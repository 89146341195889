import React, { useState, useEffect, useRef } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useUserAuth } from "../../context/UserAuthContext";
import Spinner from 'react-bootstrap/Spinner';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Chart } from 'chart.js/auto'; // Import 'chart.js/auto' for the latest version
import HorizontalBarChart from './HorizontalBarChart';
import plan from '../../images/Errors/Serratus Anterior_Normal.png'
import angle from '../../images/angle.png'
import pain from '../../images/Assessment/PainScale.png'
import disability from '../../images/Assessment/Disability.png'
import { assessment } from './AssessmentData';

export const Overview = ({ patientId, programId, programdata }) => {
  const { getOverviewData, getHistogramData, getAssessmentData } = useUserAuth();
  const [overviewData, setOverviewData] = useState({
    GrandTotalScore: null,
    AdherenceScore: null,
    StreakScore: null,
  });
  const [histogramData, setHistogramData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Hisloading, setHisLoading] = useState(true);
  const [Assessment, setAssessment] = useState([]);
  const [current_day, setCurrentDay] = useState(0);
  const [duration, setDuration] = useState(0);
  useEffect(() => {
    console.log("patientId ", patientId);
    console.log("programId ", programId);
    console.log("duration  ", duration);
    console.log("current day  ", current_day);
    console.log("Data ---------------", programdata);
    setDuration(programdata.duration);
    setCurrentDay(programdata.currentDay);
    const fetchOverviewData = async () => {
      console.log('Fetching programs data...');
      const Data = await getOverviewData(patientId, programId);
      setOverviewData(Data);
      if (Data !== null)
        setLoading(false);
    };

    fetchOverviewData();
  }, [patientId, programId]);

  useEffect(() => {
    const fetchHistogramData = async () => {
      console.log("Fetching Histogram Data...");
      setHisLoading(true);
      const histogram = await getHistogramData(patientId, programId, duration, current_day);
      setHistogramData(histogram);
      if (histogram !== null || histogram.length > 0) {
        setHisLoading(false);
        console.log("inside if ", histogram, Hisloading);
      }
      console.log("histogram loader ", Hisloading);
      console.log("histogram Data in UI  ", histogramData);
    };

    fetchHistogramData();
  }, [patientId, programId, duration, current_day]);

  useEffect(() => {
    const fetchAssessment = async () => {
      console.log("Fetching Assessment Data ... ",);
      try {
        const data = await getAssessmentData(patientId, programId, duration); // Include duration parameter
        console.log("this is assessment data in front end ", data);
        const dataArray = Object.keys(data).map((key) => ({
            key,
            value: data[key],
        }));

        const imagePromises = dataArray.map(async (item) => {
            const { imgSrc, title } = await getImageForAssessment(item.key);
            return { ...item, imgSrc, title };
        });

        // Resolve all promises
        const images = await Promise.all(imagePromises);
        setAssessment(images);

    } catch (error) {
        console.error("Error fetching assessment data:", error);
    }
     
    };
    fetchAssessment(); 
  }, [patientId, duration, current_day]);

  const getImageForAssessment = (key) => {
    const assessmentItem = assessment.find((item) => item.key === key);
    if (!assessmentItem) {
        console.error("Assessment item not found for key:", key);
        return { imgSrc: '', title: key };
    }
    const imgSrc = `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioAssessmentImages%2F${assessmentItem.img}.png?alt=media&token=452dd430-bd8d-447c-b9ea-76b6ad81b733`;
    return { imgSrc, title: assessmentItem.title };
};

const isBackProgram = programdata.programName.toLowerCase().includes("back");


  return (
    <div className='overview'>
      <br />
      <div className='tab-overview'>
        Day {current_day}/{duration} <br />
        <ProgressBar style={{ width: "100%" }} now={current_day / duration * 100} />
      </div>
      <br />
      <div className='tab-overview'>
        Performance
      </div>
      <br />
      <div className='score-data'>
        <div className='score-box'>
            <div className='inner-score-box'>
              <div className='score-result' style={{color: "#da396a"}}>
                {loading ? (
                  <>0%</>
                ) : (
                  <>
                    {overviewData !== null || Object.keys(overviewData).length > 0
                      ? `${overviewData.GrandTotalScore}%`
                      : "NA"}
                  </>
                )}
                
                </div>
              <div className='score-parameter'>Score</div>
            </div>
          </div>
	        <div className='score-box'>
            <div className='inner-score-box'>
              <div className='score-result' style={{color: "#ff5e00"}}>
              {loading ? (
                  <>0</>
                ) : (
                  <>
                    {overviewData !== null
                      ? `${overviewData.AdherenceScore}%`
                      : "NA"}
                  </>
                )}
                
                </div>
              <div className='score-parameter'>Attendence</div>
            </div>
          </div>
	        <div className='score-box'>
            <div className='inner-score-box'>
              <div className='score-result' style={{color: "#23ce6b"}}> 
              {loading ? (
                  <>0</>
                ) : (
                  <>
                     {overviewData !== null ? overviewData.StreakScore : "NA"}
                  </>
                )}
                
              
             </div>
              <div className='score-parameter'>Streak</div>
            </div>
          </div>
      </div>
      <br />
      <div className='tab-overview'>
        Score
      </div>
      <br />

      {Hisloading ? (
        <>
          <Skeleton
            sx={{ bgcolor: 'grey.900' }}
            variant="rectangular"
            height={118}
          />
        </>

      ) : (
        <div className='histogram'>
           <HorizontalBarChart data={histogramData} />
        </div>
      )}

      <br />

      {Assessment && Assessment.length > 0 ? (
        <div>
          {/* <hr style={{height:"5px"}}/> */}
          <div style={{
            height:"2px",
            backgroundColor:"#D9D9D9",
            marginTop:"2%",
            marginBottom:"2%",
          }}> </div>
          { isBackProgram ?(
              <>
              <div style={{ fontSize: "110%" }}> Pain Scale and Disability</div>
                <div className='assessment-block-scrollable'>
                  {Assessment.map((item, index) => {
                    const key = item.key; // Assuming each item has only one key
                    const values = item.value;
                    const img = item.imgSrc;
                    const title = item.title;

                    if (key.toLowerCase().includes('pain') && values.length > 0) {
                      return (
                          <div key={index} className='assessment-outer-block'>
                              <div className='assessment-inner-block'>
                                  <img src={pain} alt="" className='assessment-img' />
                                  <div className='assessment-inner-block1'>
                                      <div style={{ fontWeight: "505" }}>{title}</div>
                                  </div>
                              </div>
                              <hr />
                              <div className='assessment-scroll-container'>
                                <div className='assessment-inner-block-lower'>
                                    {values.map((value, dayIndex) => (
                                        // value !== 0 && 
                                          (
                                            <div key={dayIndex}>
                                                <div className='assessment-day' style={{ backgroundColor: `#ebe8ff` }}>
                                                  Day {dayIndex*15}
                                                </div>
                                                <div className='assessment-scores'>
                                                <div className='assessment-score-text'>{value === '-' ? value : `${value} %`}</div>
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </div>
                              </div>
                          </div>
                        );
                    } else {
                        return null; // Skip rendering if the condition is not met
                    }
                    })}
                </div>
              </>
            ):(
              <>
                <div className='assessment-block'>
                  <div style={{fontSize:"120%", fontWeight:"600"}}>Assessment </div>
                  <div> Range of Motion </div>
                  <div className='assessment-block-scrollable'>
                  {Assessment.map((item, index) => {
                    // console.log("this is assigment data in loop ",item);
                    const key = item.key; // Assuming each item has only one key
                    const values = item.value;
                    const title = item.title;
                    const img = item.imgSrc;
                    console.log("this is the value of values in assessment ",values)
                    // const imgSrc = getImageForAssessment(key);
                    if (key.toLowerCase().includes('abduction') && values.length > 0) {
                      return (
                          <div key={index} className='assessment-outer-block'>
                              <div className='assessment-inner-block'>
                                  <img src={img || plan} alt="" className='assessment-img' />
                                  <div className='assessment-inner-block1'>
                                      <div style={{ fontWeight: "505" }}>{title}</div>
                                      <div>Normal range: 150 - 180</div>
                                  </div>
                              </div>
                              <hr />
                              <div className='assessment-scroll-container'>
                                <div className='assessment-inner-block-lower'>
                                    {values.map((value, dayIndex) => (
                                        // value !== 0 && 
                                          (
                                            <div key={dayIndex}>
                                                <div className='assessment-day' style={{ backgroundColor: `#ebe8ff` }}>
                                                  Day {dayIndex*15}
                                                </div>
                                                <div className='assessment-scores'>
                                                    {value !== '-' && <img src={angle} alt="" className='assessment-angle-img' />}
                                                    <div className='assessment-score-text'>{value}</div>
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </div>
                              </div>
                          </div>
                      );
                  } else {
                      return null; // Skip rendering if the condition is not met
                  }
                  })}

                  </div>
                  
                  <div className='assessment-block-scrollable'>
                  
                  {Assessment.map((item, index) => {
                    // console.log("PRANAV",item);
                    const key = item.key; // Assuming each item has only one key
                    const values = item.value;
                    const img = item.imgSrc;
                    const title = item.title;
                    // console.log("PRINTING VALUES ",key, values,img);

                    if (key.toLowerCase().includes('flexion') && values.length > 0) {
                      return (
                          <div key={index} className='assessment-outer-block'>
                              <div className='assessment-inner-block'>
                                  <img src={img || plan} alt="" className='assessment-img' />
                                  <div className='assessment-inner-block1'>
                                      <div style={{ fontWeight: "505" }}>{title}</div>
                                      <div>Normal range: 150 - 180</div>
                                  </div>
                              </div>
                              <hr />
                              <div className='assessment-scroll-container'>
                                <div className='assessment-inner-block-lower'>
                                    {values.map((value, dayIndex) => (
                                        // value !== 0 && 
                                          (
                                            <div key={dayIndex}>
                                                <div className='assessment-day' style={{ backgroundColor: `#ebe8ff` }}>
                                                  Day {dayIndex*15}
                                                </div>
                                                <div className='assessment-scores'>
                                                    {value !== '-' && <img src={angle} alt="" className='assessment-angle-img' />}
                                                    <div className='assessment-score-text'>{value}</div>
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </div>
                              </div>
                          </div>
                      );
                  } else {
                      return null; // Skip rendering if the condition is not met
                  }
                  })}

                  </div>

                  <div className='assessment-block-scrollable'>
                    {Assessment.map((item, index) => {
                      const key = item.key; // Assuming each item has only one key
                      const values = item.value;
                      const img = item.imgSrc;
                      const title = item.title;

                      if (!key.toLowerCase().includes('pain') &&
                        !key.toLowerCase().includes('disability') &&
                        (key.toLowerCase().includes('external rotation') || key.toLowerCase().includes('er')) &&
                        values.length > 0) {
                        return (
                            <div key={index} className='assessment-outer-block'>
                                <div className='assessment-inner-block'>
                                    <img src={img || plan} alt="" className='assessment-img' />
                                    <div className='assessment-inner-block1'>
                                        <div style={{ fontWeight: "505" }}>{title}</div>
                                        <div>Normal range: 70 - 90</div>
                                    </div>
                                </div>
                                <hr />
                                <div className='assessment-scroll-container'>
                                  <div className='assessment-inner-block-lower'>
                                      {values.map((value, dayIndex) => (
                                          // value !== 0 && 
                                            (
                                              <div key={dayIndex}>
                                                  <div className='assessment-day' style={{ backgroundColor: `#ebe8ff` }}>
                                                    Day {dayIndex*15}
                                                  </div>
                                                  <div className='assessment-scores'>
                                                      {value !== '-' && <img src={angle} alt="" className='assessment-angle-img' />}
                                                      <div className='assessment-score-text'>{value}</div>
                                                  </div>
                                              </div>
                                          )
                                      ))}
                                  </div>
                                </div>
                            </div>
                          );
                      } else {
                          return null; // Skip rendering if the condition is not met
                      }
                    })}
                  </div>
                  <div style={{
                    height: "2px",
                    backgroundColor: "#D9D9D9",
                    marginTop: "3%",
                    marginBottom: "4%",
                  }}> </div>
                  <div className='assessment-block'>
                    <div style={{ fontSize: "110%" }}> Pain Scale and Disability</div>
                    <div className='assessment-block-scrollable'>
                      {Assessment.map((item, index) => {
                        const key = item.key; // Assuming each item has only one key
                        const values = item.value;
                        const img = item.imgSrc;
                        const title = item.title;

                        if (key.toLowerCase().includes('disability') && values.length > 0) {
                          return (
                              <div key={index} className='assessment-outer-block'>
                                  <div className='assessment-inner-block'>
                                      <img src={disability} alt="" className='assessment-img' />
                                      <div className='assessment-inner-block1'>
                                          <div style={{ fontWeight: "505" }}>{title}</div>
                                      </div>
                                  </div>
                                  <hr />
                                  <div className='assessment-scroll-container'>
                                    <div className='assessment-inner-block-lower'>
                                        {values.map((value, dayIndex) => (
                                            // value !== 0 && 
                                              (
                                                <div key={dayIndex}>
                                                    <div className='assessment-day' style={{ backgroundColor: `#ebe8ff` }}>
                                                      Day {dayIndex*15}
                                                    </div>
                                                    <div className='assessment-scores'>
                                                      <div className='assessment-score-text'>{value === '-' ? value : `${value} %`}</div>
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                  </div>
                              </div>
                            );
                        } else {
                            return null; // Skip rendering if the condition is not met
                        }
                      })}
                    </div>
                  </div>


                  <div className='assessment-block-scrollable'>
                  {Assessment.map((item, index) => {
                    const key = item.key; // Assuming each item has only one key
                    const values = item.value;
                    const img = item.imgSrc;
                    const title = item.title;

                    if (key.toLowerCase().includes('pain') && values.length > 0) {
                      return (
                          <div key={index} className='assessment-outer-block'>
                              <div className='assessment-inner-block'>
                                  <img src={pain} alt="" className='assessment-img' />
                                  <div className='assessment-inner-block1'>
                                      <div style={{ fontWeight: "505" }}>{title}</div>
                                  </div>
                              </div>
                              <hr />
                              <div className='assessment-scroll-container'>
                                <div className='assessment-inner-block-lower'>
                                    {values.map((value, dayIndex) => (
                                        // value !== 0 && 
                                          (
                                            <div key={dayIndex}>
                                                <div className='assessment-day' style={{ backgroundColor: `#ebe8ff` }}>
                                                  Day {dayIndex*15}
                                                </div>
                                                <div className='assessment-scores'>
                                                <div className='assessment-score-text'>{value === '-' ? value : `${value} %`}</div>
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </div>
                              </div>
                          </div>
                        );
                    } else {
                        return null; // Skip rendering if the condition is not met
                    }
                    })}
                </div>

                </div>
              </>
            )}
          
        </div>
      ):(
        <div> </div>
      )}
      

    </div>
  );
};
