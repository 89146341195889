import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import left from '../images/Left.png';
import { useUserAuth } from "../context/UserAuthContext";

export const QRcodePage = () => {
  const navigate = useNavigate();
  const { fetchPhysioData } = useUserAuth();

  const [message, setMessage] = useState('');
  const [QRcodelink, setQRcodeLink] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQRCodeData = async () => {
      try {
        const data = await fetchPhysioData();
        console.log("this is physio Data ", data);
        setMessage(data.whatsAppMessage);
        setQRcodeLink(data.qrCodeURL);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQRCodeData();
  }, []);

  const handleImageClick = () => {
    navigate('/profile');
  };

  const handleDownloadClick = () => {
    const link = document.createElement('a');
    link.href = QRcodelink;
    link.download = 'QR_Code.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleNavigateToImage = () => {
    window.open(QRcodelink, '_blank'); // Open the image URL in a new tab
  };

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false when the image is loaded
  };

  return (
    <div className="qr-code-page">
      <div style={{marginTop: "7%", marginBottom: "5%"}}>
        <img 
          className='AP-back-image'
          src={left}
          alt="back"
          onClick={handleImageClick}
          style={{marginLeft: "5%"}}
        />
        <span className='calender-text'>  QR Code</span>
      </div>
      {loading ? (
        <div className="loader"></div> // Show loader while image is loading
      ) : (
        <>
          <div className="qr-code-container" style={{display: "flex", justifyContent: "center"}}>
            <img 
              className="qr-code-image" 
              src={QRcodelink} 
              alt="QR Code" 
              onLoad={handleImageLoad} // Call handleImageLoad when image is loaded
            />
          </div>
          <div className="message-container">
            <div className="message-bubble">{message}</div>
          </div>
          {/* <button onClick={handleDownloadClick}>Download QR Code</button>
          <button onClick={handleNavigateToImage}>View QR Code</button> */}
        </>
      )}
    </div>
  );
};
