// NoInternetPopup.js
import React, { useState, useEffect } from 'react';


const NoInternetPopup = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  if (!isOnline) {
    return (
      <div className="no-internet-popup">
        <div className="popup-content">
          <p>No active internet connection</p>
          <p>Please check your network settings</p>
        </div>
        <style jsx>{`
          .no-internet-popup {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 0, 0, 0.25);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 9999;
          }
          .popup-content {
            font-family: "Rajdhani";
            background: #fff;
            padding: 20px;
            text-align: center;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          }
        `}</style>
      </div>
    );
  }
  return null;
};

export default NoInternetPopup;
