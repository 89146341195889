import React,{ useState } from 'react'
import { Link } from 'react-router-dom'

export const Report = () => {

  return (
    <div>
      <div className='report-header'>
        <div className='report-header-text'>
          Wizio Report 
        </div>
      </div>
      <div className='report-block'>
        <div>  
          Name : <input 
            style={{ border: 'none', borderBottom: '1px solid #2d2f351a ', color: '#2d2f35' , 
            outline: 'none' ,
            width:"50%"}} 
            type="text" 
          />
        </div>
        <div>
          Date : <input  type="date" style={{ border: 'none', borderBottom: '1px solid #2d2f351a', color: '#red' , outline: 'none' , width:"70%", }}/>
        </div>
      </div>

      <div className='report block report-block1'>
        <div style={{width:"60%"}}>  
        Expected Number of Days for Treatments :
        </div>
        <div>
          <input type="number" style={{ border: 'none', border: '1px solid #2d2f351a', color: '#red'  , width:"50px", borderRadius:"10px", height:"35px"}}/>
        </div>
      </div>

      <div className='report-block3'>
        <div>
        Treatment Goals : 
        </div> <br />
        <textarea 
            style={{ border: 'none', borderBottom: '1px solid #2d2f351a ', color: '#2d2f35' , 
            outline: 'none' ,
            width:"100%",
            whiteSpace: 'normal',
            height : "50%"}} 
            type="text" 
          />

      </div>

      <div className='report-block3'>
        <div>
        Diagnosis :
        </div> <br />
        <textarea 
            style={{ border: 'none', borderBottom: '1px solid #2d2f351a ', color: '#2d2f35' , 
            outline: 'none' ,
            width:"100%",
            whiteSpace: 'normal',
            height : "50%"}} 
            type="text" 
          />

      </div>

      <div className='report-block3'>
        <div>
        Subjective & Objective findings including Activity Restriction Recommendations :
        </div> <br />
        <textarea 
            style={{ border: 'none', borderBottom: '1px solid #2d2f351a ', color: '#2d2f35' , 
            outline: 'none' ,
            width:"100%",
            whiteSpace: 'normal',
            height : "50%"}} 
            type="text" 
          />

      </div>

      <div className='AP-button'>
      <Link to = '/home' style={{textDecoration:"none"}}>
        <div className='inner-AP-button'>
          <div className='AP-text'>SUBMIT</div>
        </div>
      </Link>
      
    </div>
    </div>
  )
}
