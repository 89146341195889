import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tick from '../../images/Blue OK.png';
import WhatsApp from '../../images/WhatsApp.png';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';

export const AddPatientSuccessPopUp = ({ showSuccessModal, setShowSuccessModal, patientData, physioName }) => {
  
  const navigate = useNavigate();

  const handleShow = () => {
    setShowSuccessModal(true);
  };

  const handleClose = () => {
    setShowSuccessModal(false);
    navigate('/home'); // Redirect to /home after closing the pop-up
  };

  const generateWhatsAppLink = () => {
    const phoneNumber = patientData.CountryCode + patientData.PatientMobileFP;
    const message = `Hello, this is your exercise plan. Please review it.
    Patient Name: ${patientData.PatientNameFP}
    Mobile Number: ${patientData.PatientMobileFP}
    Patient Treatment: ${patientData.PatientTreatmentFP}
    Color band: ${patientData.PatientColorFP}
    Side: ${patientData.PatientSideFP}
    Selected Plan: ${patientData.PatientAssignedProgramNameFP}
    App Link: https://wiziophysiotherapy.app.link/FnPlpMFqRAb
    Please watch this video to understand how to start this program. https://youtu.be/VIbmDKoBH_Y
    For any support, please contact us at 7709937940.`;
  
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  
    // Open WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };

  const handleShareWhatsApp = () => {
    const message = 'Hello, this is your exercise plan. Please review it.';
    const whatsappLink = generateWhatsAppLink(message);
    
    // Open WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };

  const getJointName = (moduleID) => {
    console.log("this is joint name ", moduleID);
    const jointNames = ["hip", "knee", "shoulder", "ankle", "back", "neck"];
    if (moduleID === "b2bHipandKnee0002") {
      return "knee";
    }
    if (moduleID === "b2bHipandKnee0001") {
      return "hip";
    }
    return jointNames.find(joint => moduleID.toLowerCase().includes(joint)) || "Joint";
  };

  useEffect(() => {
    if (showSuccessModal) {
      const fetchData = async () => {
        try {
          console.log("the WhatsApp API called");
          const jointName = getJointName(patientData.FBTemplateid);
          const response = await axios.post('https://62xk1zfu3b.execute-api.ap-south-1.amazonaws.com/dev/b2b_program_WT', {
            patient_name: patientData.PatientNameFP,
            physio_name: physioName,
            joint_name: jointName,
            duration: '30',
            customer_number: patientData.CountryCode + patientData.PatientMobileFP,
            customer_name: patientData.PatientNameFP
          });
          console.log('API response:', response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [showSuccessModal, patientData, physioName]);

  return (
    <>
      <div className='popup'>
        <Modal 
          show={showSuccessModal} 
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className='popup-header'>
              <div>
                <img src={Tick} alt="Tick" /> 
              </div>
              <div className='successful-text'>Successful</div>
            </div>
            <div className='popup-body'>
              Your patient was added
            </div>
            {/* <div className='popupwhatsapp' onClick={handleShareWhatsApp}>
              <img src={WhatsApp} alt="WhatsApp" /> 
              <div className='whatapp-text'>
                Share your plan with the patient
              </div>
            </div> */}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
