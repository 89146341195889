import React, { useState, useEffect } from 'react';
import { useUserAuth } from "../context/UserAuthContext";
import { useNavigate } from 'react-router-dom';
import left from '../images/Left.png';
import Profile1 from '../images/Profile1.png';
import Doorbell from '../images/Doorbell.png';
import Help from '../images/Help.png';
import Privacy from '../images/Privacy.png';
import TermsACondition from '../images/Terms and Conditions.png';
import Comments from '../images/Comments.png';
import Carousel from 'react-bootstrap/Carousel';
import myplanpic from '../images/myplanpic.png'
import More from '../images/More Than (1).png';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Footer } from './components/Footer';
import Spinner from 'react-bootstrap/Spinner'
import { analytics } from "../firebase/index";
import { logEvent } from "firebase/analytics";
import QRcodelogo  from '../images/QRcode.png';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const Profile = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [About, setAbout] = useState('');
  const [price, setPrice] = useState('');
  const { ID, user, verified, docsCalenderBool, consultation, fetchPhysioData, getAllPrograms, logOut,VideoCallHistoryData } = useUserAuth();
  const [programs, setPrograms] = useState([]);
  const [log, setlog] = useState(0);

  useEffect(() => {
    if (log === 1) {
      logEvent(analytics, 'py_PhysioProfile', {
        Date: new Date().toLocaleString(),
        ID,
        "pyData": JSON.stringify(userName),
        "py_log" : "fetching py physio data for profile page"
      });
      console.log("logging the value ");
    }
  }, [log]);

  

  useEffect(() => {
    // Scroll to the top of the page when it loads
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  useEffect(() => {
    const fetchVideoCallHistoryData = async () => {
      const data = await VideoCallHistoryData();
      const historyArray = Object.values(data);
      localStorage.setItem('VideoCallHistoryData', JSON.stringify(historyArray));
    };

    fetchVideoCallHistoryData();
  }, []);


  // fetching myplans data (programs)
  useEffect(() => {
    const fetchPrograms = async () => {
      const storedPrograms = JSON.parse(localStorage.getItem('Program'));
      
      if (storedPrograms && storedPrograms.length > 0) {
        setPrograms(storedPrograms);
        console.log('Using programs data from localStorage.');
      }
      console.log('Fetching programs data...');
      const programsData = await getAllPrograms();
      localStorage.setItem('Program', JSON.stringify(programsData)); 
      setPrograms(programsData);
    };

    fetchPrograms();
  }, []);

  const handleMeetingHistoryClick = () => {
    logEvent(analytics, 'py_MeetingHistoryPage_Clicked', {
      Date: new Date().toLocaleString(),
      ID,
      "py_log": "meeting history page clicked"
    });
    console.log("logging");

    // Redirect to the 'videocallhistory' page
    navigate('/videocallhistory');
  };


  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        console.log('Fetching user data...');
        setUserName(localStorage.getItem('py_username'));
        setAbout(localStorage.getItem('py_About'));
        setPrice(localStorage.getItem('py_Price'));
        const userData = await fetchPhysioData();
        console.log("this is user data ",userData);
        
        if (userData) {
          setUserName(userData.Name);
          setAbout(userData.About);
          setPrice(userData.ConsultancyPrice);
          localStorage.setItem('py_username', userData.Name); 
          localStorage.setItem('py_About', userData.About); 
          localStorage.setItem('py_Price', userData.ConsultancyPrice); 
        }
        setlog(1);

      };
      fetchData();
    }
  }, [user]); // Add user as a dependency
  

  const handleLogout = async () => {
    try {
      await logOut();
      logEvent(analytics, 'py_Logout', {
        Date: new Date().toLocaleString(),
        ID,
        "py_log": "User logged out"
      });
      // Redirect the user to the login page or any other page after logout
      navigate('/'); // Replace '/login' with your desired route
    } catch (error) {
      console.error("Error logging out:", error);
      logEvent(analytics, 'py_Logout_error', {
        Date: new Date().toLocaleString(),
        ID,
        "py_log": error
      });
    }
  };
  useEffect(() => {
    const handleBackButton = (event) => {
      
      console.log("historyyyyyyyyyyyyyy",window.history);
      event.preventDefault(); // Prevent the default browser behavior
      window.history.back(); // Navigate to the previous page
    };

    window.addEventListener('popstate', handleBackButton)
    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener('popstate', handleBackButton);
      console.log("back button clicked hurrayyyyyyyyyyyyyyyyyy");
    };
  }, [])


  return (
    <div className='profile-page' style={{backgroundColor:"#edf1fc"}}>
      <br />
      <div>
        <img 
          className='back-image'
          src={left}
          alt="back"
          onClick={() => navigate('/home')}
          style={{marginTop:"1%", marginLeft:"5%"}}
        />

        <div className='profile-banner' style={{marginBottom:"3%"}}>
          <div className='profile-banner-inner'>
            <div className='profile-banner-header'>
            <div className='profile-text'>
                {userName=='' ? <Skeleton width={150} /> : userName}
              </div>
              <div className='profilepic-body'>
                <img className='profile-image' src={Profile1} alt="Profile" />
              </div>
              
            </div>
            <br />
            <div className='profile-text'>
              About
            </div>
            <div className='profile-text' style={{paddingRight:"10%"}}>
              {About=='' ? <Skeleton width={150} /> : About}
            </div>
          </div>
        </div>

        {/* <div className='myplan-banner' style={{marginBottom:"3%"}}>
          <p className='myplan-text'>My Plans</p>
          {programs.length > 0 ? (
            <Carousel controls={false} indicators={false}>
            {programs.map((program, index) => {
              const exerciseName = program._document.data.value.mapValue.fields.Program_name.stringValue.toLowerCase();

              // Mapping of joint names to their corresponding image URLs
              const getRandomThumbnail = (joint) => {
                const randomIndex = Math.floor(Math.random() * 3) + 1; // Generates a random number between 1 and 3
                return `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioExcercisePlanImages%2F${joint}Thumbnail${randomIndex}.png?alt=media&token=c94ba79a-26ce-4efb-9f4f-70865845ef68`;
              };
              
              const jointImages = {
                knee: getRandomThumbnail('Knee'),
                hip: getRandomThumbnail('Hip'),
                shoulder: getRandomThumbnail('Shoulder'),
                neck: getRandomThumbnail('Neck'),
                back: getRandomThumbnail('Back'),
                ankle: getRandomThumbnail('Ankle'),
              };
              
              // Find the matching image URL based on the exercise name
              const matchingJoint = Object.keys(jointImages).find(joint => exerciseName.includes(joint));
              const imgSrc = matchingJoint ? jointImages[matchingJoint] : myplanpic;
              
          
              return (
                <Carousel.Item key={index}>
                  <Link to={`/plan/${program._document.key.path.segments[6]}/${program._document.data.value.mapValue.fields.Program_name.stringValue}`} style={{ textDecoration: 'none' }}>
                    <div className='myplan-body'>
                      <img
                        className='myplan-image'
                        src={imgSrc}
                        alt='My Plan'
                      />
                      <div className='myplan-text2' style={{ textDecoration: 'none' }}>{program._document.data.value.mapValue.fields.Program_name.stringValue}</div>
                      <div className='myplan-text3'> <br /> <br />  View Plan </div>
                    </div>
                  </Link>
                </Carousel.Item>
              );
            })}
          </Carousel>
          
          ) : (
            <div className='skeleton'>
              <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"
              className='home-loader'
              height={70}
            />
            </div>
          )}
          <br />
        </div> */}

        {/* <div className='myplan-banner'>
          <p className='myplan-text'>My Plans</p>
          <Carousel>
            {programs.map((program, index) => (
              
              <Carousel.Item key={index}>
                <Link to={`/plan/${program._document.key.path.segments[6]}`}>
                
                <div className='myplan-body'>
                  
                  <img
                    className='myplan-image'
                    src={myplanpic}
                    alt='My Plan'
                  />
                  <div className='myplan-text-container'>
                    <Row>
                      <Col sm={12}>
                        <div className='myplan-text2'>{program._document.data.value.mapValue.fields.Program_name.stringValue}</div>
                      </Col>
                      <Col>
                        <div className='myplan-text3'> <br /> <br />  View Plan </div>
                      </Col>
                    </Row>                    
                  </div>
                </div>
                </Link>
              </Carousel.Item>
             
            ))}
          </Carousel>
          <br />
        </div>  */}
        {verified && (
          <Link to='/myprograms' style={{textDecoration:'none'}}>
            <div className='fees-text' style={{paddingTop:"15px", paddingBottom:"15px"}}>
              <div className='first-part'>
                <div className='profile-text'> My Programs </div>
              </div>
              <div className='second-part'>
                <img className='img2' src={More} alt="Profile" />
              </div>
            </div>
          </Link>
        )}
        

        {docsCalenderBool && (
          <>
            <Link to='/qrcode' style={{textDecoration:'none'}}>
              <div className='fees-text'>
                <div className='first-part'>
                  <div className='image-holder'>
                    <img src={QRcodelogo} alt="Profile" style={{marginLeft:"20%", height:"60%", width:"60%", marginTop:"20%"}}/>
                  </div>
                  <div className='profile-text'> QR Code </div>
                </div>
                <div className='second-part'>
                  {/* <div className='vc-history-block'>
                    <div className='vc-history-text'>+5</div>
                  </div> */}
                  <img className='img2' src={More} alt="Profile" />
                </div>
              </div>
            </Link>
          </>
          
        )}

        
        {consultation && (
          <>
            <Link to='/videocallhistory' style={{textDecoration:'none'}} onClick={handleMeetingHistoryClick}>
              <div className='fees-text'>
                <div className='first-part'>
                  <div className='image-holder' style={{paddingLeft:"3%"}}>
                    <img className='img1' src={Doorbell} alt="Profile" style={{marginLeft:"8%"}}/>
                  </div>
                  <div className='profile-text'> Meeting History </div>
                </div>
                <div className='second-part'>
                  {/* <div className='vc-history-block'>
                    <div className='vc-history-text'>+5</div>
                  </div> */}
                  <img className='img2' src={More} alt="Profile" />
                </div>
              </div>
            </Link>
          </>
          
        )}

        <div className='about-block' style={{marginBottom:"3%"}}>
          <div className='about-text'> <br />&nbsp; About </div>
     
          {/* <div className='about-text-block'>
            <div className='first-part'>
              <div className='image-holder' style={{paddingLeft:"5%"}}>
              <img className='img1' src={Comments} alt="Profile" style={{height:"19px", width:"18px",marginTop:"20%", marginLeft:"3%"}}/>
            </div>
              <div className='profile-text'> Feedback </div>
            </div>
            <div className='second-part'>
              <img className='img2' src={More} alt="Profile" />
            </div>
          </div>
          <hr className='profilehr'/> */}
          <Link to='https://www.wizio.in/physio-agreement' style={{textDecoration:'none'}}>
          <div className='about-text-block'>
            <div className='first-part'>
              <div className='image-holder' style={{paddingLeft:"2%"}}>
              <img className='img1' src={TermsACondition} alt="Profile" style={{height:"22px", width:"18px",marginTop:"20%", marginLeft:"10%"}}/>
            </div>
              <div className='profile-text'> Terms and condition </div>
            </div>
            <div className='second-part'>
              <img className='img2' src={More} alt="Profile" />
            </div>
          </div>
          </Link>
          <hr className='profilehr' />
          <Link to='https://www.wizio.in/privacy-policy' style={{textDecoration:'none'}}>
          <div className='about-text-block'>
            <div className='first-part'>
              <div className='image-holder' style={{paddingLeft:"3%"}}>
              <img className='img1' src={Privacy} alt="Profile" style={{height:"22px", width:"18px",marginTop:"20%", marginLeft:"10%"}}/>
            </div>
              <div className='profile-text'> Privacy Policy </div>
            </div>
            
          </div>
          </Link>
          <hr  className='profilehr'/>
          <Link to='https://www.wizio.in/physio-faqs' style={{textDecoration:'none'}}>
          <div className='about-text-block'>
            <div className='first-part'>
              <div className='image-holder' style={{paddingLeft:"2%"}}>
              <img className='img1' src={Help} alt="Profile" style={{height:"32px", width:"18px", marginLeft:"10%"}}/>
            </div>
              <div className='profile-text'>Frequently Asked Questions </div>
            </div>
          </div>
          </Link>

          
        </div>


       <div className='fees-text' onClick={handleLogout} style={{paddingTop:"3%", paddingBottom:"3%"}}>
          <div className='first-part'>
            <div className='profile-text'> &nbsp; Logout </div>
          </div>
        </div>
        <br />
       <br /> <br />
      </div>
      <Footer/>
    </div>
  )
}
