import React, { useState, useEffect }  from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useUserAuth } from "../../context/UserAuthContext";
import Modal from 'react-modal';
import cross from '../../images/Cross.png';
import ok from '../../images/Ok (1).png';
Modal.setAppElement('#root');

const FollowUpSession = ({patientName,patientNumber,patientId, requested}) => {
  const navigate = useNavigate();
  
  const { consultation, VideoCallHistoryData, WeeklyUpcomingAppointmentsDataforPatients, requestConsultation } = useUserAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // New state
  const [sessionHistory, setsessionHistory] = useState([]);
  const [WeeklyUpcoming, setWeeklyUpcoming] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [requestedConsultation, setRequestedConsultation] = useState(false);
  const [consultationMessage, setConsultationMessage] = useState('');


  useEffect(() => {
    const fetchSessionHistoryData = async () => {
      // console.log('Fetching Session History Data ...');
      // const Data = await VideoCallHistoryData();
      const Data = JSON.parse(localStorage.getItem('VideoCallHistoryData'));
      // console.log("data we have without parsing ", Data);
      // console.log("data we have ", Data[patientId]);
      console.log("patient id, number , name ",patientId,patientNumber,patientNumber, Data)
      const filteredData = Data.filter(item => item.ID === patientId);
      setsessionHistory(filteredData);
      const Data1 = await VideoCallHistoryData();
      const filteredData1 = Data1.filter(item => item.ID === patientId);
      // setsessionHistory(filteredData1);
      localStorage.setItem('VideoCallHistoryData',JSON.stringify(Data1));
    };
  
    fetchSessionHistoryData();
  }, [patientId]); // Include patientId as a dependency

  useEffect(() => {
    const fetchWeeklyUpcomingAppointmentsData = async () => {
      const Data = await WeeklyUpcomingAppointmentsDataforPatients();
      console.log("fetchWeeklyUpcomingAppointmentsData", Data);

      if (Data && Data[patientId]) {
        const filteredSessions = Data[patientId].filter((session) => session.ConsultationCompleted === false);
        setWeeklyUpcoming(filteredSessions);
        console.log("Filtered data in WeeklyUpcoming:", filteredSessions);
      } else {
        // Handle the case where Data or Data[patientId] is undefined
        console.log("No data available for patientId:", patientId);
        setWeeklyUpcoming([]); // Set an empty array or handle it as per your requirements
      }

      // if (Data[patientId]) {
      //   const filteredSessions = Data[patientId].sessions.filter(session => session.ConsultationCompleted === false);
      //   Data[patientId].sessions = filteredSessions;

      //   // Update the 'setWeeklyUpcoming' state with the filtered data
      //   setWeeklyUpcoming(Data[patientId]);

      //   // Log the filtered data
      //   console.log("Filtered data in WeeklyUpcoming: ", Data[patientId]);
      // }
      console.log("consultation ka value",consultation)
      console.log("fetchWeeklyUpcomingAppointmentsData1 ",WeeklyUpcoming);
    };
  
    fetchWeeklyUpcomingAppointmentsData();
  }, [patientId,isLoading]); // Include patientId as a dependency


  useEffect(() => {
    setRequestedConsultation(requested);
    console.log("the value i am getting and saving",requested,requestedConsultation);
  }, [requested]);


  function getFormattedDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric'};
    const [day, month, year] = dateString.split('/');
    const outputDateString = `${month}/${day}/${year}`;
    const date = new Date(outputDateString);
    return date.toLocaleString('en-US', options);
  }

  function formattime(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12}:${String(minutes).padStart(2, '0')} ${period}`;
    return formattedTime;
  }

  const openSuccessModal = () => {
    console.log("comming here hahahahah")
    setIsSuccessModalOpen(true);

    // Automatically close the success modal after 3 seconds (adjust as needed)
    setTimeout(() => {
      setIsSuccessModalOpen(false);
      navigate("/home")
    }, 2000);
  };


  const handleEditEvent = async() => {
    console.log("first")
    setIsModalOpen(true);
  };

  const handleRequestConsultation = async() => {
    setIsLoading(true); // Show the loader
    try {
      // Call your requestConsultation function here
      await requestConsultation(patientId, patientName);
      setConsultationMessage('Consultation requested successfully.');
      openSuccessModal();
      
    } catch (error) {
      setConsultationMessage('Error requesting consultation.');
    } finally {
      setIsLoading(false); // Hide the loader
      setIsModalOpen(false); // Close the modal
    }
  }

  if(!consultation){
    console.log("ye toh yaha fass rha h ",consultation)
    return null;
  }

  return (
    <div className='followup-session'>
      
        {WeeklyUpcoming && WeeklyUpcoming.length > 0 ? (
          <div className='followup-session-header' style={{backgroundColor:"#E2FEEA", color:"#02320F"}}>
            <p style={{ padding: "5%" }}>
              Next Follow-up on {getFormattedDate(WeeklyUpcoming[0].ConsultationDate)} at {formattime(WeeklyUpcoming[0].ConsultationTime)}
            </p>
          </div>
        ) : (
          <div className='followup-session-header' style={{backgroundColor:"#f0f4ff", color:"#050051"}}>
            <p 
              // onClick={() => handleEditEvent()} 
              style={{ 
                padding: "5%",
                display:"flex",
                justifyContent:"space-between", 
              }}
            >
              {sessionHistory.length > 0 ? (
                <div>Request Follow-up Session </div>
              ):(
                <div>Request First Session </div> 
              )}
              
              
            </p>
          </div>
          
        )}
      
      
      {sessionHistory.length > 0 ? (
        <>
          <div className='followup-session-body1'>
            Previous Consultation 
            
          </div>
          <>
          {sessionHistory.map((session, index) => (
            <div className='followup-session-body2' key={index}>
              <hr style={{ padding:"0", margin:"0.5rem 0", marginRight:"5%",}}/>
              <div className='followp-session-text1'>Video Consultation</div>
              <div className='followup-session-body3'>
                <div className='followup-session-date'>{session.Session_Time}</div>
                {/* <Link to='/report' style={{ textDecoration: 'none' }}>
                  <div className='followup-session-view'>View Report</div>
                </Link> */}
              </div>
              
            </div>
          ))}
          {/* <br /> */}
          </>
        </>
      ) : (
        <div className='followup-session-body11'>
          No Previous Consultation
        </div>
      )}
      {!(WeeklyUpcoming && WeeklyUpcoming.length > 0) && (
        <>
        <hr style={{margin:"0.5rem 5%", }}/>
        {/* <p
          style={{
            padding: "0 5%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >  */}
          {(requestedConsultation) ? (
            <div
            style={{
              padding: "0 5%",
              display: "flex",
              justifyContent: "space-between",
            }}
            >
             
              <div className='addAIPblock-text' style={{color:"white", padding:"3% 0%",  display: 'flex', justifyContent: 'center' , width:"100%", marginBottom:"2%", backgroundColor:"grey"}}  >
                REQUEST CONSULTATION
              </div>
            </div>
          ):(
            <div
            style={{
              padding: "0 5%",
              display: "flex",
              justifyContent: "space-between",
            }}
            >
              
              <div className='addAIPblock-text' style={{color:"white", padding:"3% 0%",  display: 'flex', justifyContent: 'center' , width:"100%", marginBottom:"2%"}} onClick={() => handleEditEvent() } >
              REQUEST CONSULTATION
              </div>
            </div>
          )}
        {/* </p> */}
        </>
      )}

      

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)', // Background overlay color
            transition: 'background 0.5s ease', 
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: 'none', // Remove border
            background: '#fff', // Background color of the modal
            padding: '20px', // Adjust padding as needed
            width: '350px'
          },
        }}
      >
        <div>
          <div className='RCmodelheader'>
            <div className='RCmodelheadertext'>
              Request A Consulation 
            </div>
            <div className='RCmodelheaderbtncontainer'>
              <div>
                <img 
                  className='RCmodelheaderbtnimage'
                  src={cross}
                  alt="back"
                  style={{marginTop:"1%", marginLeft:"5%", height:"25px"}}
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                />
              </div>
            </div>
          </div>
          <form>
            <div className='APpage-text'>
              Name
            </div>
            <input
              className='APpage-input'
              type="text"
              id="name"
              value={patientName}
              readOnly = {true}
              required
            />
            <div className='APpage-text'>
              <br />
              Phone Number
            </div>
            <input
              className='APpage-input'
              type="tel"
              maxLength="10"
              id="phoneNumber"
              value={patientNumber}
              readOnly = {true}
              required
            />
            <br />            
          <br />
          <div className='AP-button3' onClick={() => handleRequestConsultation()}>
            <div className='inner-AP-button'>
              <div className='AP-text1'>REQUEST CONSULTATION</div>
            </div>
          </div>
          
        </form>
        </div>
      </Modal>

      {isLoading && (
        <div className="spinner-container">
          <div className="spinner-grow" role="status">
          </div>
        </div>
      )}

      <Modal
        // Modal styling here
        isOpen={isSuccessModalOpen}
        onRequestClose={() => {
          setIsSuccessModalOpen(false);
        }}
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)', // Background overlay color
            transition: 'background 0.5s ease', 
            fontSize: "100%",
            fontFamily: "Rajdhani",
            fontWeight: "600",
            
          },
          content: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color:"#00621B",
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: 'none', // Remove border
            background: '#fff', // Background color of the modal
            padding: '20px', // Adjust padding as needed
            width: '350px',
            borderRadius:"10px",
          },
        }}

      >
        <div style={{
          justifyContent:"center",
          display:"flex",
          paddingTop:""
        }}>
          <div>Consultation requested successfully.</div>
        </div>
      </Modal>

      
    </div>
  )
}

export default FollowUpSession