import React,{ useState, useEffect } from 'react'
import { useUserAuth } from "../../context/UserAuthContext";
import { analytics } from "../../firebase/index";
import { logEvent } from "firebase/analytics";

export const ProgramRenewal = (patientId) => {
  const { ID, programRenewalData, ProgramRenewal } = useUserAuth();
  const [renewalData, setRenewalData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [reLoad, setReLoad] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const ID = patientId.patientId;
      for (const patient of JSON.parse(localStorage.getItem('ProgramRenewalData'))) {
        if (patient.patientId === ID) {
          setRenewalData(patient);
          break; // No need to continue checking once a match is found
        }
      }
      setIsLoading(false);
      // console.log("before calling function data in local, actual variable ",JSON.parse(localStorage.getItem('ProgramRenewalData')),renewalData);
      // const data = await programRenewalData();
      // for (const patient of data) {
      //   if (patient.patientId === ID) {
      //     setRenewalData(patient);
      //     break; // No need to continue checking once a match is found
      //   }
      // }
    };
    fetchData();
  }, [patientId]);

  const handleRenewal = async(item) => {
    setIsLoading(true);
    await ProgramRenewal(item.patientId, item.programID);
    setReLoad(Math.random());
    logEvent(analytics, 'py_Renewal_to_Patient_through_patient_profile_page', {
      Date: new Date().toLocaleString(),
      ID,
      "patientid": item.patientId,
      "py_log" : "Physio giving Renewal to Patient for AI program through Patient Profile Page"
    }); 
    setIsLoading(false);
    setRenewalData({});
  }


  if(isLoading){
    return(
      <div className="spinner-container">
        <div className="spinner-grow" role="status">
        </div>
      </div>
    )
  }
  else if(Object.keys(renewalData).length === 0){
    return null;
  }


  return (
    <div style={{marginLeft:"4%",marginRight:"4%"}}>
      <br />
      <div className='programrenwalpage-header'>
        <div className='programrenwalpage-header-inner-block'>
          <div className='programrenwalpage-header-inner-block-text1'>
              Program Renewal
            </div>
            <div className='programrenwalpage-header-inner-block-text2-block'>
              <div className='programrenwalpage-header-inner-block-text2'>
                {renewalData.enddate ? renewalData.enddate.substring(0, 10) : ''}
              </div>
            </div>
          </div>
        </div>

        <div className='programrenwalpage-body'>
          <div className='programrenwalpage-body-inner'>
            <div className='programrenwalpage-body-text1'>
            {renewalData.program}
            </div>
            <div className='programrenwalpage-body-text2' onClick={() => handleRenewal(renewalData)}>
              <div className='programrenwalpage-body-text2-inner'>
                <div className='programrenwalpage-body-text2-text'>RENEW</div>
              </div>
            </div>
          </div>
        </div>

        
      
    </div>
  )
}
