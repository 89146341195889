import React, { useState, useEffect } from 'react';
import logo from '../images/logo_page.png';
import Dropdown from 'react-bootstrap/Dropdown';
import ReactCountryFlag from 'react-country-flag';
import Form from 'react-bootstrap/Form';
import { useUserAuth } from '../context/UserAuthContext';
import { useNavigate } from 'react-router-dom';
import countries from '../utils/countries';


const PhysioNewSignUpPhone = () => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+91');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [error, setError] = useState('');
  const [number, setNumber] = useState('');
  const { setUpRecaptcha, setResult, checkPhysioExist } = useUserAuth();
  const [showPopup, setShowPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Set country code based on user's location
    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords;
      const response = await fetch(`https://geocode.xyz/${latitude},${longitude}?json=1`);
      const data = await response.json();
      const userCountry = countries.find(country => country.name === data.country);
      if (userCountry) {
        setCountryCode(userCountry.code);
      }
    });
  }, []);

  const handlePhoneNumberChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, '');
    const selectedCountry = countries.find(country => country.code === countryCode);
    const phoneLength = selectedCountry.phoneLength;
    const limitedInput = inputNumber.slice(0, phoneLength);
    const isValid = limitedInput.length === phoneLength;
    setIsPhoneNumberValid(isValid);
    setMobileNumber(limitedInput);
    setNumber(limitedInput);
  };

  const getOtp = async (e) => {
    setOtpRequested(true);
    setIsLoading(true);
    e.preventDefault();
    setError('');

    try {
      console.log('number', number);
      const response = await setUpRecaptcha(number, countryCode);
      console.log('response from captcha ', response);
      setResult(response);

      if (isPhoneNumberValid && isCheckboxChecked) {
        navigate(`/otp/${countryCode.substring(1)}/${mobileNumber}`);
      }
    } catch (err) {
      if (err.code === 'auth/invalid-phone-number') 
        setShowErrorPopup(true);
      // setError(err.message);
      console.log(err.code);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleCountryCodeChange = (code) => {
    setCountryCode(code);
    setSearchQuery('');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCountries = countries.filter((country) => 
    country.name.toLowerCase().includes(searchQuery.toLowerCase()) || country.code.includes(searchQuery)
  );

  return (
    <div className='newPhysioSignUp'>
      <div className='content'>
        <div className='textContainer'>
          <div style={{display:"flex"}}>
            <img src={logo} style={{height:"50px"}} alt="Logo" />
            <div style={{fontWeight:"600",fontSize:"40px"}}>
              Wizphys.io
            </div>
          </div>
          <div style={{paddingLeft:"3%", width:"180px", lineHeight:"18px"}}>
            Everything you need for <br /> a speedy recovery
          </div>
        </div>
        <div className="docImageWrapper">
          <img src='https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioUIContent%2Fdocimage.png?alt=media&token=18ab9702-e015-4f87-ba5b-8cba69026cc7' alt="Doctor" className="docImage"/>
        </div>
      </div>
      <div className="inputSection">
        <div className="inputContainer">
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center', paddingLeft: '2%', fontWeight: '650', paddingRight: '2%', justifyContent: 'space-between' }}>
            <Dropdown onSelect={handleCountryCodeChange} style={{ marginRight: '10px', fontWeight: '650', fontSize: '120%', height:"48px" }}>
              <Dropdown.Toggle variant="light" id="dropdown-basic" className="custom-dropdown-toggle">
                <ReactCountryFlag countryCode={countries.find(country => country.code === countryCode)?.countryCode} svg style={{ marginLeft: '0px', marginRight: '10px', borderRadius:"100%",transform: 'scale(1.7)' }} /> 
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-animated">
                <Form.Control 
                  type="text" 
                  placeholder="Search country code or name" 
                  value={searchQuery} 
                  onChange={handleSearchChange} 
                  style={{ margin: '5%', width:"90%" }}
                />
                {filteredCountries.map((country, index) => (
                  <>
                    <Dropdown.Item key={country.code} eventKey={country.code}>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{marginRight: "10px"}}>
                          <ReactCountryFlag countryCode={country.countryCode} svg /> {country.name}
                        </div>
                        <div>{country.code}</div>
                      </div>
                    </Dropdown.Item>
                    {index !== filteredCountries.length - 1 && <hr style={{ margin: '0 8px', border: 'none', borderBottom: '2px solid #ccc' }} />}
                  </>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <div style={{ position: 'relative', width: '100%' }}>
              <span style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#000', fontSize:"20px", fontWeight:"600" }}>{countryCode}</span>
              <Form.Control
                type="tel"
                className="phone-input"
                value={mobileNumber}
                onChange={handlePhoneNumberChange}
                style={{ paddingLeft: '70px', fontWeight: '600', fontSize: '120%', backgroundColor: '#F6F6F6', border: '0.93px solid #E4E4E4' }}
                placeholder="Enter mobile number"
              />
            </div>
          </div>

          {error && (
            <div style={{ color: 'red', paddingLeft: "4%", marginTop: '10px', fontWeight: 'bold' }}>
              {error}
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }} id="recaptcha-container"></div>

          <div style={{ paddingLeft: "4%", display:"flex", paddingTop:"25px", paddingBottom:"5px" }}>
            <input type="checkbox" id="terms" style={{ backgroundColor: '#F6F6F6', border: '1.33px solid #DBDBDB', transform: 'scale(1.5)', paddingTop:"5px" }} onChange={handleCheckboxChange}/>
            <label htmlFor="terms" style={{ color: '#2D2F35', fontWeight:"500"}}>I agree to <a href="https://www.wizio.in/physio-agreement" style={{color:"#E09C5C", fontWeight:"600"}}>Terms and Conditions</a></label>
          </div>
        <button className={`InputBtn ${!isPhoneNumberValid || !isCheckboxChecked ? 'disabled' : 'enabled'}`} onClick={getOtp} disabled={!isPhoneNumberValid || !isCheckboxChecked}>
          {isLoading ? 'Loading...' : 'GET STARTED'}
        </button>
        </div>
      </div>
    </div>
  );
};

export default PhysioNewSignUpPhone;
