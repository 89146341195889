import React, { useState, useEffect, useRef } from 'react';
import { useUserAuth } from "../context/UserAuthContext";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import left from '../images/Left.png';
import playIcon from '../images/play-icon.png'; // Ensure you have a play icon image
import search from '../images/magnifyingglass.png';
import Modal from 'react-bootstrap/Modal';
import Tick from '../images/Blue OK.png';
import arrowUp from '../images/Up.png'
import arrowDown from '../images/Down.png'

const CustomModuleCreation = () => {
  const { fetchAllExcercises, addCustomModule } = useUserAuth(); // Assuming saveModule is your backend function
  const [exercises, setExercises] = useState({});
  const [filteredExercises, setFilteredExercises] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [moduleName, setModuleName] = useState('');
  const [showSuccessModel, setShowSuccessModal] = useState(false);
  const videoRef = useRef(null);
  const [visibleJoints, setVisibleJoints] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const { joint } = useParams();
  const navigate = useNavigate();

  const handleImageClick = () => {
    navigate('/joint');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchExercise = async () => {
      try {
        const data = await fetchAllExcercises();
        console.log("Fetched data:", data);
        setExercises(data);
        setFilteredExercises(data); // Initialize filteredExercises with all exercises
        const initialVisibleJoints = Object.keys(data).reduce((acc, key) => {
          acc[key] = true; // Show all joints by default
          return acc;
        }, {});
        setVisibleJoints(initialVisibleJoints);
      } catch (error) {
        console.error("Error fetching exercises", error);
        setError('Error fetching exercises');
      } finally {
        setLoading(false);
      }
    };

    fetchExercise();
  }, [fetchAllExcercises]);

  useEffect(() => {
    // Filter exercises based on the search term
    const filtered = {};
    Object.keys(exercises).forEach(jointName => {
      if (exercises[jointName]?.names) { // Check if names exist
        const filteredNames = exercises[jointName].names.filter(name =>
          name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (filteredNames.length > 0) {
          filtered[jointName] = {
            ...exercises[jointName],
            names: filteredNames,
            reps: filteredNames.map(name => exercises[jointName].reps[exercises[jointName].names.indexOf(name)]),
            weightage: filteredNames.map(name => exercises[jointName].weightage[exercises[jointName].names.indexOf(name)]),
          };
        }
      }
    });
    setFilteredExercises(filtered);
  }, [searchTerm, exercises]);

  const exerciseImages = (name) =>
    `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioExcercisePlanImages%2F${name}.png?alt=media&token=5021fba3-9698-4ae2-b7b6-f259ef69d9f9`;

  const videoUrl = (name) =>
    `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioExerciseVideo%2F${name}.mp4?alt=media&token=5021fba3-9698-4ae2-b7b6-f259ef69d9f9`;

  const handlePlayClick = (name) => {
    setSelectedVideo(videoUrl(name));
  };

  const formatExerciseName = (name) => {
    return name.replace(/([a-z])([A-Z])/g, '$1 $2');
  };
  
  

  const handleCloseFullscreen = () => {
    setSelectedVideo(null);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  };

  // useEffect(() => {
  //   if (selectedVideo && videoRef.current) {
  //     if (videoRef.current.requestFullscreen) {
  //       videoRef.current.requestFullscreen();
  //     } else if (videoRef.current.webkitRequestFullscreen) { /* Safari */
  //       videoRef.current.webkitRequestFullscreen();
  //     } else if (videoRef.current.msRequestFullscreen) { /* IE11 */
  //       videoRef.current.msRequestFullscreen();
  //     }
  //   }
  // }, [selectedVideo]);

  const handleExerciseClick = (exerciseName) => {
    setSelectedExercises(prev =>
      prev.includes(exerciseName)
        ? prev.filter(name => name !== exerciseName)
        : [...prev, exerciseName]
    );
  };

  const handleContinue = () => {
    setShowPopup(true);
  };


  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try{
      const selectedExercisesData = selectedExercises.map(exerciseName => {
        const jointName = Object.keys(filteredExercises).find(joint =>
          filteredExercises[joint]?.names.includes(exerciseName)
        );
        if (jointName) {
          const index = filteredExercises[jointName].names.indexOf(exerciseName);
          return {
            name: exerciseName,
            reps: filteredExercises[jointName].reps[index],
            weightage: filteredExercises[jointName].weightage[index],
            joint: jointName
          };
        }
        return null;
      }).filter(exercise => exercise !== null);
  
      // Call the backend function with moduleName and selectedExercisesData
      await addCustomModule(moduleName, selectedExercisesData, joint);
      console.log("OUTPUT: ",moduleName, selectedExercisesData, joint)
      setShowPopup(false);
    }catch(e){
      console.log("Error while adding custom module");
    }finally{
      setLoading(false);
      setShowSuccessModal(true);
    }
    
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedExercises = Array.from(selectedExercises);
    const [movedExercise] = reorderedExercises.splice(result.source.index, 1);
    reorderedExercises.splice(result.destination.index, 0, movedExercise);

    setSelectedExercises(reorderedExercises);
  };

  const handleClose = () => {
    setShowSuccessModal(false);
    navigate('/myprograms'); // Redirect to /home after closing the pop-up
  };

  const handleRepsChange = (exerciseName, value) => {
    const jointName = Object.keys(filteredExercises).find(joint =>
      filteredExercises[joint]?.names.includes(exerciseName)
    );
    if (jointName) {
      const updatedExercises = { ...filteredExercises };
      const exerciseIndex = updatedExercises[jointName].names.indexOf(exerciseName);
      updatedExercises[jointName].reps[exerciseIndex] = value;
      setFilteredExercises(updatedExercises);
      // Update the state to trigger a re-render
      setSelectedExercises([...selectedExercises]);
    }
  };

  const handleWeightageChange = (exerciseName, value) => {
    const jointName = Object.keys(filteredExercises).find(joint =>
      filteredExercises[joint]?.names.includes(exerciseName)
    );
    if (jointName) {
      const updatedExercises = { ...filteredExercises };
      const exerciseIndex = updatedExercises[jointName].names.indexOf(exerciseName);
      updatedExercises[jointName].weightage[exerciseIndex] = value;
      setFilteredExercises(updatedExercises);
      // Update the state to trigger a re-render
      setSelectedExercises([...selectedExercises]);
    }
  };

  const toggleJointVisibility = (jointName) => {
    setVisibleJoints(prev => ({
      ...prev,
      [jointName]: !prev[jointName]
    }));
  };


  return (
    <div className='profile-page' style={{ marginTop: "40px" }}>
      <div style={{ marginLeft: "5%" }}>
        <img
          className="AP-back-image"
          src={left}
          alt="back"
          onClick={handleImageClick}
        />
        &nbsp; &nbsp;
        <span className="calender-text" style={{ fontSize: "24px", fontWeight: "700" }}>Select Exercises</span>
      </div>

      <div className="search-bar-container">
        <img src={search} alt="Search" className="search-icon" />
        <input
          type="text"
          placeholder="Search for exercises..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
      </div>

      {selectedExercises.length > 0 && (
        <div className="selected-exercises">
          <h2>Selected Exercises</h2>
          <div style={{fontSize:"17px", paddingBottom:"10px"}}>Note : Drag to re-order the exercises</div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="selectedExercises">
              {(provided) => (
                <div
                  className="selected-exercise-list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {selectedExercises.map((exerciseName, index) => {
                    const jointName = Object.keys(filteredExercises).find(joint =>
                      filteredExercises[joint]?.names.includes(exerciseName)
                    );
                    const exerciseIndex = jointName ? filteredExercises[jointName].names.indexOf(exerciseName) : -1;
                    if (jointName && exerciseIndex >= 0) {
                      return (
                        <Draggable key={exerciseName} draggableId={exerciseName} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`cus-plan-block ${selectedExercises.includes(exerciseName) ? 'selected' : ''}`}
                              // onClick={() => handleExerciseClick(exerciseName)}
                            >
                              <div className='image-container'>
                                <img
                                  className={`cus-plan-img ${filteredExercises[jointName].names[exerciseIndex] ? 'loading' : 'loaded'}`}
                                  src={exerciseImages(exerciseName)}
                                  alt={exerciseName}
                                  onLoad={(e) => e.currentTarget.classList.replace('loading', 'loaded')}
                                />
                                <img
                                  src={playIcon}
                                  alt="Play"
                                  className='play-icon'
                                  onClick={() => handlePlayClick(exerciseName)}
                                />
                              </div>
                              <div className='cus-plan-text'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                  <div className='cus-plan-name'>
                                    {formatExerciseName(exerciseName)}
                                  </div>
                                  <div>
                                  <label>
                                    <select
                                      value={filteredExercises[jointName].weightage[exerciseIndex]}
                                      onChange={(e) => handleWeightageChange(exerciseName, e.target.value)}
                                      style={{borderRadius:"8px"}}
                                    >
                                      {[...Array(10).keys()].map(i => (
                                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                                      ))}
                                    </select> points
                                  </label>
                                  </div>
                                </div>

                                <div className='cus-plan-reps'>
                                  <label>
                                    Reps:
                                    <select
                                      value={filteredExercises[jointName].reps[exerciseIndex]}
                                      onChange={(e) => handleRepsChange(exerciseName, e.target.value)}
                                      style={{borderRadius:"8px"}}
                                    >
                                      {[...Array(10).keys()].map(i => (
                                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                                      ))}
                                    </select>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                    return null;
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}

      {loading ? (
        <div className="spinner-container">
          <div className="spinner-grow" role="status">
          </div>
        </div>
      ) : (
        <div className='cus-exercise-container'>
          {Object.keys(filteredExercises).map((jointName) => (
            <div key={jointName} className='cus-exercise-section'>
              <div className='cus-exercise-heading' style={{display:"flex", justifyContent:"space-between"}} onClick={() => toggleJointVisibility(jointName)}>
                <div>
                  {jointName.charAt(0).toUpperCase() + jointName.slice(1)}
                </div>
                <img
                  src={visibleJoints[jointName] ? arrowUp : arrowDown}
                  alt={visibleJoints[jointName] ? 'Hide' : 'Show'}
                  className="dropdown-arrow"
                  style={{height:"20px"}}
                />
              </div>
              {visibleJoints[jointName] && (
                <div className='cus-exercise-list'>
                  {filteredExercises[jointName]?.names?.map((exerciseName, index) => (
                    <div
                      key={exerciseName}
                      className={`cus-plan-block ${selectedExercises.includes(exerciseName) ? 'selected' : ''}`}
                      onClick={() => handleExerciseClick(exerciseName)}
                    >
                      <div className='image-container'>
                        <img
                          className={`cus-plan-img ${filteredExercises[jointName]?.names[index] ? 'loading' : 'loaded'}`}
                          src={exerciseImages(exerciseName)}
                          alt={exerciseName}
                          onLoad={(e) => e.currentTarget.classList.replace('loading', 'loaded')}
                        />
                        <img
                          src={playIcon}
                          alt="Play"
                          className='play-icon'
                          onClick={() => handlePlayClick(exerciseName)}
                        />
                      </div>
                      <div className='cus-plan-text'>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className='cus-plan-name'>
                            {formatExerciseName(exerciseName)}
                          </div>
                          <div>
                            {filteredExercises[jointName]?.weightage[index]} points
                          </div>
                        </div>

                        <div className='cus-plan-reps'>
                          Reps: {filteredExercises[jointName]?.reps[index]}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <Link className='AP-button2' onClick={handleContinue}>
        <div className='inner-AP-button'>
          <div className='AP-text'>CONTINUE</div>
        </div>
      </Link>

      <br />
      <br />
      <br />
      <br />

      {/* Fullscreen Video Player */}
      {selectedVideo && (
        <div className='fullscreen-video-container'>
          <video ref={videoRef} className='fullscreen-video-player' autoPlay controls>
            <source src={selectedVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button className='fullscreen-close-button' onClick={handleCloseFullscreen}>
            ✕
          </button>
        </div>
      )}

      {/* Module Name Popup */}
      {showPopup && (
      <div className='popup-overlay'>
        <div className='popup-container'>
          <button className='popup-close-button' onClick={handleClosePopup}>✕</button>
          <div style={{fontSize:"16px"}}>Enter Module Name</div>
          <input
            type="text"
            value={moduleName}
            onChange={(e) => setModuleName(e.target.value)}
            placeholder="Module name"
            className='popup-input'
          />
          <button className='AP-button3' onClick={handleSave}>
            <div className='inner-AP-button'>
              <div className='AP-text'>SAVE</div>
            </div>
          </button>
        </div>
      </div>
    )}

    { showSuccessModel && (
      <div className='popup'>
        <Modal 
          show={showSuccessModel} 
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className='popup-header'>
              <div>
                <img src={Tick} alt="Tick" /> 
              </div>
              <div style={{fontFamily:"Rajdhani", fontSize:"26px", fontWeight:"700"}}>Successful</div>
            </div>
            <div style={{fontFamily:"Rajdhani", fontSize:"18px", fontWeight:"600", display:"flex", justifyContent:"center", paddingBottom:"10px"}}>
              Your program was created
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )}
  </div>
  );
};

export default CustomModuleCreation;
