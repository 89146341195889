// countries.js


const countries = [
  { name: "Afghanistan", code: "+93", countryCode: "AF", phoneLength: 9 },
  { name: "Albania", code: "+355", countryCode: "AL", phoneLength: 9 },
  { name: "Algeria", code: "+213", countryCode: "DZ", phoneLength: 9 },
  { name: "American Samoa", code: "+1684", countryCode: "AS", phoneLength: 10 },
  { name: "Andorra", code: "+376", countryCode: "AD", phoneLength: 6 },
  { name: "Angola", code: "+244", countryCode: "AO", phoneLength: 9 },
  { name: "Anguilla", code: "+1264", countryCode: "AI", phoneLength: 10 },
  { name: "Antarctica", code: "+672", countryCode: "AQ", phoneLength: 8 },
  { name: "Antigua and Barbuda", code: "+1268", countryCode: "AG", phoneLength: 10 },
  { name: "Argentina", code: "+54", countryCode: "AR", phoneLength: 10 },
  { name: "Armenia", code: "+374", countryCode: "AM", phoneLength: 8 },
  { name: "Aruba", code: "+297", countryCode: "AW", phoneLength: 7 },
  { name: "Australia", code: "+61", countryCode: "AU", phoneLength: 9 },
  { name: "Austria", code: "+43", countryCode: "AT", phoneLength: 10 },
  { name: "Azerbaijan", code: "+994", countryCode: "AZ", phoneLength: 9 },
  { name: "Bahamas", code: "+1242", countryCode: "BS", phoneLength: 10 },
  { name: "Bahrain", code: "+973", countryCode: "BH", phoneLength: 8 },
  { name: "Bangladesh", code: "+880", countryCode: "BD", phoneLength: 10 },
  { name: "Barbados", code: "+1246", countryCode: "BB", phoneLength: 10 },
  { name: "Belarus", code: "+375", countryCode: "BY", phoneLength: 9 },
  { name: "Belgium", code: "+32", countryCode: "BE", phoneLength: 9 },
  { name: "Belize", code: "+501", countryCode: "BZ", phoneLength: 7 },
  { name: "Benin", code: "+229", countryCode: "BJ", phoneLength: 8 },
  { name: "Bermuda", code: "+1441", countryCode: "BM", phoneLength: 10 },
  { name: "Bhutan", code: "+975", countryCode: "BT", phoneLength: 8 },
  { name: "Bolivia", code: "+591", countryCode: "BO", phoneLength: 8 },
  { name: "Bosnia and Herzegovina", code: "+387", countryCode: "BA", phoneLength: 8 },
  { name: "Botswana", code: "+267", countryCode: "BW", phoneLength: 8 },
  { name: "Brazil", code: "+55", countryCode: "BR", phoneLength: 11 },
  { name: "British Indian Ocean Territory", code: "+246", countryCode: "IO", phoneLength: 7 },
  { name: "Brunei", code: "+673", countryCode: "BN", phoneLength: 7 },
  { name: "Bulgaria", code: "+359", countryCode: "BG", phoneLength: 9 },
  { name: "Burkina Faso", code: "+226", countryCode: "BF", phoneLength: 8 },
  { name: "Burundi", code: "+257", countryCode: "BI", phoneLength: 8 },
  { name: "Cambodia", code: "+855", countryCode: "KH", phoneLength: 9 },
  { name: "Cameroon", code: "+237", countryCode: "CM", phoneLength: 9 },
  { name: "Canada", code: "+1", countryCode: "CA", phoneLength: 10 },
  { name: "Cape Verde", code: "+238", countryCode: "CV", phoneLength: 7 },
  { name: "Cayman Islands", code: "+345", countryCode: "KY", phoneLength: 10 },
  { name: "Central African Republic", code: "+236", countryCode: "CF", phoneLength: 8 },
  { name: "Chad", code: "+235", countryCode: "TD", phoneLength: 8 },
  { name: "Chile", code: "+56", countryCode: "CL", phoneLength: 9 },
  { name: "China", code: "+86", countryCode: "CN", phoneLength: 11 },
  { name: "Christmas Island", code: "+61", countryCode: "CX", phoneLength: 9 },
  { name: "Cocos Islands", code: "+61", countryCode: "CC", phoneLength: 6 },
  { name: "Colombia", code: "+57", countryCode: "CO", phoneLength: 10 },
  { name: "Comoros", code: "+269", countryCode: "KM", phoneLength: 7 },
  { name: "Congo", code: "+242", countryCode: "CG", phoneLength: 9 },
  { name: "Cook Islands", code: "+682", countryCode: "CK", phoneLength: 5 },
  { name: "Costa Rica", code: "+506", countryCode: "CR", phoneLength: 8 },
  { name: "Croatia", code: "+385", countryCode: "HR", phoneLength: 9 },
  { name: "Cuba", code: "+53", countryCode: "CU", phoneLength: 8 },
  { name: "Cyprus", code: "+357", countryCode: "CY", phoneLength: 8 },
  { name: "Czech Republic", code: "+420", countryCode: "CZ", phoneLength: 9 },
  { name: "Denmark", code: "+45", countryCode: "DK", phoneLength: 8 },
  { name: "Djibouti", code: "+253", countryCode: "DJ", phoneLength: 6 },
  { name: "Dominica", code: "+1767", countryCode: "DM", phoneLength: 10 },
  { name: "Dominican Republic", code: "+1849", countryCode: "DO", phoneLength: 10 },
  { name: "Ecuador", code: "+593", countryCode: "EC", phoneLength: 9 },
  { name: "Egypt", code: "+20", countryCode: "EG", phoneLength: 10 },
  { name: "El Salvador", code: "+503", countryCode: "SV", phoneLength: 8 },
  { name: "Equatorial Guinea", code: "+240", countryCode: "GQ", phoneLength: 9 },
  { name: "Eritrea", code: "+291", countryCode: "ER", phoneLength: 7 },
  { name: "Estonia", code: "+372", countryCode: "EE", phoneLength: 8 },
  { name: "Ethiopia", code: "+251", countryCode: "ET", phoneLength: 9 },
  { name: "Fiji", code: "+679", countryCode: "FJ", phoneLength: 7 },
  { name: "Finland", code: "+358", countryCode: "FI", phoneLength: 10 },
  { name: "France", code: "+33", countryCode: "FR", phoneLength: 9 },
  { name: "French Guiana", code: "+594", countryCode: "GF", phoneLength: 9 },
  { name: "French Polynesia", code: "+689", countryCode: "PF", phoneLength: 6 },
  { name: "Gabon", code: "+241", countryCode: "GA", phoneLength: 8 },
  { name: "Gambia", code: "+220", countryCode: "GM", phoneLength: 7 },
  { name: "Georgia", code: "+995", countryCode: "GE", phoneLength: 9 },
  { name: "Germany", code: "+49", countryCode: "DE", phoneLength: 10 },
  { name: "Ghana", code: "+233", countryCode: "GH", phoneLength: 9 },
  { name: "Gibraltar", code: "+350", countryCode: "GI", phoneLength: 8 },
  { name: "Greece", code: "+30", countryCode: "GR", phoneLength: 10 },
  { name: "Greenland", code: "+299", countryCode: "GL", phoneLength: 6 },
  { name: "Grenada", code: "+1473", countryCode: "GD", phoneLength: 10 },
  { name: "Guadeloupe", code: "+590", countryCode: "GP", phoneLength: 9 },
  { name: "Guam", code: "+1671", countryCode: "GU", phoneLength: 10 },
  { name: "Guatemala", code: "+502", countryCode: "GT", phoneLength: 8 },
  { name: "Guernsey", code: "+44", countryCode: "GG", phoneLength: 10 },
  { name: "Guinea", code: "+224", countryCode: "GN", phoneLength: 9 },
  { name: "Guinea-Bissau", code: "+245", countryCode: "GW", phoneLength: 7 },
  { name: "Guyana", code: "+592", countryCode: "GY", phoneLength: 7 },
  { name: "Haiti", code: "+509", countryCode: "HT", phoneLength: 8 },
  { name: "Honduras", code: "+504", countryCode: "HN", phoneLength: 8 },
  { name: "Hong Kong", code: "+852", countryCode: "HK", phoneLength: 8 },
  { name: "Hungary", code: "+36", countryCode: "HU", phoneLength: 9 },
  { name: "Iceland", code: "+354", countryCode: "IS", phoneLength: 7 },
  { name: "India", code: "+91", countryCode: "IN", phoneLength: 10 },
  { name: "Indonesia", code: "+62", countryCode: "ID", phoneLength: 10 },
  { name: "Iran", code: "+98", countryCode: "IR", phoneLength: 10 },
  { name: "Iraq", code: "+964", countryCode: "IQ", phoneLength: 10 },
  { name: "Ireland", code: "+353", countryCode: "IE", phoneLength: 9 },
  { name: "Isle of Man", code: "+44", countryCode: "IM", phoneLength: 10 },
  { name: "Israel", code: "+972", countryCode: "IL", phoneLength: 9 },
  { name: "Italy", code: "+39", countryCode: "IT", phoneLength: 10 },
  { name: "Jamaica", code: "+1876", countryCode: "JM", phoneLength: 10 },
  { name: "Japan", code: "+81", countryCode: "JP", phoneLength: 10 },
  { name: "Jersey", code: "+44", countryCode: "JE", phoneLength: 10 },
  { name: "Jordan", code: "+962", countryCode: "JO", phoneLength: 9 },
  { name: "Kazakhstan", code: "+7", countryCode: "KZ", phoneLength: 10 },
  { name: "Kenya", code: "+254", countryCode: "KE", phoneLength: 9 },
  { name: "Kiribati", code: "+686", countryCode: "KI", phoneLength: 8 },
  { name: "Kuwait", code: "+965", countryCode: "KW", phoneLength: 8 },
  { name: "Kyrgyzstan", code: "+996", countryCode: "KG", phoneLength: 9 },
  { name: "Laos", code: "+856", countryCode: "LA", phoneLength: 9 },
  { name: "Latvia", code: "+371", countryCode: "LV", phoneLength: 8 },
  { name: "Lebanon", code: "+961", countryCode: "LB", phoneLength: 8 },
  { name: "Lesotho", code: "+266", countryCode: "LS", phoneLength: 8 },
  { name: "Liberia", code: "+231", countryCode: "LR", phoneLength: 7 },
  { name: "Libya", code: "+218", countryCode: "LY", phoneLength: 9 },
  { name: "Liechtenstein", code: "+423", countryCode: "LI", phoneLength: 7 },
  { name: "Lithuania", code: "+370", countryCode: "LT", phoneLength: 8 },
  { name: "Luxembourg", code: "+352", countryCode: "LU", phoneLength: 9 },
  { name: "Macau", code: "+853", countryCode: "MO", phoneLength: 8 },
  { name: "Madagascar", code: "+261", countryCode: "MG", phoneLength: 9 },
  { name: "Malawi", code: "+265", countryCode: "MW", phoneLength: 9 },
  { name: "Malaysia", code: "+60", countryCode: "MY", phoneLength: 10 },
  { name: "Maldives", code: "+960", countryCode: "MV", phoneLength: 7 },
  { name: "Mali", code: "+223", countryCode: "ML", phoneLength: 8 },
  { name: "Malta", code: "+356", countryCode: "MT", phoneLength: 8 },
  { name: "Marshall Islands", code: "+692", countryCode: "MH", phoneLength: 7 },
  { name: "Martinique", code: "+596", countryCode: "MQ", phoneLength: 9 },
  { name: "Mauritania", code: "+222", countryCode: "MR", phoneLength: 8 },
  { name: "Mauritius", code: "+230", countryCode: "MU", phoneLength: 8 },
  { name: "Mayotte", code: "+262", countryCode: "YT", phoneLength: 9 },
  { name: "Mexico", code: "+52", countryCode: "MX", phoneLength: 10 },
  { name: "Micronesia", code: "+691", countryCode: "FM", phoneLength: 7 },
  { name: "Moldova", code: "+373", countryCode: "MD", phoneLength: 8 },
  { name: "Monaco", code: "+377", countryCode: "MC", phoneLength: 8 },
  { name: "Mongolia", code: "+976", countryCode: "MN", phoneLength: 8 },
  { name: "Montenegro", code: "+382", countryCode: "ME", phoneLength: 8 },
  { name: "Montserrat", code: "+1664", countryCode: "MS", phoneLength: 10 },
  { name: "Morocco", code: "+212", countryCode: "MA", phoneLength: 9 },
  { name: "Mozambique", code: "+258", countryCode: "MZ", phoneLength: 9 },
  { name: "Myanmar", code: "+95", countryCode: "MM", phoneLength: 9 },
  { name: "Namibia", code: "+264", countryCode: "NA", phoneLength: 9 },
  { name: "Nauru", code: "+674", countryCode: "NR", phoneLength: 7 },
  { name: "Nepal", code: "+977", countryCode: "NP", phoneLength: 10 },
  { name: "Netherlands", code: "+31", countryCode: "NL", phoneLength: 9 },
  { name: "New Zealand", code: "+64", countryCode: "NZ", phoneLength: 9 },
  { name: "Nicaragua", code: "+505", countryCode: "NI", phoneLength: 8 },
  { name: "Niger", code: "+227", countryCode: "NE", phoneLength: 8 },
  { name: "Nigeria", code: "+234", countryCode: "NG", phoneLength: 10 },
  { name: "Niue", code: "+683", countryCode: "NU", phoneLength: 4 },
  { name: "Norfolk Island", code: "+672", countryCode: "NF", phoneLength: 6 },
  { name: "North Korea", code: "+850", countryCode: "KP", phoneLength: 9 },
  { name: "North Macedonia", code: "+389", countryCode: "MK", phoneLength: 8 },
  { name: "Northern Mariana Islands", code: "+1670", countryCode: "MP", phoneLength: 10 },
  { name: "Norway", code: "+47", countryCode: "NO", phoneLength: 8 },
  { name: "Oman", code: "+968", countryCode: "OM", phoneLength: 8 },
  { name: "Pakistan", code: "+92", countryCode: "PK", phoneLength: 10 },
  { name: "Palau", code: "+680", countryCode: "PW", phoneLength: 7 },
  { name: "Palestine", code: "+970", countryCode: "PS", phoneLength: 9 },
  { name: "Panama", code: "+507", countryCode: "PA", phoneLength: 8 },
  { name: "Papua New Guinea", code: "+675", countryCode: "PG", phoneLength: 8 },
  { name: "Paraguay", code: "+595", countryCode: "PY", phoneLength: 9 },
  { name: "Peru", code: "+51", countryCode: "PE", phoneLength: 9 },
  { name: "Philippines", code: "+63", countryCode: "PH", phoneLength: 10 },
  { name: "Pitcairn Islands", code: "+64", countryCode: "PN", phoneLength: 9 },
  { name: "Poland", code: "+48", countryCode: "PL", phoneLength: 9 },
  { name: "Portugal", code: "+351", countryCode: "PT", phoneLength: 9 },
  { name: "Puerto Rico", code: "+1787", countryCode: "PR", phoneLength: 10 },
  { name: "Qatar", code: "+974", countryCode: "QA", phoneLength: 8 },
  { name: "Réunion", code: "+262", countryCode: "RE", phoneLength: 9 },
  { name: "Romania", code: "+40", countryCode: "RO", phoneLength: 10 },
  { name: "Russia", code: "+7", countryCode: "RU", phoneLength: 10 },
  { name: "Rwanda", code: "+250", countryCode: "RW", phoneLength: 9 },
  { name: "Saint Barthélemy", code: "+590", countryCode: "BL", phoneLength: 9 },
  { name: "Saint Helena", code: "+290", countryCode: "SH", phoneLength: 4 },
  { name: "Saint Kitts and Nevis", code: "+1869", countryCode: "KN", phoneLength: 10 },
  { name: "Saint Lucia", code: "+1758", countryCode: "LC", phoneLength: 10 },
  { name: "Saint Martin", code: "+590", countryCode: "MF", phoneLength: 9 },
  { name: "Saint Pierre and Miquelon", code: "+508", countryCode: "PM", phoneLength: 6 },
  { name: "Saint Vincent and the Grenadines", code: "+1784", countryCode: "VC", phoneLength: 10 },
  { name: "Samoa", code: "+685", countryCode: "WS", phoneLength: 7 },
  { name: "San Marino", code: "+378", countryCode: "SM", phoneLength: 9 },
  { name: "São Tomé and Príncipe", code: "+239", countryCode: "ST", phoneLength: 7 },
  { name: "Saudi Arabia", code: "+966", countryCode: "SA", phoneLength: 9 },
  { name: "Senegal", code: "+221", countryCode: "SN", phoneLength: 9 },
  { name: "Serbia", code: "+381", countryCode: "RS", phoneLength: 9 },
  { name: "Seychelles", code: "+248", countryCode: "SC", phoneLength: 7 },
  { name: "Sierra Leone", code: "+232", countryCode: "SL", phoneLength: 8 },
  { name: "Singapore", code: "+65", countryCode: "SG", phoneLength: 8 },
  { name: "Sint Maarten", code: "+1721", countryCode: "SX", phoneLength: 10 },
  { name: "Slovakia", code: "+421", countryCode: "SK", phoneLength: 9 },
  { name: "Slovenia", code: "+386", countryCode: "SI", phoneLength: 9 },
  { name: "Solomon Islands", code: "+677", countryCode: "SB", phoneLength: 7 },
  { name: "Somalia", code: "+252", countryCode: "SO", phoneLength: 9 },
  { name: "South Africa", code: "+27", countryCode: "ZA", phoneLength: 9 },
  { name: "South Korea", code: "+82", countryCode: "KR", phoneLength: 10 },
  { name: "South Sudan", code: "+211", countryCode: "SS", phoneLength: 9 },
  { name: "Spain", code: "+34", countryCode: "ES", phoneLength: 9 },
  { name: "Sri Lanka", code: "+94", countryCode: "LK", phoneLength: 9 },
  { name: "Sudan", code: "+249", countryCode: "SD", phoneLength: 9 },
  { name: "Suriname", code: "+597", countryCode: "SR", phoneLength: 7 },
  { name: "Svalbard and Jan Mayen", code: "+47", countryCode: "SJ", phoneLength: 8 },
  { name: "Swaziland", code: "+268", countryCode: "SZ", phoneLength: 7 },
  { name: "Sweden", code: "+46", countryCode: "SE", phoneLength: 9 },
  { name: "Switzerland", code: "+41", countryCode: "CH", phoneLength: 9 },
  { name: "Syria", code: "+963", countryCode: "SY", phoneLength: 9 },
  { name: "Taiwan", code: "+886", countryCode: "TW", phoneLength: 9 },
  { name: "Tajikistan", code: "+992", countryCode: "TJ", phoneLength: 9 },
  { name: "Tanzania", code: "+255", countryCode: "TZ", phoneLength: 9 },
  { name: "Thailand", code: "+66", countryCode: "TH", phoneLength: 9 },
  { name: "Timor-Leste", code: "+670", countryCode: "TL", phoneLength: 7 },
  { name: "Togo", code: "+228", countryCode: "TG", phoneLength: 8 },
  { name: "Tokelau", code: "+690", countryCode: "TK", phoneLength: 4 },
  { name: "Tonga", code: "+676", countryCode: "TO", phoneLength: 5 },
  { name: "Trinidad and Tobago", code: "+1868", countryCode: "TT", phoneLength: 10 },
  { name: "Tunisia", code: "+216", countryCode: "TN", phoneLength: 8 },
  { name: "Turkey", code: "+90", countryCode: "TR", phoneLength: 10 },
  { name: "Turkmenistan", code: "+993", countryCode: "TM", phoneLength: 8 },
  { name: "Turks and Caicos Islands", code: "+1649", countryCode: "TC", phoneLength: 10 },
  { name: "Tuvalu", code: "+688", countryCode: "TV", phoneLength: 5 },
  { name: "Uganda", code: "+256", countryCode: "UG", phoneLength: 9 },
  { name: "Ukraine", code: "+380", countryCode: "UA", phoneLength: 9 },
  { name: "United Arab Emirates", code: "+971", countryCode: "AE", phoneLength: 9 },
  { name: "United Kingdom", code: "+44", countryCode: "GB", phoneLength: 10 },
  { name: "United States", code: "+1", countryCode: "US", phoneLength: 10 },
  { name: "Uruguay", code: "+598", countryCode: "UY", phoneLength: 9 },
  { name: "Uzbekistan", code: "+998", countryCode: "UZ", phoneLength: 9 },
  { name: "Vanuatu", code: "+678", countryCode: "VU", phoneLength: 7 },
  { name: "Vatican City", code: "+39", countryCode: "VA", phoneLength: 10 },
  { name: "Venezuela", code: "+58", countryCode: "VE", phoneLength: 10 },
  { name: "Vietnam", code: "+84", countryCode: "VN", phoneLength: 9 },
  { name: "Wallis and Futuna", code: "+681", countryCode: "WF", phoneLength: 6 },
  { name: "Western Sahara", code: "+212", countryCode: "EH", phoneLength: 9 },
  { name: "Yemen", code: "+967", countryCode: "YE", phoneLength: 9 },
  { name: "Zambia", code: "+260", countryCode: "ZM", phoneLength: 9 },
  { name: "Zimbabwe", code: "+263", countryCode: "ZW", phoneLength: 9 }
  ]

export default countries;
