import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';

const VerticalBarChart = ({ data }) => {
  const chartContainer = useRef(null);
  const barWidth = 20; // Desired width for each bar
  const chartWidth = data.length * (barWidth + 20);

  useEffect(() => {
    const blueRange = Math.max(...data.map((item) => parseFloat(item.score)));
    const greyRange = 100 - blueRange;
    const yAxisLabels = [0, 25, 50, 75, 100]; // Set your desired y-axis labels here

    const chart = new Highcharts.Chart({
      chart: {
        renderTo: chartContainer.current,
        type: 'column',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        spacingLeft: 10, // Add 10px left padding
        spacingRight: 10, // Add 10px right padding
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: data.map((item) => `${item.day}`),
        labels: {
          // rotation: -45,
        },
        lineColor: 'transparent',
      },
      yAxis: {
        title: {
          text: '',
        },
        max: 100,
        gridLineWidth: 1.5,
        
        labels: {
          formatter: function () {
            return this.value;
          },
          offset: 10,
        },
        tickPositions: yAxisLabels, // Set fixed tick positions for the y-axis
        zones: [
          {
            value: blueRange, // Set the range for the blue bars dynamically
            color: '#083FC7', // Blue color for the bars
          },
          {
            color: '#CCCCCC', // Grey color for the remaining area
          },
        ],
      },
      plotOptions: {
        column: {
          borderRadius: 100, // Set the border radius for the bars
          color: '#2d2f35',
        },
      },
      series: [
        {
          name: 'Scores',
          data: data.map((item) => parseFloat(item.score)),
          color: '#083FC7',
          pointWidth: 15, // Set the width of each bar to 20px
          showInLegend: false,
        },
      ],
      credits: {
        enabled: false, // Remove the Highcharts.com link
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div style={{ overflowX: 'auto' }}>
      <div style={{ width: chartWidth < 500 ? '100%' : chartWidth + 'px' }}>
        <div ref={chartContainer} style={{ height: '175px' }}></div>
      </div>
    </div>
  );
};

export default VerticalBarChart;
