import React, { useState , useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import left from '../images/Left.png'
import { WeeklyUpcomingAppointments } from './components/WeeklyUpcomingAppointments';
import { Footer } from './components/Footer';
import { useUserAuth } from "../context/UserAuthContext";
import NoInternetPopup from './components/NoInternetPopup';



export const VideoCallHistory = () => {
  const navigate = useNavigate();
  const { VideoCallHistoryData, PendingReportData } = useUserAuth();
  
  const [VideoCallHistory, setVideoCallHistory] = useState(null);
  const [PendingReports, setPendingReports] = useState(null);
  const [showData, setShowData] = useState(null);

  useEffect(() => {
    const fetchVideoCallHistoryData = async () => {

      const localData = localStorage.getItem('Data');
      setShowData(localStorage.getItem('Data'));
      console.log("this is patient data from local storage 1",localData);
      console.log("this is patient data from local storage 2",showData);

      console.log('Fetching Weekly Upcoming Appointment data...');
      setVideoCallHistory(JSON.parse(localStorage.getItem('VideoCallHistoryData')));
      console.log("this is video call history from local 3",VideoCallHistory);
      const data = await VideoCallHistoryData();
      console.log("this is the final output  ",data );
      const historyArray = Object.values(data);
      console.log("this is the final output  2",historyArray );

      localStorage.setItem('VideoCallHistoryData', JSON.stringify(historyArray));

      // Sort the VideoCallHistory in ascending order based on the date
      historyArray.sort((a, b) => {
        const dateA = new Date(a.Session_Date);
        const dateB = new Date(b.Session_Date);
        return dateA - dateB;
      });

      setVideoCallHistory(historyArray);
      console.log("data in front end  ", VideoCallHistory);
    };

    fetchVideoCallHistoryData();
  }, []);

  // useEffect(() => {
  //   const fetchPendingReports = async () => {
  //     console.log('Fetching Weekly Upcoming Appointment data...');
  //     const data = await PendingReportData();
  //     // const historyArray = Object.values(data);
  //     setPendingReports(data);
  //     console.log("data in front end ", data);
  //   };

  //   fetchPendingReports();
  // }, []);



  const handleImageClick = () => {
    navigate('/profile');
  };


  return (
    <div>
      <div style={{marginTop:"7%",marginBottom:"5%"}}>
        <img 
            className='AP-back-image'
            src={left}
            alt="back"
            onClick={handleImageClick}
            style={{marginLeft:"5%"}}
        />
        &nbsp; &nbsp;
        <span className='calender-text'>Video call History</span>
      </div>
      <WeeklyUpcomingAppointments/>
      
      {/* THIS BLOCK NEEDS TO BE CHANGED AS PER DATA COMING FROM BACKEND */}
      {/* <div className='VCH-RP-block'>
        <div className='RP-header'>
          Report Pending
        </div>
        <div className='RP-block'>  
          <div className='RP-block-inner' style={{backgroundColor:'#fcffe1'}}>
          <Link to= '/report' style={{textDecoration:"none"}}>
              <div className='RP-block-name'>Shruti Nirbhavane</div>
              <div className='RP-block-VC'>Video Consultation</div>
              <div className='RP-block-time'>02 Aug 2023, 1:45</div>
          </Link>
          </div>
          <div className='RP-block-inner'>
          <Link to= '/report' style={{textDecoration:"none"}}>
              <div className='RP-block-name'>Shruti Nirbhavane</div>
              <div className='RP-block-VC'>Video Consultation</div>
              <div className='RP-block-time'>02 Aug 2023, 1:45</div>
          </Link>
          </div>
        </div>
      </div> */}

      {/* <div className='VCH-RP-block'>      /// this is pending report section which is currently not in use can be used later 
        <div className='RP-header'>
          Report Pending
        </div>
        <div className='RP-block'>
          {PendingReports && Object.keys(PendingReports).length > 0 && VideoCallHistory ? (
            VideoCallHistory.map((videoCallData, index) => {
              if (PendingReports[videoCallData.PatientID]) {
                return (
                  <>
                  <div
                    className='RP-block-inner'
                    style={{ backgroundColor: '#fcffe1' }}
                    key={index}
                  >
                    <Link to='/report' style={{ textDecoration: 'none' }}>
                      <div className='RP-block-name'>{videoCallData.PatientName}</div>
                      <div className='RP-block-VC'>Video Consultation</div>
                      <div className='RP-block-time'>
                        {videoCallData.Session_Date}, {videoCallData.Session_Time}
                      </div>
                    </Link>
                  </div>
                  
                  </>
                );
              }
              return null;
            })
          ) : (
            <div className='exercise-data-error-block'>No Pending Reports</div>
          )}
        </div>
      </div> */}

      <br />

      <div className='VCH-block'>
        <div className='VCH-block-name'>
          Past
        </div>
      </div>
      <NoInternetPopup/>
      <div className='VC-block-history'>
        {VideoCallHistory && VideoCallHistory.length > 0 ? (
          VideoCallHistory.flat().map((item, index) => (
            <div key={index}>
              <div className='VC-month'>
                {/* {item.Session_Date.split(',')[1].trim()} Extract the month */}
              </div>
              <div className='history-block'>
                <div className='RP-block-name1'>
                  {item.PatientName}
                </div>
                <div className='VC-block-inner'>
                  <div className='RP-block-VC1'>
                    Video Consultation
                  </div>
                  <div className='RP-block-time1'>
                    {item.Session_Time}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className='exercise-data-error-block'>
            No Past Consultation Records 
          </div>
        )}
      </div>


      <br />
      <br />
      <br />
      {/* <Footer/> */}
    </div>
  )
}
