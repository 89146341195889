export const assessment = [
  {
    key: "AbductionLeftScore",
    title: "Abduction - Left",
    img: "SAbduction L",
  },
  {
    key: "AbductionRightScore",
    title: "Abduction - Right",
    img: "SAbduction R",
  },  {
    key: "ExternalRotationLeftScore",
    title: "External Rotation - Left",
    img: "SExternalRotation L",
  },  {
    key: "ExternalRotationRightScore",
    title: "External Rotation - Right",
    img: "SExternalRotation R",
  },  {
    key: "FlexionLeftScore",
    title: "Flexion - Left",
    img: "SFlexion L",
  },  {
    key: "FlexionRightScore",
    title: "Flexion - Right",
    img: "SFlexion R",
  },  {
    key: "leftDisabilityScoreAverage",
    title: "Disability Scale - Left",
    img: "Disability",
  },  {
    key: "leftPainScoreAverage",
    title: "Pain Scale Left",
    img: "PainScale",
  },  {
    key: "rightDisabilityScoreAverage",
    title: "Disability Scale - Right",
    img: "Disability",
  },  {
    key: "rightPainScoreAverage",
    title: "Pain Scale Right",
    img: "PainScale",
  },  {
    key: "HipAbductionLeftScore",
    title: "Hip Abduction Left",
    img: "Abduction L",
  },  {
    key: "HipAbductionRightScore",
    title: "Hip Abduction Right",
    img: "Abduction R",
  },  {
    key: "HipExtensionLeftScore",
    title: "Hip Extension Left",
    img: "Extension L",
  },  {
    key: "HipExtensionRightScore",
    title: "Hip Extension Right",
    img: "Extension R",
  },  {
    key: "HipFlexionLeftScore",
    title: "Hip Flexion Left",
    img: "Flexion L",
  },  {
    key: "HipFlexionRightScore",
    title: "Hip Flexion Right",
    img: "Flexion R",
  },  {
    key: "HipPainScore",
    title: "Hip Pain",
    img: "PainScale",
  },  {
    key: "KneeExtensionLeftScore",
    title: "Knee Extension Left",
    img: "kneeEx L",
  },  {
    key: "KneeExtensionRightScore",
    title: "Knee Extension Right",
    img: "kneeEx R",
  },  {
    key: "KneeFlexionLeftScore",
    title: "Knee Flexion Left",
    img: "kneeEx L",
  },  {
    key: "KneeFlexionRightScore",
    title: "Knee Flexion Right",
    img: "kneeEx R",
  },  {
    key: "LeftKneePainScore",
    title: "Left Knee Pain",
    img: "PainScale",
  },  {
    key: "RightKneePainScore",
    title: "Right Knee Pain",
    img: "PainScale",
  },




  {
    key: "AbductionLeftScore",
    title: "Abduction - Left",
    img: "SAbduction L",
  },
  {
    key: "AbductionRightScore",
    title: "Abduction - Right",
    img: "SAbduction R",
  },  {
    key: "ExternalRotationLeftScore",
    title: "External Rotation - Left",
    img: "SExternalRotation L",
  },  {
    key: "ExternalRotationRightScore",
    title: "External Rotation - Right",
    img: "SExternalRotation R",
  },  {
    key: "FlexionLeftScore",
    title: "Flexion - Left",
    img: "SFlexion L",
  },  {
    key: "FlexionRightScore",
    title: "Flexion - Right",
    img: "SFlexion R",
  },  {
    key: "leftDisabilityScoreAverage",
    title: "Disability Scale - Left",
    img: "Disability",
  },  {
    key: "leftPainScoreAverage",
    title: "Pain Scale Left",
    img: "PainScale",
  },  {
    key: "rightDisabilityScoreAverage",
    title: "Disability Scale - Right",
    img: "Disability",
  },  {
    key: "rightPainScoreAverage",
    title: "Pain Scale Right",
    img: "PainScale",
  },

  {
    key: "HipAbductionLeftScore",
    title: "Hip Abduction Left",
    img: "Abduction L",
  },  {
    key: "HipAbductionRightScore",
    title: "Hip Abduction Right",
    img: "Abduction R",
  },  {
    key: "HipExtensionLeftScore",
    title: "Hip Extension Left",
    img: "Extension L",
  },  {
    key: "HipExtensionRightScore",
    title: "Hip Extension Right",
    img: "Extension R",
  },  {
    key: "HipFlexionLeftScore",
    title: "Hip Flexion Left",
    img: "Flexion L",
  },  {
    key: "HipFlexionRightScore",
    title: "Hip Flexion Right",
    img: "Flexion R",
  },  {
    key: "HipPainScore",
    title: "Hip Pain",
    img: "PainScale",
  },  {
    key: "KneeExtensionLeftScore",
    title: "Knee Extension Left",
    img: "kneeEx L",
  },  {
    key: "KneeExtensionRightScore",
    title: "Knee Extension Right",
    img: "kneeEx R",
  },  {
    key: "KneeFlexionLeftScore",
    title: "Knee Flexion Left",
    img: "kneeEx L",
  },  {
    key: "KneeFlexionRightScore",
    title: "Knee Flexion Right",
    img: "kneeEx R",
  },  {
    key: "LeftKneePainScore",
    title: "Left Knee Pain",
    img: "PainScale",
  },  {
    key: "RightKneePainScore",
    title: "Right Knee Pain",
    img: "PainScale",
  },
];
