// SplashScreen.js
import React, { useEffect, useState } from 'react';
import NoInternetPopup from './NoInternetPopup'; // Import the NoInternetPopup

const SplashScreen = ({ onSplashComplete }) => {
  // State to control whether to show the splash screen and the NoInternetPopup
  const [showSplash, setShowSplash] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Function to handle online status changes
  const handleOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    // Add event listeners to handle online/offline status changes
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    // Simulate a delay of 2 seconds (2000 milliseconds)
    const timer = setTimeout(() => {
      setShowSplash(false);
      onSplashComplete(); // Invoke the callback when the splash screen is complete
    }, 1000);

    // Clean up the timer and event listeners when the component unmounts
    return () => {
      clearTimeout(timer);
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, [onSplashComplete]);

  return (
    <div>
      {/* Display the SplashScreen */}
      {showSplash && (
        <div className="loading-container">
          <div className="loader"></div>
        </div>
      )}

      {/* Display the NoInternetPopup when there's no internet */}
      {!isOnline && <NoInternetPopup />}
    </div>
  );
};

export default SplashScreen;
