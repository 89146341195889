import React, { useState, useEffect, Suspense } from 'react';
import { useUserAuth } from "../context/UserAuthContext";
import left from '../images/Left.png';
import { useParams, useNavigate, Link } from 'react-router-dom';
import myplanpic from '../images/myplanpic.png';
import Skeleton from 'react-loading-skeleton';
import download from '../images/Downloading Updates.png'

// Lazy load images
const LazyImage = React.lazy(() => import('./components/LazyImage'));

const MyPrograms = () => {
  const { getAllPrograms, getProgramCodes } = useUserAuth();
  const [programs, setPrograms] = useState([]);
  const [programsCodes, setProgramsCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const navigate = useNavigate();
  const handleImageClick = () => {
    navigate('/profile');
  };

  const DownloadProgramCodes = async() =>{
    console.log("this is program codes ", programsCodes);
    try {
      setDownloadLoading(true);
      // Call the API to generate the PDF
      const response = await fetch('https://xddz4d1tr1.execute-api.ap-south-1.amazonaws.com/dev/programCodesPDF', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(programsCodes)
      });
  
      // Parse the response JSON
      const data = await response.json();
      console.log("This is data ",data);
      const pdfUrl = data.body.url;
    
      window.open(pdfUrl);
      setDownloadLoading(false);
    } catch (error) {
      console.error('Error generating PDF:', error);
      setDownloadLoading(false);
    }
  }

  useEffect(() => {
    const fetchPrograms = async () => {
      const storedPrograms = JSON.parse(localStorage.getItem('Program'));

      if (storedPrograms && storedPrograms.length > 0) {
        setPrograms(storedPrograms);
        console.log('Using programs data from localStorage.');
      }
      console.log('Fetching programs data...');
      const programsData = await getAllPrograms();
      localStorage.setItem('Program', JSON.stringify(programsData)); 
      setPrograms(programsData);
      setLoading(false);
      const data = JSON.parse(localStorage.getItem('codes'));
      console.log("data",data)
      setProgramsCodes(data);
      const codes = await getProgramCodes();
      if(codes.length>0){
        setProgramsCodes(codes);
      }
      
    };

    fetchPrograms();
  }, []);

  // Function to find the corresponding program code
  const findProgramCode = (programId) => {
    const programCode = programsCodes.find(code => code.id === programId);
    return programCode ? programCode.code : 'NA'; // Default to 'N/A' if no code is found
  };

  return (
    <div className='profile-page' style={{marginTop:"40px"}}>
      <div style={{ marginLeft: "5%", display:"flex", justifyContent:"space-between", marginRight:"5%" }}>
        <div>
          <img
            className="AP-back-image"
            src={left}
            alt="back"
            onClick={handleImageClick}
          />
          &nbsp; &nbsp;
          <span className="calender-text" style={{fontSize:"24px", fontWeight:"700"}}>My Programs</span>
        </div>
        <img
          className="AP-back-image"
          src={download}
          alt="back"
          onClick={DownloadProgramCodes}
        />
      </div>
      <div className='myplan-banner' style={{marginBottom:"3%"}}>
        {loading ? (
          <div style={{width:"90%"}}>
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"
              className='home-loader'
              height={70}
            />
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"
              className='home-loader'
              height={70}
            />
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"
              className='home-loader'
              height={70}
            />
          </div>
          
        ) : (
          <div className='program-list'>
            {programs.map((program, index) => {
              const exerciseName = program._document.data.value.mapValue.fields.Program_name.stringValue.toLowerCase();
              const TemplateID = program._document.data.value.mapValue.fields.Template_id.stringValue.toLowerCase();

              const getRandomThumbnail = (joint) => {
                const cycleIndex = index % 3 + 1;
                return `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioExcercisePlanImages%2F${joint}Thumbnail${cycleIndex}.png?alt=media&token=c94ba79a-26ce-4efb-9f4f-70865845ef68`;
              };

              const jointImages = {
                knee: getRandomThumbnail('Knee'),
                hip: getRandomThumbnail('Hip'),
                shoulder: getRandomThumbnail('Shoulder'),
                neck: getRandomThumbnail('Neck'),
                back: getRandomThumbnail('Back'),
                ankle: getRandomThumbnail('Ankle'),
              };

              let imgSrc = Object.keys(jointImages).find(joint => exerciseName.includes(joint)) || myplanpic;
              imgSrc = Object.keys(jointImages).find(joint => TemplateID.includes(joint)) || myplanpic;
              
              if (imgSrc !== myplanpic) {
                imgSrc = jointImages[imgSrc];
              }

              // Fetch the program ID and match it with the program code
              const programId = program._document.key.path.segments[6];
              const programCode = findProgramCode(programId);

              return (
                <div className='program-item' key={index}>
                  <Link to={`/plan/${programId}/${program._document.data.value.mapValue.fields.Program_name.stringValue}/${programCode}`} style={{ textDecoration: 'none' }}>
                    <div className='myplan-body'>
                      <Suspense fallback={<Skeleton height={150} width={150} />}>
                        <LazyImage
                          className='myplan-image'
                          src={imgSrc}
                          alt='My Plan'
                        />
                      </Suspense>
                      <div className='myplan-text2new' style={{ textDecoration: 'none', fontSize:"18px" }}>
                        {program._document.data.value.mapValue.fields.Program_name.stringValue}
                      </div>
                      <div style={{ justifyContent: "right", paddingRight: "10px" }}>
                        {programCode !== 'NA' && (
                          <div style={{ color: "black", fontSize: "20px", textDecoration: "none", fontWeight: "800" }}>
                            {programCode}
                          </div>
                        )}
                        <div className='myplan-text3' style={{ width: "50px" }}> 
                          View Plan 
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
        <br />
        <Link type="submit" className='AP-button2' to='/joint'  > 
          <div className='inner-AP-button'>
            <div className='AP-text'>CREATE</div>
          </div>
        </Link>
      </div>
      {downloadLoading && (
        <div className="spinner-container">
          <div className="spinner-grow" role="status">
          </div>
        </div>
      )}
    </div>
  );
}

export default MyPrograms;
