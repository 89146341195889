import React from 'react';

const WhatsAppButton = () => {
  const handleClick = () => {
    const phoneNumber = '917709937940'; // Replace with the actual phone number
    const message = encodeURIComponent('I need help with the wizphys.io app');
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="whatsapp-button" onClick={handleClick}>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </div>
  );
};

export default WhatsAppButton;
