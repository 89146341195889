import React, { useState, useEffect } from 'react'
import left from '../images/Left.png'
import { useParams, useNavigate, Link } from 'react-router-dom';
import planpic from '../images/myplanpic.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useUserAuth } from "../context/UserAuthContext";
import { AddPatientSuccessPopUp } from './components/AddPatientSuccessPopUp';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { analytics } from "../firebase/index";
import { logEvent } from "firebase/analytics";
import NoInternetPopup from './components/NoInternetPopup';
import ok from '../images/Blue OK.png';
import ReactCountryFlag from 'react-country-flag';
import Dropdown from 'react-bootstrap/Dropdown'; // Import Dropdown
import countries from '../utils/countries'


export const AddAIProgram = () => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [reason, setReason] = useState('');
  const [color, setColor] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSide, setSelectedSide] = useState('');
  const [programs, setPrograms] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [Templateid, setTemplateid] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State to control the visibility of the success modal
  const [isLoading, setIsLoading] = useState(false);
  const [PatientData, setPatientData] = useState({});
  const [FBTemplateid, setFBTemplateid] = useState('');
  const [initialPatientData, setInitialPatientData] = useState({});
  const [changeValue, setChangeValue] = useState(0);
  const [allDetailsFilled, setAllDetailsFilled] = useState(false);
  const [customFlag, setCustomFlag] = useState(false);
  const [countryName, setCountryName] = useState('India'); // Default country code
  

  const navigate = useNavigate();
  const { patientid } = useParams();
  // console.log("patient ID ",patientid);

  const { ID, getAllPrograms, addPatientData, getPhysioPatients,fetchPhysioData } = useUserAuth();


  // fetching plans data (programs)
  useEffect(() => {
    const fetchPrograms = async () => {
      console.log('Fetching programs data...');
      const programsData = await getAllPrograms();
      setPrograms(programsData);
    };

    fetchPrograms();
  }, []);

  // fetching Patient profile from Physio collection  data (programs)
  useEffect(() => {
    const fetchUserData = async () => {
      const data = await getPhysioPatients();
      const filteredData = data.filter(item => item.Patient_idFP === patientid);
      setInitialPatientData(filteredData[0]);
      setChangeValue(Math.random());
    
  };
  console.log("fetching patient data",initialPatientData);

    fetchUserData();
  }, [patientid]);

  useEffect(() => {
    console.log("coming in this ",initialPatientData)
    if (initialPatientData && Object.keys(initialPatientData).length > 0) {
      setName(initialPatientData.PatientNameFP || '');
      setPhoneNumber(initialPatientData.PatientMobileFP || '');
    }
    console.log("name ",name);
    console.log("number ",phoneNumber);
  }, [changeValue]);

  useEffect(() => {
    if (name && phoneNumber && reason && color && selectedSide && selectedPlan) {
      setAllDetailsFilled(true);
      console.log("all detials are filled ");
      // All details are filled
    }else{
      console.log("all detials are not filled ")
    }
  }, [name,phoneNumber,reason,color,selectedSide,selectedPlan]);



  
  const boxColors = ['Red', 'Green', 'Yellow', 'Blue'];
  const boxColors1 = ['#ff4242', '#006d2e', '#e1d144', '#05328d'];



  const handleBoxClick = (selectedColor) => {   //color selection handled 
    setColor(selectedColor);
    setSelectedColor(selectedColor);
  };

  const handleOptionClick = (option) => {      // Side selection handled 
    setSelectedSide(option);
  };

  const handlePlanClick = async(programName, programDetials) => {   // Selected Program for the user 
    console.log("this is program detials user clicked ", programName,programDetials);
    if(programDetials._key.path.segments[5] === "CustomPrograms"){
      setCustomFlag(true);
    }else{
      setCustomFlag(false);
    }

    setSelectedPlan(programName.Program_name.stringValue);
    setFBTemplateid(programName.Template_id.stringValue);
    const userData = await fetchPhysioData();
    console.log("this is user data ",userData.PhysioID+(parseInt(userData.AssignedProgramCount)+1));
    console.log("selected program :",programName.Program_name.stringValue);
    setTemplateid(userData.PhysioID+(parseInt(userData.AssignedProgramCount)+1));
    console.log("template id :",FBTemplateid);
  };

  const handleCountryCodeChange = (name) => {
    // console.log("country selected ",name, countries.find(country => country.code === countryName)?.code)
    setCountryName(name);
  };



  const handleImageClick = () => {
    navigate('/home');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("checkpoint ")
    // console.log("encrytpted number",EncryptData11(phoneNumber))


    
    const patientData = {
      PatientNameFP: name,
      PatientMobileFP: phoneNumber,
      PatientTreatmentFP: reason,
      PatientColorFP: color,
      PatientSideFP: selectedSide,
      PatientAssignedProgramNameFP: selectedPlan,
      Template_id: Templateid,
      FBTemplateid: FBTemplateid,
      Patient_idFP: patientid,
      customFlag: customFlag,
      CountryName: countryName,
      // Other patient data...
    };
    logEvent(analytics, 'py_add_AI_program', {
      Date: new Date().toLocaleString(),
      py_id: ID,
      patientID:  patientid,
      patientData: JSON.stringify(patientData),
      log: "Add AI Program button Clicked on Add AI Program Page"
    });
    
    setPatientData(patientData);
    console.log("patient Data ",patientData);
    try {
      // Call the function to add encrypted patient data
      console.log("Data Adding ...")
      setIsLoading(true);
      await addPatientData(patientData, false);
      console.log(" data added successfully");
      setShowSuccessModal(true); 
      
    } catch (error) {
        console.log("Error adding encrypted patient data:", error);
        logEvent(analytics, 'py_add_AI_program_error', {
          Date: new Date().toLocaleString(),
          py_id: ID,
          patientID:  patientid,
          patientData: JSON.stringify(patientData),
          error: error,
        });
        // Handle the error, e.g., show an error message to the user
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='add-patient-page' style={{marginTop:"1%"}}>
      <br />
      <div className=''>
            <img 
                className='AP-back-image'
                src={left}
                alt="back"
                onClick={handleImageClick}
            />
            &nbsp; &nbsp;
            <span className='add-patient-text'>Add an AI program</span>
        </div>
        <br />
        <form onSubmit={handleSubmit}>
          <div className='APpage-text'>
            Name
          </div>
          <input
            className='APpage-input'
            type="text"
            id="name"
            value={name}
            // onChange={(e) => setName(e.target.value)}
            readOnly
            // required 
          />
          <div className='APpage-text'>
            <br />
            Phone Number
          </div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <Dropdown onSelect={handleCountryCodeChange} style={{ marginRight:'10px',fontWeight: '650', fontSize: '120%', borderColor:'#bed0fb'}}>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <ReactCountryFlag countryCode={countries.find(country => country.name === countryName)?.countryCode} svg style={{ marginLeft: '10px' }} /> {/* Displaying the ReactCountryFlag component */}
                &nbsp; <span>{countries.find(country => country.name === countryName)?.code}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-animated">
                {countries.map((country, index) => (
                  <>
                    <Dropdown.Item key={country.name} eventKey={country.name}>
                      <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div style={{marginRight:"10px"}}>
                          <ReactCountryFlag countryCode={country.countryCode} svg/> {country.name} 
                        </div> 
                        <div>{country.code}</div>
                      </div>
                    </Dropdown.Item>
                    {index !== countries.length - 1 && <hr style={{ margin: '0 8px', border: 'none', borderBottom: '2px solid #ccc' }} />}
                  </>
                  
                ))}
              </Dropdown.Menu>
            </Dropdown>
          <input
            style={{
              backgroundColor: '#ffffff',
              border: '1px solid',
              borderColor: '#bed0fb',
              borderRadius: '10px',
              height: '38px',
              width: '80%',
              padding: '3%',
              marginRight:'10%'
            }}
            type="tel"
            maxLength="10"
            id="phoneNumber"
            value={phoneNumber}
            // onChange={handlePhoneNumberChange}
            readOnly
            // required
          />
          </div>

          <div className='APpage-text'>
            <br />
            Reason
          </div>
          <input
            className='APpage-input'
            type="text"
            id="phoneNumber"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            // readOnly
            required
          />
        <br />

        <div className='APpage-text'>
            <br />
            Threadband
        </div>

        <div>
          <div style={{ display: 'flex', justifyContent: 'left' }}  className='threadband-outerblock'>
            {boxColors1.map((boxColor, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: boxColor,
                  border: selectedColor === boxColors[index] ? '2px solid #0054ff' : 'none',
                  boxShadow: selectedColor === boxColors[index] ? '0 0 10px #0054ff' : 'none',
                }}
                className='threadband-div'
                onClick={() => handleBoxClick(boxColors[index])}
              /> 
            ))}
          </div>
        </div>

        <div className='APpage-text'>
            <br />
            Side
        </div>

        <div>
          <div style={{ display: 'flex', justifyContent: 'left' }} className='text-block-container'>
            <div
              
              className={`text-block ${selectedSide === 'Left' ? 'selectedbox' : ''} sideblock`}
              onClick={() => handleOptionClick('Left')}
            >
              Left
            </div>
            <div
              className={`text-block ${selectedSide === 'Right' ? 'selectedbox' : ''} sideblock`}
              onClick={() => handleOptionClick('Right')}
            >
              Right
            </div>
            <div
              className={`text-block ${selectedSide === 'Both' ? 'selectedbox' : ''} sideblock`}
              onClick={() => handleOptionClick('Both')}
            >
              Both
            </div>
          </div>
        </div>

        <div className='APpage-text'>
          <br />
          Module
        </div>
        
        {programs.length > 0 ? (
          programs.map((program, index) => {
            const exerciseName = program._document.data.value.mapValue.fields.Program_name.stringValue.toLowerCase();
            const TemplateID = program._document.data.value.mapValue.fields.Template_id.stringValue.toLowerCase();

            // Mapping of joint names to their corresponding image URLs
            const getRandomThumbnail = (joint) => {
              const cycleIndex = index % 3 + 1;
              return `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioExcercisePlanImages%2F${joint}Thumbnail${cycleIndex}.png?alt=media&token=c94ba79a-26ce-4efb-9f4f-70865845ef68`;
            };
            
            const jointImages = {
              knee: getRandomThumbnail('Knee'),
              hip: getRandomThumbnail('Hip'),
              shoulder: getRandomThumbnail('Shoulder'),
              neck: getRandomThumbnail('Neck'),
              back: getRandomThumbnail('Back'),
              ankle: getRandomThumbnail('Ankle'),
            };

            let matchingJoint = Object.keys(jointImages).find(joint => exerciseName.includes(joint));
            matchingJoint = Object.keys(jointImages).find(joint => TemplateID.includes(joint));

            // Set the imgSrc to the corresponding image URL or to the default plan image
            const imgSrc = matchingJoint ? jointImages[matchingJoint] : planpic;




            return (
              <div
                key={program._document.key.path.segments[6]}
              >
                <div
                  className={`myplan-body1 ${selectedPlan === program._document.data.value.mapValue.fields.Program_name.stringValue ? 'selected' : 'randomclass'}`}
                  onClick={() => handlePlanClick(program._document.data.value.mapValue.fields, program)}
                >
                  <img
                    className='myplan-image1'
                    src={imgSrc} // Use the dynamically determined imgSrc
                    alt='My Plan'
                  />

                  <div className='myplan-text21'>{program._document.data.value.mapValue.fields.Program_name.stringValue}</div>
                  {selectedPlan === program._document.data.value.mapValue.fields.Program_name.stringValue ? (
                      <img 
                        src={ok} style={{
                          height: "24px",
                          marginTop: "20px",
                          marginRight: "5px",
                        }}
                      />
                    ) : (
                      <div style={{ width: "24px" }}></div>
                    )}
                </div>
                <br />
              </div>
            );
          })
        ) : (
          <div className='skeleton'>
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"
              className='home-loader'
              height={70}
            /> <br />
            
          </div>
        )}
                 
          <br /> 
          <br />
          <br />
        <button type="submit" className='AP-button2' onSubmit={handleSubmit} disabled={!allDetailsFilled}> 
          <div className='inner-AP-button'>
            <div className='AP-text'>ADD PATIENT</div>
          </div>
        </button>
        </form>

        {isLoading && (
          <div className="spinner-container">
            <div className="spinner-grow" role="status">
            </div>
          </div>
        )}

        <NoInternetPopup/>
        <AddPatientSuccessPopUp showSuccessModal={showSuccessModal} setShowSuccessModal={setShowSuccessModal} patientData={PatientData}/>
    </div>
  )
}
