import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom';
import left from '../images/Left.png'
import { Footer } from './components/Footer';
import { useUserAuth } from "../context/UserAuthContext";
import Skeleton from 'react-loading-skeleton'
import { analytics } from "../firebase/index";
import { logEvent } from "firebase/analytics";

export const ProgramRenewalPage = () => {
  const { ID, programRenewalData, ProgramRenewal } = useUserAuth();
  const navigate = useNavigate();
  const [result, setResult] = useState([]);
  const [result1, setResult1] = useState([1,2]);
  const [isLoading, setIsLoading] = useState(false);
  const [reLoad, setReLoad] = useState(0);
  const [dummyState, setDummyState] = useState(false); 

  
  
  useEffect(() => {
    // Scroll to the top of the page when it loads
    window.scrollTo(0, 0);
    
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  useEffect(() => {
    const fetchData = async () => {
      setResult(JSON.parse(localStorage.getItem('ProgramRenewalData')));
      console.log("this in function ")
      const output = await programRenewalData();
      console.log("output is component ",output);
      setResult(output);
    };
  
    fetchData();
  }, [reLoad]);


  const handleImageClick = () => {
    navigate('/home');
  };

  const handleRenewal = async(item) => {
    setIsLoading(true);
    
    console.log("item printing ",item.patientId);
    await ProgramRenewal(item.patientId, item.programID);
    console.log('Before filtering:', result);

    setResult((prevResult) => prevResult.filter((dataItem) => dataItem.patientId !== item.patientId));
    localStorage.setItem('ProgramRenewalData',JSON.stringify(result));
    navigate('/home');
    console.log('After filtering:1', result);
    // setReLoad(Math.random());
    // setResult([]);
    logEvent(analytics, 'py_Renewal_to_Patient', {
      Date: new Date().toLocaleString(),
      ID,
      "patientid": item.patientId,
      "py_log" : "Physio giving Renewal to Patient for AI program"
    }); 
  }
  

  useEffect(() => {
    console.log('After filtering:3', result);
    
    
  }, [result1]);

  

  return (
    <div>
      
      <div style={{marginTop:"8%", marginLeft:"5%"}}>
        <img 
            className='AP-back-image'
            src={left}
            alt="back"
            onClick={handleImageClick}
        />
        &nbsp; &nbsp;
        <span className='calender-text'>Program Renewal</span>
      </div>

      {isLoading && (
        <div className="spinner-container">
          <div className="spinner-grow" role="status">
          </div>
        </div>
      )}

      {result.length === 0 ? (
        <div className='skeleton ' style={{paddingLeft:"5%"}}>
          <br />
          <Skeleton
            sx={{ bgcolor: 'grey.900' }}
            variant="rectangular"
            className='home-loader'
            height={100}
          /> <br />
          <br />
          <br />
        </div>
      ) : (
        result.map((item, index) => (
          <div className='PR-block' key={index}> 
            <div className='PR-name-time-block'>
              <div className='PR-name'>
                {item.patientName}
              </div>
              <div className='PR-time'>
                Ends on : {item.enddate ? item.enddate.substring(0, 10) : ''}
              </div>
            </div>
            <div className='PR-program'>
              {item.program}
            </div>
            <div className='PR-Side-band'>
              Side : <span style={{color:"#2d2f35"}}>{item.side}</span>
            </div>
            <div className='PR-Side-band'>
              Theraband : <span style={{color:`${item.color}`}}>{item.color}<br /></span>
            </div>
            
            <div className='PR-button' onClick={() => handleRenewal(item)}>
              <div className='PR-button-inner'>
                RENEW
              </div>
            </div>
          </div>
        ))
      )}
    <br />
    <br />
    <br />
    <Footer/>
    </div>
  )
}
