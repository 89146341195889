import React, { useState, useEffect } from 'react';
import { useUserAuth } from "../../context/UserAuthContext";

export const Excercise = ({ patientId, programId, duration, programdata }) => {
  const daysOfMonth = Array.from({ length: duration }, (_, index) => index + 1); // Create an array of days
  const [exerciseData, setExerciseData] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null); // State to store the selected day
  const [exerciseList, setExerciseList] = useState(null); // State to store the selected day
  const { getExerciseData, getExcerciseList } = useUserAuth(); // Import the function to fetch exercise data from your context

  // Use an object to store the images for each error
  const [errorImages, setErrorImages] = useState({});

  useEffect(() =>{
    const fetchData = async() => {
      try{
        const data = await getExcerciseList(patientId,programId);
        console.log("this is excercise data list",data);
        setExerciseList(data);
      }catch(e){
        console.log("ERROR fetching excercise details");
      }
    } 
    fetchData();
  },[patientId,programId])

  const handleDayClick = async (day) => {
    setSelectedDay(day);

    try {
      const data = await getExerciseData(patientId, programId, day);
      if (data !== null) {
        setExerciseData(data);
      }
    } catch (error) {
      console.error("Error fetching exercise data:", error);
    }
  };

  useEffect(() => {
    if (selectedDay !== null) {
      console.log("You clicked day no:", selectedDay);
    }
  }, [selectedDay]);

  // Use this useEffect to log the updated exerciseData state
  useEffect(() => {
    // console.log("exerciseData:", exerciseData);
  }, [exerciseData]);

  const validImageNames = [
"Bracing With Shoulder Extension_BackBendError",
  "Bracing With Shoulder Extension_Normal",
  "Bracing_NeckTiltError",
  "Bracing_NeckTiltError_Normal",
  "Bracing_Normal",
  "Bracing_ShruggingError",
  "Bracing_ShruggingError_Normal",
  "HipAbduction_HipBend",
  "HipAbduction_KneeBend",
  "HipAbduction_Normal",
  "HipExtension_HipBend",
  "HipExtension_KneeBend",
  "HipExtension_Normal",
  "HipFlexion_HipBend",
  "HipFlexion_KneeBend",
  "HipFlexion_Normal",
  "Infra Spinatus_ElbowBend",
  "Infra Spinatus_Normal",
  "Infra Spinatus_TorsoTilt",
  "Infra Spinatus_WristDown",
  "Infra Spinatus_WristUp",
  "Low Row_BackBend",
  "Low Row_Normal",
  "Low Row_WristDown",
  "Low Row_WristUp",
  "Rhomboids_ElbowBend",
  "Rhomboids_Normal",
  "Rhomboids_WristDown",
  "Rhomboids_WristUp",
  "Serratus Anterior_Normal",
  "Serratus Anterior_WristDown",
  "Serratus Anterior_WristUp",
  "Subscap IR with Band_Normal",
  "Supra Spinatus_ElbowBend",
  "Supra Spinatus_HandFront",
  "Supra Spinatus_HandSide",
  "Supra Spinatus_Normal",
  "Supra Spinatus_TorsoTilt",
  "WallPush_BackBend",
  "WallPush_Normal",
  "WallPush_WristDown",
  "WallPush_WristUp"
  ];
  
  
  const getImageUrl = (imageName) => {
    console.log("this error name ",imageName);
    if (validImageNames.includes(imageName)) {
      return `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioExcersiceErrors%2F${imageName}.png?alt=media&token=e4375adb-35d2-40a9-afc9-50577e0e5967`;  
    } else {
      return null;
    }
  };

  const importImages = async (badImageSrc, goodImageSrc, exerciseId, errorIndex) => {
    let badImageUrl = '';
    let goodImageUrl = '';

    if (badImageSrc && goodImageSrc) {
      badImageUrl = getImageUrl(badImageSrc);
      goodImageUrl = getImageUrl(goodImageSrc);
    }

    // Create a new object by spreading the current errorImages and updating the specific error's images
    setErrorImages(prevImages => ({
      ...prevImages,
      [`${exerciseId}-${errorIndex}`]: {
        badImage: badImageUrl,
        goodImage: goodImageUrl,
      },
    }));
    console.log("Error images:", errorImages);
  };

  useEffect(() => {
    // Call importImages when exerciseData or some relevant data changes
    console.log("ExcersieData", exerciseData);
    if (exerciseData != null && exerciseData.length > 0) {
      exerciseData.forEach((exercise, exerciseIndex) => {
        exercise.ErrorCounts.forEach((error, errorIndex) => {
          importImages(error.badimage, error.goodimage, exerciseIndex, errorIndex);
        });
      });
    }
  }, [exerciseData]);

  const calculateScore = (excerciseName, exerciseData) => {
    const name = exerciseList.name;
    let index = 0;
    for(var i=0;i<name.length;i++){
      if(name[i]===excerciseName){
        index=i;
        break;
      }
    }
    console.log("the value of i",index);
    const weightage = exerciseList.weightage[index];
    const repCount = exerciseList.reps[index];
    console.log("the value of reps and weigtag",weightage,repCount);
    const score = (exerciseData.ExerciseScore / (repCount * weightage)) * 100;
    return Math.min(score, 100).toFixed(2).replace(/\.00$/, '');
  };

  const getRepsCount = (excerciseName) => {
    const name = exerciseList.name;
    let index = 0;
    for(var i=0;i<name.length;i++){
      if(name[i]===excerciseName){
        index=i;
        break;
      }
    }
    console.log("the value of reps ",exerciseList.reps[index]);

    return exerciseList.reps[index];
  };

  return (
    <div>
      <div className="excersise-container">
        <div className="scrollable-div">
          {daysOfMonth.map((day) => (
            <div
              key={day}
              className={`excercise-day ${selectedDay === day ? 'excercise-day-selected' : ''}`}
              onClick={() => handleDayClick(day)}
            >
              {day}
            </div>
          ))}
        </div>
      </div>

      {/* Display exercise data based on selected day */}
      {exerciseData != null && exerciseData.length === 0 ? (
        <div className='exercise-data-error-block'>
          No Data Available
        </div>
      ) : (
        exerciseData.map((exercise, exerciseIndex) => (
          <div className="exercise-data" key={exerciseIndex}>
            <div className='exercise-data-header'>
              <div className='exercise-data-heading'>
                {exercise.ExerciseName}
              </div>
              <div className='exercise-data-heading2'>
                <div>
                  Reps - {exercise.RepCount}/{getRepsCount(exercise.ExerciseName)}
                </div>
                <div>
                  Score -  {calculateScore(exercise.ExerciseName, exercise)} %
                </div>
                <div>
                  Errors - {exercise.ErrorCounts.reduce((total, error) => total + error.count, 0)}
                </div>
              </div>
            </div>

            {exercise.ErrorCounts.some(error => error.count > 0) && (
              <div className='excercise-body'>
                {exercise.ErrorCounts.map((error, errorIndex) => {
                  // Get the images for this error from the errorImages object
                  const errorImagesKey = `${exerciseIndex}-${errorIndex}`;
                  const errorImagesData = errorImages[errorImagesKey] || {};
                  const { badImage, goodImage } = errorImagesData;

                  // Render the block only if both badImage and goodImage are not empty
                  if (badImage && goodImage) {
                    return (
                      <div key={errorIndex} className='excercise-body-inner'>
                        <div className='excercise-body-inner-good-text'>
                          <img src={badImage} style={{ width: "92px", height: "129px", borderRadius: "10px" }} alt="" />
                          <div><br /> {error.badtext}</div>
                          <div style={{ color: "#ff4242" }}>{`- ${error.count} times`}</div>
                        </div>
                        <div className='excercise-body-inner-bad-text'>
                          <img src={goodImage} style={{ width: "92px", height: "129px", borderRadius: "10px" }} alt="" />
                          <div><br /> {error.goodtext}</div>
                        </div>
                      </div>
                    );
                  } else {
                    // If either badImage or goodImage is missing, return null
                    return null;
                  }
                })}
                <br />
              </div>
            )}

          </div>
        ))
      )}
    </div>
  );
}
