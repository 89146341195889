import React,{useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Home from '../../images/Home.png';
import Calendar from '../../images/Calendar.png';
import Profile from '../../images/Profile.png';
import HomeActive from '../../images/Home Active.png';
import CalendarActive from '../../images/Calender Active.png';
import ProfileActive from '../../images/Profile Active.png';
import { logEvent } from "firebase/analytics"; // Import Firebase Analytics logEvent
import { useUserAuth } from "../../context/UserAuthContext";
import { analytics } from "../../firebase/index";
import NoInternetPopup from './NoInternetPopup';
import { Modal, Button } from 'react-bootstrap'; 


const UpdateModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} centered
      style={{
        padding:"10%",
      }}
    >
      <div style={{
        padding:"5%",
        fontFamily:"Rajdhani",

      }}>
        <Modal.Title style={{ fontSize: '120%', fontWeight:"600" }}>Update App</Modal.Title>
        <p style={{ fontSize: '100%', fontWeight: "500" }}>
          An update is available. Please update the app to the latest version.
        </p>
        <Button
          variant="primary"
          onClick={onHide}
          style={{
            backgroundColor: '#264507',
            borderColor: '#264507',
            borderRadius: '10px',
            fontSize: '110%',
            display:"flex",
            justifyContent:"center"
          }}
          href="https://play.google.com/store/apps/details?id=com.WizphysAi.newPhysioApp"
        >
          Update Now
        </Button>
      </div>
        
      
    </Modal>
  );
};

export const Footer = () => {
  const { ID, fetchPhysioData, showDocCalender, setShowDocCalender, docsCalenderBool, setDocsCalenderBool, consultation, setConsultation, fetchCurrentVersion, addingPhysioAppVersion } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentversion, setCurrentVersion] = useState(null);


  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const Appversion = "1.2.2";
  const isHomeSelected = location.pathname === '/home';
  const isCalendarSelected = location.pathname === '/calendar';
  const isDocCalendarSelected = location.pathname === '/doccalendar';
  const isProfileSelected = location.pathname === '/profile';

  const logFooterItemClick = (item) => {
    // Log the event when a footer item is clicked
    logEvent(analytics, 'py_footer_item_click', {
      ID,
      item: item,
      "py_log":"footer item clicked",
    });
  };

  useEffect(() =>{
    const fetchData = async () => {
      const userData = await fetchPhysioData();
      if (userData) {
        console.log("Pranav Checking value :",userData.DocCalenderVerified )
        setShowDocCalender(userData.DocCalenderVerified);
        setDocsCalenderBool(userData.DocCalenderVerified);
        console.log("INside footerrrrrrrrrrrrrrrrr",userData.ConsultationVerification,userData.B2BConsultationVerification,userData.ConsultationVerification || userData.B2BConsultationVerification)
        if(userData.ConsultationVerification || userData.B2BConsultationVerification){
          setConsultation(true);
        }else{
          setConsultation(false);
        }
      }
      console.log("hey this is userData ",consultation);
    };

    fetchData();
  })

  useEffect(() => {
    const fetchData = async () => {
      const userData = await fetchCurrentVersion();
      const currentversion = userData.Android;
      setCurrentVersion(currentversion);
      await addingPhysioAppVersion(Appversion);
      console.log("this is the value of current version",currentversion);
      if (compareVersions(currentversion, Appversion) === 1  && location.pathname === '/home') {
        setShowUpdateModal(true);
      }
    };

    fetchData();
  }, []);

  const compareVersions = (version1, version2) => {
    const v1 = version1.split('.').map(Number);
    const v2 = version2.split('.').map(Number);

    for (let i = 0; i < v1.length; i++) {
      if (v1[i] > v2[i]) return 1;
      if (v1[i] < v2[i]) return -1;
    }

    return 0; // Versions are equal
  };


  return (
    <div className='footer'>
      
      <NoInternetPopup />
      <div
        onClick={() => {
          navigate('/home');
          logFooterItemClick('Home'); // Log the event for Home item
        }}
        className={isHomeSelected ? 'selected' : ''}
      >
        <div className='footer-item'>
          <img
            className={`footer-image`}
            src={isHomeSelected ? HomeActive : Home}
            alt='Home'
            style={{ marginRight: "12px" }}
          />
        </div>
        <div className={`footer-text ${isHomeSelected ? 'active' : ''}`}>Home</div>
      </div>

      {consultation &&(
        <div
        onClick={() => {
          navigate('/calendar');
          logFooterItemClick('Calendar'); // Log the event for Calendar item
        }}
        className={isCalendarSelected ? 'selected' : ''}
      >
        <div className='footer-item'>
          <img
            className={`footer-image`}
            src={isCalendarSelected ? CalendarActive : Calendar}
            alt='Calendar'
            style={{ marginRight: "12px" }}
          />
        </div>
        <div className={`footer-text ${isCalendarSelected ? 'active' : ''}`}>Calendar</div>
      </div>
        
      )}

      { showDocCalender && (
        <div
          onClick={() => {
            navigate('/doccalendar');
            logFooterItemClick('Calendar'); // Log the event for Calendar item
          }}
          className={isDocCalendarSelected ? 'selected' : ''}
        >
          <div className='footer-item'>
            <img
              className={`footer-image`}
              src={isDocCalendarSelected ? CalendarActive : Calendar}
              alt='Calendar'
              style={{ marginLeft: "25%" }}
            />
          </div>
          <div className={`footer-text ${isDocCalendarSelected ? 'active' : ''}`}>Doc's Calendar</div>
        </div>
      )}
      
      
      <div
        onClick={() => {
          navigate('/profile');
          logFooterItemClick('Profile'); // Log the event for Profile item
        }}
        className={isProfileSelected ? 'selected' : ''}
      >
        <div className='footer-item'>
          <img
            className={`footer-image ${isProfileSelected ? 'active' : ''}`}
            src={isProfileSelected ? ProfileActive : Profile}
            alt='Profile'
            style={{ marginRight: "12px" }}
          />
        </div>
        <div className={`footer-text`}>Profile</div>
      </div>

      <UpdateModal show={showUpdateModal} onHide={handleUpdateModalClose} />
    </div>
  );
};
