import React, { useState } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 
import image1 from '../images/image1.png';
import image2 from '../images/image2.png';
import image3 from '../images/image3.png';
import left from '../images/Left.png';

const MobileGuide = () => {
  const [isLastSlide, setIsLastSlide] = useState(false);
  const navigate = useNavigate();

  const settings = {
    // dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => {
      if (index === carouselVideos.length - 1) {
        setIsLastSlide(true);
      } else {
        setIsLastSlide(false);
      }
    },
  };

  const carouselVideos = [
    { src: 'https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioUIContent%2FVideos%2FCreating%20a%20Custom%20Physio%20Module.mp4?alt=media&token=4354cf38-08ed-44bb-b076-0996d6160e80', text: 'Welcome to the app!' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioUIContent%2FVideos%2FWizphys.io%20-%20Appointment%20Booking%20System.mp4?alt=media&token=63421ad3-ed61-4601-bf87-e5027057f929', text: 'Discover new features!' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioUIContent%2FVideos%2FWizphys.io%20Doctors%20App%20Demo.mp4?alt=media&token=267565e6-b670-4044-ba32-ea34df9d22f2', text: 'Get started with your journey!' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioUIContent%2FVideos%2FWizphysio%20Registration%20process.mp4?alt=media&token=d4ffefa4-84f1-4544-a0bd-d015c4affcf9', text: 'Get started with your journey!' },
  ];

  const handleSkip = () => {
    // Go to the next slide
    sliderRef.current.slickNext();
  };

  const handleGetStarted = () => {
    navigate('/home');
  };

  let sliderRef = React.createRef();

  return (
    <div style={{paddingLeft:"5%", paddingTop:"8%", paddingRight:"5%", backgroundColor:"#EDE7F6", height:"100%"}}>
      <div style={{ marginLeft: "0%", display:"flex", justifyContent:"space-between", marginRight:"5%", paddingBottom:"20px" }}>
        <div>
          <img
            className="AP-back-image"
            src={left}
            alt="back"
          />
          &nbsp; &nbsp;
          <span className="calender-text" style={{fontSize:"24px", fontWeight:"700"}}>Getting Started</span>
        </div>
      </div>
      <div style={{overflow: 'hidden' }}>
        <button
          onClick={handleSkip}
          style={{
            display:"flex",
            justifyContent:"right",
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: '16px',
            color: '#000',
            cursor: 'pointer',
            fontFamily:"Rajdhani",
            fontWeight:"700",
            marginLeft:"90%",
            marginBottom:"30px"
          }}
        >
          Skip
        </button>
        
        {/* <Slider ref={sliderRef} {...settings}>
          {carouselImages.map((item, index) => (
            <div key={index} className='carosal-container'>
              <img src={item.src} className='carosal-img' alt={`guide-${index}`} />
              <div className='carosal-text'>{item.text}</div>
            </div>
          ))}
        </Slider> */}
        
        <Slider ref={sliderRef} {...settings} style={{ height: '100%' }}>
          {carouselVideos.map((item, index) => (
            <div key={index} className='carosal-container'>
              <div style={{display:"flex", justifyContent:"center"}}>
                <video 
                  src={item.src} 
                  className='carosal-video' 
                  autoPlay 
                  muted 
                  loop 
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                >
                  Your browser does not support the video tag.
                </video>
              </div>
              <h2 className='carosal-text'>{item.text}</h2>
            </div>
          ))}
        </Slider>


        <button
          onClick={handleGetStarted}
          className={`InputBtn ${isLastSlide ? 'enabled' : 'disabled'}`}
          disabled={!isLastSlide}
          style={{ width: "100%", backgroundColor: isLastSlide ? '#3974E5' : '#8E8A8A', marginTop:"50px" }}
        >
          GET STARTED
        </button>
      </div>
      <br />
    </div>
  );
};

export default MobileGuide;
