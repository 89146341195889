import React, { useState, useEffect, useRef } from 'react';
import left from '../images/Left.png';
import deleteimg from '../images/Delete.png';
import editimg from '../images/Edit.png';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useParams, useNavigate, Link } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import './style.css';
import { Footer } from './components/Footer';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { analytics } from "../firebase/index";
import cross from '../images/Cross.png';
import { logEvent } from "firebase/analytics";

import { useUserAuth } from "../context/UserAuthContext";

const localizer = momentLocalizer(moment);

export const Calender = () => {
  const { ID, user, Events, setEvent, loggedIn, AddNewSlot, fetchCompleteSlotData, deleteSlot } = useUserAuth();
  
  const [selectedDate, setSelectedDate] = useState(moment());
  const [events, setEvents] = useState([]); // Store the events here
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [dummyEvent, setDummyEvent] = useState(null);
  const [addDummyEvent, setAddDummyEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailModalOpen, setDetailIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false); // Track edit mode
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const [result, setResult] = useState();
  const [showOverlappingErrorModal, setShowOverlappingErrorModal] = useState(false);
  const [selectedBookedEvent, setSelectedBookedEvent] = useState(null); // Track the selected "booked-event" event
  const [isInitialRender,setIsInitialRender ] = useState(true);
  const [Adding, setAdding] = useState();
  const [updating, setUpdating] = useState();
  const calendarRef = useRef(null);


  const bookedColors = ['#FADB8C', '#FADB8C', '#FADB8C', '#FADB8C'];

  const setLoading = (value) => {
    setIsLoading(value);
    console.log("hey this in changing loader value function ",value, isLoading);
  };



  
  useEffect(() => {
    // Scroll to the top of the page when it loads
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

    
  useEffect(() => {
    console.log('Selected Date:', selectedDate.toDate());
    // console.log("Logged In data ",loggedIn);
  }, [selectedDate]);

  useEffect(() => {
    // Set the app element for react-modal
    const rootElement = document.getElementById('root');
    Modal.setAppElement(rootElement);
  }, []);

  const handleImageClick = () => {
    navigate('/home');
  };

  //fetching all the data for calender 
  // useEffect(() => {
  //   // Fetch data from the database and populate the events state
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const completeSlotData = await fetchCompleteSlotData();
  //     if (completeSlotData) {
  //       // Process the data and convert it into events format
  //       const newEvents = [];
  //       for (const date in completeSlotData) {
  //         completeSlotData[date].forEach((slot, index) => {
  //           const isBooked = slot.ID.startsWith('booked');
  //           const color = isBooked ? bookedColors[index % bookedColors.length] : undefined;
            
  //           newEvents.push({
  //             id: newEvents.length + 1,
  //             ID: `${slot.ID}`,
  //             title: 'Blocked',
  //             start: moment(`${date} ${slot.start}`, 'DD/MM/YYYY HH:mm').toDate(),
  //             end: moment(`${date} ${slot.end}`, 'DD/MM/YYYY HH:mm').toDate(),
  //             className: isBooked ? 'booked-event' : undefined,
  //             color: color,
  //           });
  //         });
  //       }
  //       console.log("Events ",newEvents);
  //       setEvents(newEvents);
  //     }
  //   };

  //   fetchData();
  // }, [user]); // Fetch data on component mount

  useEffect(() => {
    setLoading(true); // Set loading to true before the call
    console.log("this is calender event from local storage",Events);
    setEvents(Events);
    setLoading(false);
    setIsInitialRender(false);
  },[Events])

  useEffect(() => {
    const fetchData = async () => {

      if (isInitialRender) {
        return;
      }

      console.log("Running this part -------------------------------------------")
      // setLoading(true); // Set loading to true before the call
      // console.log("this is calender event from local storage",JSON.parse(localStorage.getItem('CalenderEvents')));
      // setEvents(Events);
      // setLoading(false);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1000); // 1000 milliseconds (1 second)
      try {
        const completeSlotData = await fetchCompleteSlotData();
        console.log("data coming in front end about calender ",completeSlotData);
        if (completeSlotData) {
          // Process the data and convert it into events format
          const newEvents = [];
          for (const date in completeSlotData) {
            completeSlotData[date].forEach((slot, index) => {
              let isBooked;
              try{
                isBooked = slot.ID.startsWith('booked');
              }catch{
                isBooked =false;
              }
              // console.log("this is for checking start and end along with ID_________________________________________________________")
              // console.log("-----------------------------------------------------------------------------------------------------------------")
              // // ,moment(`${date} ${"05:30"}`, 'DD/MM/YYYY HH:mm').toDate(),slot.end,moment(`${date} ${slot.end}`, 'DD/MM/YYYY HH:mm').toDate())
              // console.log("Date 0 ",date);
              // console.log("slot id 1",slot.ID);
              // console.log("slot.start 2",slot.start);
              // console.log("(slot.start).length 3",(slot.start).length);
              // console.log("moment(`${date} ${slot.start}`, 'DD/MM/YYYY HH:mm') 4",moment(`${date} ${slot.start}`, 'DD/MM/YYYY HH:mm'));
              // console.log("moment(${date} ${slot.start}, 'DD/MM/YYYY HH:mm').toDate() 5",moment(`${date} ${slot.start}`, 'DD/MM/YYYY HH:mm').toDate());
              // console.log("slot.end 6",slot.end);
              // console.log("moment(`${date} ${slot.end}`, 'DD/MM/YYYY HH:mm').toDate()) 7",moment(`${date} ${slot.end}`, 'DD/MM/YYYY HH:mm').toDate());

              const color = isBooked ? bookedColors[index % bookedColors.length] : undefined;
              const eventTitle = slot.Name || 'Blocked';
              newEvents.push({
                id: newEvents.length + 1,
                ID: `${slot.ID}`,
                title: eventTitle,
                start: moment(`${date} ${slot.start}`, 'DD/MM/YYYY HH:mm').toDate(),
                end: moment(`${date} ${slot.end}`, 'DD/MM/YYYY HH:mm').toDate(),
                className: isBooked ? 'booked-event' : "not-yetbooked",
                color: color,
              });
            });
          }
          console.log("this is actual calender event  ",JSON.stringify(newEvents));
          localStorage.setItem('CalenderEvents', JSON.stringify(newEvents));
          setEvents(newEvents);
          setEvent(newEvents);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false); // Set loading to false after the call
      }
    };
  
    fetchData();
  }, [result]); // Fetch data on component mount
  
  function formatDateTime(input) {
    const match = input.match(/booked_(\d{2})\/(\d{2})\/(\d{4})_(\d{2}:\d{2})/);
  if (match) {
    const [_, day, month, year, time] = match;
    const [hour, minute] = time.split(':');

    const inputDate = new Date(year, month - 1, day, hour, minute);
    const options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    return new Intl.DateTimeFormat('en-US', options).format(inputDate);
  } else {
    // Handle invalid input
    return "Invalid Date/Time";
  }
  }

  const generateDayBlocks = () => {
    const days = [];
    const currentDate = moment();
    const startDate = currentDate.clone().subtract(3, 'days');
    const endDate = currentDate.clone().add(7, 'days');
    const dateRange = [];
    while (startDate.isBefore(endDate)) {
      dateRange.push(startDate.clone());
      startDate.add(1, 'day');
    }
    // console.log("date Range ", dateRange);

  
    return dateRange.map((date) => {
      const isSelectedDay = selectedDate.isSame(date, 'day');
      return (
        <>
          <div
            key={date.format('YYYY-MM-DD')}
            className={`day-block`}
            onClick={() => {
              console.log("on clicking", date.format('YYYY-MM-DD'));
              setSelectedDate(date.clone());
              logEvent(analytics, 'py_Calender_day_click', {
                Date: new Date().toLocaleString(),
                ID,
                clickedDate: date.format('DD/MM/YYYY'),
              });
            }}
          >
            <div style={{ marginBottom: "10%" }}>
              <div className='day-block2'>{date.format('ddd')}</div>
            </div>
            <div className={`date-block ${isSelectedDay ? 'selected-day-date' : ''}`}>
              <div className={`${isSelectedDay ? 'selected-day-date-inner' : 'date-block-inner '}`}>{date.format('DD')}</div>
            </div>
          </div>
        </>
      );
    });
  };

  const handleBookedEventClick = (event) => {
    if (event.className === 'booked-event') {
      setSelectedBookedEvent(event);
      setDetailIsModalOpen(true);
    }
  };



  const handleSelectSlot = ({ start, end }) => {
    // Set the selected slot

    const currentDate = moment();

    // Check if the selected date is today or before today
    if (moment(start).isSameOrBefore(currentDate, 'day')) {
      // Selected date is today or before today, disable further actions
      // alert("You can't select today or a past date.");
    } else {
      
    setSelectedSlot({ start, end });
    // Create a new event and add it to the events array
    const newEvent = {
      id: events.length + 1,
      title: 'Blocked',
      start,
      end,
    };
    setEvents([...events, newEvent]);
    setDummyEvent(newEvent);
    setAddDummyEvent(events);

    console.log('Slot selected:', start, end);
  }
  };

  const handleEditEvent = (event) => {
    setSelectedEvent(event);
    console.log("this is what u have to operate on ", event.start.toString().substring(0,16),typeof(event.start));
    setIsEditMode(true); // Enter edit mode
    setIsModalOpen(true);
    logEvent(analytics, 'py_click_edit_icon_on_Event_slot', {
      Date: new Date().toLocaleString(),
      ID,
      "pyEvent": JSON.stringify(event),
      "py_log" : "In calender Clicked on Edit icon on Event Slot"
    }); 
  };

  const handleUpdateSlot = (increment) => {
    // Update the time slot by incrementing or decrementing
    if (selectedEvent) {
      const updatedEvent = {
        ...selectedEvent,
        start: moment(selectedEvent.start).add(increment, 'minutes').toDate(),
        end: moment(selectedEvent.end).add(increment, 'minutes').toDate(),
      };
      setSelectedEvent(updatedEvent);
    }
  };

  const handleDeleteEvent = async (event) => {
    // console.log("this is handle Delete Event ",JSON.stringify(event));
    event.preventDefault();
    // Handle the deletion of the selected event
    // console.log("Selected Event ",JSON.stringify(selectedEvent.ID));
    logEvent(analytics, 'py_click_Delete_icon_on_Event_slot', {
      Date: new Date().toLocaleString(),
      ID,
      "pyEvent": JSON.stringify(selectedEvent),
      "py_log" : "In calender Clicked on Edit icon on Event Slot and Deleting the above Event"
    }); 

    if (selectedEvent) {
      setLoading(true);
      const updatedEvents = events.filter((event) => event.id !== selectedEvent.id);
      setEvents(updatedEvents);
      setSelectedEvent(null);
      setSelectedSlot(null);
      setIsModalOpen(false);
      setIsEditMode(false); 
      setLoading(false); // Set loading to false after the call
      const result = await deleteSlot(selectedEvent);
      console.log("result in calender ", result);
      if(result === 1){
        console.log("Slot Deleted Successfully");
        // const updatedEvents = events.filter((event) => event.id !== selectedEvent.id);
        // setEvents(updatedEvents);
        // setSelectedEvent(null);
        // setSelectedSlot(null);
        // setIsModalOpen(false);
        // setIsEditMode(false); // Exit edit mode
      }
      else{
        console.log("Error Deleting ")
      }
      // setLoading(false); // Set loading to false after the call
      
    }
  };


  useEffect(() => {
    const updateSlotToBackend = async () => {
      if (selectedEvent && !isLoading) {
        setIsEditMode(true);
        try {
          console.log("the value of selected event ",selectedEvent);
          const result = await deleteSlot(selectedEvent);
          console.log("result in calender ", result);
          console.log("Slot Deleted Successfully");
          console.log("Data Adding ...");
          const added = await AddNewSlot(selectedEvent);
          setResult(Math.random());
          console.log("value of added ",added)
          if(added===0){
            console.log("it is coming added ==== 0 ")
            const updatedEvents = events.filter((event) => (event!=dummyEvent));
            setEvents(updatedEvents);
            setShowOverlappingErrorModal(true);
          }

        } catch (error) {
          console.error("Error adding New Slot in the Slot", error);
          // Handle the error, e.g., show an error message to the user
        } 
      }
    setSelectedSlot(null);
    setIsEditMode(false);

    };
  
    updateSlotToBackend();
  }, [updating]);


  // new approach for updating slots to reduce the time taken 
  const handleUpdatedSlot = async (event) => {
    console.log("this is handle Delete Event ",event);
    event.preventDefault();
    // Handle the deletion of the selected event
    console.log("Selected Event ",selectedEvent);

    logEvent(analytics, 'py_updating_event_in_calender', {
      Date: new Date().toLocaleString(),
      ID,
      "pyEvent": JSON.stringify(selectedEvent),
      "py_log" : "Updating the given Event"
    }); 

    if(selectedEvent.start >= selectedEvent.end){
      alert("not possible ");
      setSelectedEvent(null);
      setSelectedSlot(null);
      setIsModalOpen(false);
      setIsEditMode(false); // Exit edit mode
      return;
    }
    

    if (selectedEvent) {
      setLoading(true);
      // const result = await deleteSlot(selectedEvent);
      // console.log("result in calender ", result);
      // console.log("Slot Deleted Successfully");
      console.log("selected slot ",selectedSlot);
      console.log("selected event ",selectedEvent);
      console.log("this is before removing ",events);
      var updatedEvents = events.filter((event) => event.ID !== selectedEvent.ID);
      setEvents(updatedEvents);
      console.log("this is after removing ", updatedEvents);
      // setIsEditMode(false); // Exit edit mode
      setSelectedSlot(selectedEvent);
      
      const newEvent = {
        id: events.length + 1,
        title: 'Blocked',
        start: selectedEvent.start,
        end: selectedEvent.end,
        className: "slot-under-process",
      };
      const overlappingEvents = updatedEvents.filter((event) => {
        return (
          event.id !== newEvent.id &&
          ((new Date(newEvent.start) < new Date(event.end) &&
            new Date(newEvent.end) > new Date(event.start)) ||
          (new Date(event.start) < new Date(newEvent.end) &&
            new Date(event.end) > new Date(newEvent.start)))
        );
      });
      
      if (overlappingEvents.length > 0) {
        console.log("Overlapping events detected in updatedEvents");
        const eventsToBeRemoved = [];
      
        overlappingEvents.forEach((event) => {
          if (newEvent.start <= event.start && newEvent.end >= event.end) {
            // The new event completely covers the overlapping event
            eventsToBeRemoved.push(event);
            console.log("The new event completely covers the overlapping event ",event,eventsToBeRemoved);
          } else if (newEvent.start <= event.start && newEvent.end < event.end) {
            // The new event partially covers the beginning of the overlapping event
            event.start = newEvent.start;
            console.log("The new event partially covers the beginning of the overlapping event ",event,eventsToBeRemoved);
          } else if (newEvent.start > event.start && newEvent.end >= event.end) {
            // The new event partially covers the end of the overlapping event
            event.end = newEvent.end;
            console.log(" The new event partially covers the end of the overlapping event ",event,eventsToBeRemoved);
          } else if (newEvent.start > event.start && newEvent.end < event.end) {
            // The new event is contained within the overlapping event
            const newEventPart1 = {
              ...event,
              id: updatedEvents.length + 1, // Assign a new ID
              end: newEvent.start,
            };
            const newEventPart2 = {
              ...event,
              id: updatedEvents.length + 2, // Assign a new ID
              start: newEvent.end,
            };
            updatedEvents.push(newEventPart1, newEventPart2);
            eventsToBeRemoved.push(event);
            console.log(" The new event partially covers the end of the overlapping event ",event,eventsToBeRemoved);
          }
        });
      
        // Remove events that are fully covered by the new event
        updatedEvents = updatedEvents.filter((event) => !eventsToBeRemoved.includes(event));
      
        console.log("Merged overlapping events in updatedEvents:", overlappingEvents);
      } else {
        // No overlaps in updatedEvents, add the new event to updatedEvents
        console.log("No overlap detected in updatedEvents");
        updatedEvents.push(newEvent);
      }
      
      
      
      
      setEvents(updatedEvents);
      try {
        setLoading(true);
        setUpdating(Math.random());

        // console.log("Data Adding ...");
        // const added = await AddNewSlot(selectedEvent);
        // setResult(Math.random());
        // console.log("value of added ",added)
        // if(added===0){
        //   console.log("it is coming added ==== 0 ")
        //   const updatedEvents = events.filter((event) => (event!=dummyEvent));
        //   setEvents(updatedEvents);
        //   setShowOverlappingErrorModal(true);
        // }
      } catch (error) {
          console.error("Error adding New Slot in the Slot", error);
          // Handle the error, e.g., show an error message to the user
      }
      finally {
        setLoading(false); // Set loading to false after the call
      }
      // setSelectedEvent(null);
      // setSelectedSlot(null);
      setIsModalOpen(false);
      
      
      setLoading(false); // Set loading to false after the call
      
    }
  };


  // const handleUpdatedSlot = async (event) => {
  //   console.log("this is handle Delete Event ",event);
  //   event.preventDefault();
  //   // Handle the deletion of the selected event
  //   console.log("Selected Event ",selectedEvent);

  //   logEvent(analytics, 'py_updating_event_in_calender', {
  //     Date: new Date().toLocaleString(),
  //     ID,
  //     "pyEvent": JSON.stringify(selectedEvent),
  //     "py_log" : "Updating the given Event"
  //   }); 

  //   if(selectedEvent.start >= selectedEvent.end){
  //     alert("not possible ");
  //     setSelectedEvent(null);
  //     setSelectedSlot(null);
  //     setIsModalOpen(false);
  //     setIsEditMode(false); // Exit edit mode
  //     return;
  //   }
    

  //   if (selectedEvent) {
  //     setLoading(true);
  //     const result = await deleteSlot(selectedEvent);
  //     console.log("result in calender ", result);
  //     console.log("Slot Deleted Successfully");
  //     const updatedEvents = events.filter((event) => event.id !== selectedEvent.id);
  //     setEvents(updatedEvents);
  //     console.log("result in calender ", events);
  //     setIsEditMode(false); // Exit edit mode
  //     setSelectedSlot(selectedEvent);
      
  //     const newEvent = {
  //       id: events.length + 1,
  //       title: 'Blocked',
  //       start: selectedEvent.start,
  //       end: selectedEvent.end,
  //     };
  //     setEvents([...updatedEvents, newEvent]);
  //     try {
  //       setLoading(true);
  //       console.log("Data Adding ...");
  //       const added = await AddNewSlot(selectedEvent);
  //       setResult(Math.random());
  //       console.log("value of added ",added)
  //       if(added===0){
  //         console.log("it is coming added ==== 0 ")
  //         const updatedEvents = events.filter((event) => (event!=dummyEvent));
  //         setEvents(updatedEvents);
  //         setShowOverlappingErrorModal(true);
  //       }
  //     } catch (error) {
  //         console.error("Error adding New Slot in the Slot", error);
  //         // Handle the error, e.g., show an error message to the user
  //     }
  //     finally {
  //       setLoading(false); // Set loading to false after the call
  //     }
  //     setSelectedEvent(null);
  //     setSelectedSlot(null);
  //     setIsModalOpen(false);
      
      
  //     setLoading(false); // Set loading to false after the call
      
  //   }
  // };

  // const handleConfirmSlot = async () => {
  //   console.log('Confirmed Slot:', selectedSlot);
  //   console.log("checking Edit Mode ",isEditMode);
  //   logEvent(analytics, 'py_adding_slot_in_calender', {
  //     Date: new Date().toLocaleString(),
  //     ID,
  //     "pyEvent": JSON.stringify(selectedSlot),
  //     "py_log" : "Adding the given Slot"
  //   });
  //   if (isEditMode) {
  //     // Update the edited event
  //     const updatedEvents = events.map((event) =>
  //       event.id === selectedEvent.id ? selectedEvent : event
  //     );
  //     console.log("okay i am here ")
  //     setEvents(updatedEvents);
  //   } else {
  //     // Create a new event and add it to the events array
  //     const newEvent = {
  //       id: events.length + 1,
  //       title: 'Blocked',
  //       start: selectedSlot.start,
  //       end: selectedSlot.end,
  //     };
  //     // setEvents([...events, newEvent]);
  //     try {
  //       setLoading(true);
  //       console.log("Data Adding ...");
  //       const added = await AddNewSlot(selectedSlot);
  //       setResult(Math.random());
  //       console.log("value of added ",added)
  //       if(added===0){
  //         console.log("it is coming added ==== 0 ")
  //         const updatedEvents = events.filter((event) => (event!=dummyEvent));
  //         setEvents(updatedEvents);
  //         setShowOverlappingErrorModal(true);
  //       }
  //     } catch (error) {
  //         console.error("Error adding New Slot in the Slot", error);
  //         // Handle the error, e.g., show an error message to the user
  //     }
  //     finally {
  //       setLoading(false); // Set loading to false after the call
  //     }
  //   }
  //   setIsEditMode(false); // Exit edit mode
  //   setSelectedSlot(null);
  //   setIsModalOpen(false);
  // };

  // new approach to save data in front end itself so it takes less time 

  useEffect(() => {
    const addNewSlotToBackend = async () => {
      if (selectedSlot && !isLoading) {
        setIsEditMode(true);
        try {
          console.log("Data Adding ...");
          
          // Proceed to add the event to the database
          const added = await AddNewSlot(selectedSlot);
          setResult(Math.random());
          console.log("Value of added", added);
        } catch (error) {
          console.error("Error adding New Slot in the Slot", error);
          // Handle the error, e.g., show an error message to the user
        } 
      }
    setSelectedSlot(null);
    setIsEditMode(false);

    };
  
    addNewSlotToBackend();
  }, [Adding]);

  
  const handleConfirmSlot = async () => {
    console.log('Confirmed Slot:', selectedSlot);
    console.log("checking Edit Mode ", isEditMode);
    console.log("checking existing events ",events);
    logEvent(analytics, 'py_adding_slot_in_calendar', {
      Date: new Date().toLocaleString(),
      ID,
      "pyEvent": JSON.stringify(selectedSlot),
      "py_log": "Adding the given Slot"
    });
  
    if (isEditMode) {
      // Update the edited event
      const updatedEvents = events.map((event) =>
        event.id === selectedEvent.id ? selectedEvent : event
      );
      console.log("Okay, I am here ");
      setEvents(updatedEvents);
    } else {
      // Create a new event
      const newEvent = {
        id: events.length + 1,
        title: 'Blocked',
        start: selectedSlot.start,
        end: selectedSlot.end,
        className: "slot-under-process",
      };
  
      try {
        setLoading(true);
        console.log("Data Adding ... previous data",events);
        
        // Merge the new event into existing events
        const mergedEvents = [...addDummyEvent, newEvent];
        console.log("merged events ",mergedEvents)
         // Check for overlapping events
        const overlappingEvents = mergedEvents.filter((event) => {
          return (
            event.id !== newEvent.id &&
            (
              (new Date(newEvent.start) < new Date(event.end) &&
                new Date(newEvent.end) > new Date(event.start)) ||
              (new Date(event.start) < new Date(newEvent.end) &&
                new Date(event.end) > new Date(newEvent.start))
            )
          );
        });
        console.log("this is overlapping events ",overlappingEvents);
        if (overlappingEvents.length > 0) {
          console.log("Overlapping events detected");
          // Update start and end times of overlapping events
          overlappingEvents.forEach((event) => {
            if (newEvent.start < event.start) {
              // If newEvent starts before the overlapping event
              event.start = newEvent.end; // Update the overlapping event's start
            } else {
              // If newEvent starts after the overlapping event
              event.end = newEvent.start; // Update the overlapping event's end
            }
          });
          // Handle the merged overlapping events
          console.log("Merged overlapping events:", overlappingEvents);
        } else {
          // No overlaps, add the new event to the state
          console.log("no overlap detected")
          setEvents(mergedEvents);
        }
        console.log("now loading will be gone 1",isLoading)
        setLoading(false);
        console.log("now loading will be gone 2",isLoading)
         // Proceed to add the event to the database
        //  const added = await AddNewSlot(selectedSlot);
         setAdding(Math.random());
        //  console.log("Value of added", added);
      } catch (error) {
        console.error("Error adding New Slot in the Slot", error);
        // Handle the error, e.g., show an error message to the user
      } 
    }
    
  
    setIsEditMode(false); // Exit edit mode
    // setSelectedSlot(null);
    setIsModalOpen(false);
  };
  


  const handleCancelSlot = () => {
    // Check if selectedSlot is not null
    if (selectedSlot) {
      // Handle the cancellation of the selected slot here
      console.log('Cancelled Slot:', selectedSlot);
      
      const updatedEvents = events.filter((event) => (
        event.start !== selectedSlot.start && event.end !== selectedSlot.end
      ));
      setEvents(updatedEvents);
      
      if (isEditMode) {
        // If in edit mode, reset the selected event to its original state
        setSelectedEvent(null); // Clear selected event
        setSelectedSlot(null); // Clear selected slot
        setIsModalOpen(false); // Close the modal
        setIsEditMode(false); // Exit edit mode
      } else {
        // If not in edit mode, simply clear the selected slot
        setSelectedSlot(null);
        setIsModalOpen(false);
      }
    } else {
      // Handle the case where selectedSlot is null (optional)
      console.log('No slot selected to cancel.');
      setIsModalOpen(false);
    }
  };
  
  const handleDeleteSlot = () => {
    // Handle the deletion of the selected slot here
    if (selectedEvent) {
      const updatedEvents = events.filter((event) => event.id !== selectedEvent.id);
      setEvents(updatedEvents);
      setSelectedEvent(null);
      setSelectedSlot(null);
      setIsModalOpen(false);
      setIsEditMode(false); // Exit edit mode
    }
  };

  const now = new Date(); // Get the current date and time
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 0, 0); // Set the start time to 8:00 AM

  return (
    <div>

      <div className="top-container" style={{ paddingTop: "8%"}}>
        <div style={{ marginLeft: "5%" }}>
          <img
            className="AP-back-image"
            src={left}
            alt="back"
            onClick={handleImageClick}
          />
          &nbsp; &nbsp;
          <span className="calender-text">Calendar</span>
        </div>
        <div className="day-block-container">
        <br />
        {generateDayBlocks()}
      </div>
      </div>

      
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner-grow" role="status">
          </div>
        </div>
      )}
      <Calendar
        localizer={localizer}
        defaultDate={selectedDate}
        date={selectedDate} 
        defaultView="day"
        views={['day']}
        style={{ height: '60vh' }}
        events={events}
        selectable
        onSelectSlot={handleSelectSlot}
        onNavigate={(newDate) => setSelectedDate(moment(newDate))}
        components={{
          timeSlotWrapper: (props) => (
            <div className="my-calendar-text">{props.children}</div>
          ),
          event: ({ event }) => (
            <div
              className={`my-calendar-text ${event.className}`}
              style={{ background: event.color }}
              onClick={() => handleBookedEventClick(event)} // Handle click on "booked-event"
            >
              {event.title !== 'Blocked' &&(
                <div>{event.title}</div>
              )}
              
              {new Date(event.start) < new Date() || event.className ==="not-yetbooked" && ( // Show edit icon for non-booked events need to go through this again 


              <div className="calender-edit-icon-container">
                <img
                  className='calender-edit-icon'
                  src={editimg}
                  onClick={() => handleEditEvent(event)} // Pass the event to the handler
                />
              </div>
                
              )}
              {event.className ==="slot-under-process" && ( // Show edit icon for non-booked events need to go through this again 


              <div className="calender-edit-icon-container">  
                <img
                  className='calender-edit-icon'
                  src={editimg}
                  // onClick={() => handleEditEvent(event)} // Pass the event to the handler
                />
              </div>
                
              )}
            </div>
          ),
        }}
        dayPropGetter={() => ({ className: 'custom-day-slot' })}
        toolbar={false}
        min={today} // Set the minimum visible date and time
        // max={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 20, 0, 0)} // Set the maximum visible date and time

      />
      <br /> <br /> <br /> <br /> <br />

      {selectedSlot && (
        <div>
          
          {isEditMode ? (
            <div>
              {/* <button onClick={() => handleUpdateSlot(-15)}>Decrease Time</button>
              <button onClick={() => handleUpdateSlot(15)}>Increase Time</button>
              <button onClick={handleDeleteSlot}>Delete Slot</button>
              <button onClick={handleConfirmSlot}>Save</button>
              <button onClick={handleCancelSlot}>Cancel</button> */}
            </div>
          ) : (
            <div className='overlay'>
              {/* <button onClick={handleConfirmSlot}>Confirm Slot</button>
              <button onClick={handleCancelSlot}>Cancel</button> */}

              <div type="submit" onClick={handleConfirmSlot}> 
                <div className='CAP-button'>
                  <div className='Cinner-AP-button'>
                    <div className='CAP-text'>SAVE</div>
                  </div>  
                </div>
              </div>
              <button type="submit" className='Calender-Cancel-btn' onClick={handleCancelSlot}> 
                <div className='cld-btn-inner'>
                  <div className='CB-text'>CANCEL</div>
                </div>
              </button>
            </div>
          )}
        </div>
      )}

      <Footer />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
          setSelectedEvent(null);
        }}
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)', // Background overlay color
            transition: 'background 0.5s ease',
            zIndex : '2',
            
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: 'none', // Remove border
            background: '#fff', // Background color of the modal
            padding: '20px', // Adjust padding as needed
            width: '300px' 
          },
        }}
      >
        {selectedEvent && (
          <div>
            <form>
              <div className='calender-edit-header'>
                <div className='calender-edit'>
                  {selectedEvent.start.toString().substring(0,16)}
                </div>
                <img 
                  className='AP-back-image'
                  src={deleteimg}
                  alt="back"
                  type="button" onClick={handleDeleteEvent}
                />
              </div>
              <div className='calender-edit-content'>
                <label className='calender-edit-label'>Start</label>
                <input
                  className='calender-edit-input'
                  type="time"
                  name="start"
                  value={moment(selectedEvent.start).format('HH:mm')}
                  onChange={(e) =>
                    setSelectedEvent((prevSelectedEvent) => {
                      const newStartTime = moment(e.target.value, 'HH:mm');
                      const newStartDateTime = moment(prevSelectedEvent.start)
                        .set('hour', newStartTime.hour())
                        .set('minute', newStartTime.minute());
                
                      return {
                        ...prevSelectedEvent,
                        start: newStartDateTime.toDate(),
                      };
                    })
                  }
                />
              </div>
              <div className='calender-edit-content'>
                <label  className='calender-edit-label'>End</label>
                <input
                  className='calender-edit-input'
                  type="time"
                  name="end"
                  value={moment(selectedEvent.end).format('HH:mm')}
                  onChange={(e) =>
                    setSelectedEvent((prevSelectedEvent) => {
                      const newEndTime = moment(e.target.value, 'HH:mm');
                      const newEndDateTime = moment(prevSelectedEvent.start)
                        .set('hour', newEndTime.hour())
                        .set('minute', newEndTime.minute());
                      
                      return {
                        ...prevSelectedEvent,
                        end: newEndDateTime.toDate(),
                      };
                    })
                  }
                />
              </div>
              <div className='AP-button3' type="button" onClick={handleUpdatedSlot}>
                <div className='inner-AP-button'>
                  <div className='AP-text1'>SAVE</div>
                </div>
              </div>
              
            </form>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={isDetailModalOpen}
        onRequestClose={() => {
          setDetailIsModalOpen(false);
          setSelectedBookedEvent(null);
        }}
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)', // Background overlay color
            transition: 'background 0.5s ease',
            zIndex : '2',
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: 'none', // Remove border
            background: '#fff', // Background color of the modal
            padding: '20px', // Adjust padding as needed
            width: '300px',
            // height: '120px', 
          },
        }}
      >
        {selectedBookedEvent && (
          <div>
            <div className='RCmodelheader'>
              <div className='RCmodelheadertext' style={{fontSize:"120%"}}>
                {selectedBookedEvent.title} 
              </div>
              <div className='RCmodelheaderbtncontainer'>
                <div>
                  <img 
                    className='RCmodelheaderbtnimage'
                    src={cross}
                    alt="back"
                    style={{ marginLeft:"5%", height:"25px"}}
                    onClick={() => {
                      setDetailIsModalOpen(false);
                    }}
                  />
                </div>
              </div>
            </div>
            <div  className='APpage-text1' >
              
                Video Consultation Scheduled <br />
                {formatDateTime(selectedBookedEvent.ID)}
            </div>
          </div>
        )}
      </Modal>
      

    </div>
  );
};
