import React, { useState, useEffect } from 'react';
import { useUserAuth } from "../../context/UserAuthContext";
import { Link } from 'react-router-dom';
import { analytics } from "../../firebase/index";
import { logEvent } from "firebase/analytics";

export const WeeklyUpcomingAppointments = () => {  
  const { ID, user, consultation, WeeklyUpcomingAppointmentsData } = useUserAuth();
  const [appointmentsData, setAppointmentsData] = useState(null);
  const [showData, setShowData] = useState(false);

  // Function to format the date as "Tue, 22 Aug 2023"
  const formatDate = (dateString) => {
    const parts = dateString.split('/'); // Split the date string by '/'
    if (parts.length === 3) {
      const day = parts[0];
      const month = parts[1];
      const year = parts[2];
      const date = new Date(`${year}-${month}-${day}`); // Create a valid date object
      const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    } else {
      return 'Invalid Date';
    }
  };

  function formattime(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12}:${String(minutes).padStart(2, '0')} ${period}`;
    return formattedTime;
  }


  const shouldDisplayJoinButton = (appointmentDate, appointmentTime) => {
    const currentTime = new Date();
    const [day, month, year] = appointmentDate.split('/').map(Number);
    const [hours, minutes] = appointmentTime.split(':').map(Number);
    const appointmentTimeDate = new Date(year, month - 1, day, hours, minutes, 0, 0);
    const startTime = new Date(appointmentTimeDate - 5 * 60 * 1000); // 5 minutes before the appointment
    const endTime = new Date(appointmentTimeDate.getTime() + 30 * 60 * 1000); // 30 minutes after the appointment
    return currentTime >= startTime && currentTime <= endTime;
  };
  

  // fetching lans data (programs)
  useEffect(() => {
    const fetchWeeklyUpcomingAppointmentsData = async () => {
      // console.log('Fetching Weekly Upcoming Appointment data...');
      setAppointmentsData(JSON.parse(localStorage.getItem('WeeklyUpcomingAppointmentsData')));
      setShowData(true);
      const data = await WeeklyUpcomingAppointmentsData();
      const filteredData = data.filter(item => item.ConsultationCompleted === false);
      localStorage.setItem('WeeklyUpcomingAppointmentsData', JSON.stringify(filteredData));
      setAppointmentsData(filteredData);
      console.log("data in appointment ",data,filteredData);
      console.log("consultation ka value",consultation)
      
    };

    fetchWeeklyUpcomingAppointmentsData();
  }, [user]);

  const logJoinBtnClick = (item) => {
    // Log the event when a footer item is clicked
    logEvent(analytics, 'py_join_btn_click', {
      ID,
      item: item,
      "py_log":"Join button Clicked from Weekly Upcoming Appointments",
    });
  };


  if(!consultation){
    console.log("ye toh yaha fass rha h ")
    return null;
  }

  if (!showData) {
    return (
      <div className="spinner-container">
        <div className="spinner-grow" role="status">
        </div>
      </div>
    ) // loader untill it is loaded 
  }

  else if (Object.keys(appointmentsData).length === 0) {
    return (
      null
    ) // loader untill it is loaded 
  }



  return (
    <div className='WUA-container'>
      <div>
        <div className='WUA-text'>Weekly Upcoming Appointments <span style={{ color: "#004ce6" }}>({Object.keys(appointmentsData).length})</span></div>
      </div>
      
      {/* {appointmentsData && Object.keys(appointmentsData).map(appointmentID => (
        <>
        <hr />
        <div className='WUA-block' key={appointmentID}>
          <div className='WUA-block-time'>
            {appointmentsData[appointmentID].sessions[0].ConsultationTime}
          </div>
          <div className='WUA-block-inner'>
            <div className='WUA-block-name'>
              {appointmentsData[appointmentID].patientName}
            </div>
            <div className='WUA-block-date'>
            {formatDate(appointmentsData[appointmentID].sessions[0].ConsultationDate)}
            </div>
          </div>
          <div>
            {shouldDisplayJoinButton(appointmentsData[appointmentID].sessions[0].ConsultationDate, appointmentsData[appointmentID].sessions[0].ConsultationTime) && (
              <Link to={appointmentsData[appointmentID].sessions[0].ConsultationJoinLink} style={{textDecoration:"none"}}>
                <div className='joinbtn-outer'>
                  <div className='joinbtn-inner'>
                    JOIN
                  </div>
                </div>
              </Link>
            )}
          </div>
        </div>
        </>
      ))} */}
      {appointmentsData && appointmentsData.map((session, index) => (
      <div key={index}>
        <hr />
        <div>
          <div className='WUA-block' key={index}>
            <div className='WUA-block-time'>
              {formattime(session.ConsultationTime)}
            </div>
            <div className='WUA-block-inner'>
              <div className='WUA-block-name'>
                {session.PatientName}
              </div>
              <div className='WUA-block-date'>
                {formatDate(session.ConsultationDate)}
              </div>
            </div>
            <div>
              {shouldDisplayJoinButton(session.ConsultationDate, session.ConsultationTime) && (
                <a href={session.ConsultationJoinLink} style={{ textDecoration: "none" }}>
                  <div className='joinbtn-outer' onClick={() => {logJoinBtnClick('Calendar')}}>
                    <div className='joinbtn-inner'>
                      JOIN
                    </div>
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      ))}


    </div>
  )
}
