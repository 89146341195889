export const errors = [
  {
    error: "NeckTiltError",
    badtext: "Neck Forward Tilt",
    goodtext: "Straight Neck",
    badimage: "Bracing_NeckTiltError",
    goodimage: "Bracing_NeckTiltError_Normal",
  },
  {
    error: "ShruggingError",
    badtext: "Shoulder Shrug",
    goodtext: "Lower Shoulders",
    badimage: "Bracing_ShruggingError",
    goodimage: "Bracing_ShruggingError_Normal",
  },
  // {
  //   error: "BracingNeckRotationError",
  //   badtext: "Neck Rotations",
  //   goodtext: "Stable Neck",
  //   badimage: "",
  //   goodimage: "Bracing With Shoulder Extension_Normal",
  // },
  {
    error: "SRSENoBracingError",
    badtext: "Bracing Missing",
    goodtext: "Braced Shoulders",
    badimage: "",
    goodimage: "Bracing With Shoulder Extension_Normal",
  },
  {
    error: "SRSENeckTiltError",
    badtext: "Neck Forward Tilt",
    goodtext: "Straight Neck",
    badimage: "",
    goodimage: "",
  },
  {
    error: "BackBendError",
    badtext: "Bending Forward",
    goodtext: "Stand Straight",
    badimage: "Bracing With Shoulder Extension_BackBendError",
    goodimage: "Bracing With Shoulder Extension_Normal",
  },
  // {
  //   error: "WPBackBendError",
  //   badtext: "Leaning on the Wall",
  //   goodtext: "Stand Straight",
  //   badimage: "WallPush_BackBend",
  //   goodimage: "WallPush_Normal",
  // },
  {
    error: "WPWristUpError",
    badtext: "Wrists moving upwards",
    goodtext: "Elbow bent at 90°",
    badimage: "WallPush_WristUp",
    goodimage: "WallPush_Normal",
  },
  {
    error: "WPWristDownError",
    badtext: "Wrists moving downwards",
    goodtext: "Elbow bent at 90°",
    badimage: "WallPush_WristDown",
    goodimage: "WallPush_Normal",
  },
  {
    error: "HandFront",
    badtext: "Front Direction Error",
    goodtext: "Lift Diagonally",
    badimage: "Supra Spinatus_HandFront",
    goodimage: "Supra Spinatus_Normal",
  },
  {
    error: "HandSide",
    badtext: "Side Direction Error",
    goodtext: "Lift Diagonally",
    badimage: "Supra Spinatus_HandSide",
    goodimage: "Supra Spinatus_Normal",
  },
  // {
  //   error: "SSShruggingError",
  //   badtext: "Shoulder Shrug",
  //   goodtext: "Lower Shoulders",
  //   badimage: "",
  //   goodimage: "",
  // },
  {
    error: "ElbowBend",
    badtext: "Elbows not tucked to the body",
    goodtext: "Tuck Elbows in",
    badimage: "Supra Spinatus_ElbowBend",
    goodimage: "Supra Spinatus_Normal",
  },
  {
    error: "TorsoTilt",
    badtext: "Rotating in the Hips",
    goodtext: "Stand Straight",
    badimage: "Supra Spinatus_TorsoTilt",
    goodimage: "Supra Spinatus_Normal",
  },

  { //image not present 
    error: "SideBend",
    badtext: "Bending Sideways",
    goodtext: "Stand Straight",
    badimage: "Supra Spinatus_SideBend",
    goodimage: "Supra Spinatus_Normal",
  },
  {
    error: "WristUp",
    badtext: "Wrists moving upwards",
    goodtext: "Elbow bent at 90°",
    badimage: "Rhomboids_WristUp",
    goodimage: "Rhomboids_Normal",
  },
  {
    error: "WristDown",
    badtext: "Wrists moving downwards",
    goodtext: "Elbow bent at 90°",
    badimage: "Rhomboids_WristDown",
    goodimage: "Rhomboids_Normal",
  },
  {
    error: "ElbowBend",
    badtext: "Elbows not tucked to the body",
    goodtext: "Tuck Elbows in",
    badimage: "Rhomboids_ElbowBend",
    goodimage: "Rhomboids_Normal",
  },
  {
    error: "SAWristUpError",
    badtext: "Hands stretched higher",
    goodtext: "Hold at chest level",
    badimage: "Serratus Anterior_WristUp",
    goodimage: "Serratus Anterior_Normal",
  },
  // {
  //   error: "SAShouldershrug",
  //   badtext: "",
  //   goodtext: "",
  //   badimage: "",
  //   goodimage: "Serratus Anterior_Normal",
  // },
  {
    error: "SAWristDownError",
    badtext: "Hands stretched lower",
    goodtext: "Hold at chest level",
    badimage: "Serratus Anterior_WristDown",
    goodimage: "Serratus Anterior_Normal",
  },
  {
    error: "ISTorsoTilt",
    badtext: "Rotating in the Hips",
    goodtext: "Stand Straight",
    badimage: "Infra Spinatus_TorsoTilt",
    goodimage: "Infra Spinatus_Normal",
  },
  {
    error: "ISElbowBend",
    badtext: "Elbows not tucked to the body",
    goodtext: "Tuck Elbows in",
    badimage: "Infra Spinatus_ElbowBend",
    goodimage: "Infra Spinatus_Normal",
  },
  // {
  //   error: "ISSideBend",
  //   badtext: "Bending Sideways",
  //   goodtext: "Stand Straight",
  //   badimage: "",
  //   goodimage: "",
  // },
  {
    error: "ISWristup",
    badtext: "Wrists moving upwards",
    goodtext: "Elbow bent at 90°",
    badimage: "Infra Spinatus_WristUp",
    goodimage: "Infra Spinatus_Normal",
  },
  {
    error: "ISWristDown",
    badtext: "Wrists moving downwards",
    goodtext: "Elbow bent at 90°",
    badimage: "Infra Spinatus_WristDown",
    goodimage: "Infra Spinatus_Normal",
  },
  // {
  //   error: "ISNoBracing",
  //   badtext: "Shoulders not braced",
  //   goodtext: "Braced Shoulders",
  //   badimage: "",
  //   goodimage: "",
  // },
  {
    error: "BackBend",
    badtext: "Leaning on the Wall",
    goodtext: "Stand Straight",
    badimage: "Low Row_BackBend",
    goodimage: "Low Row_Normal",
  },
  {
    error: "LRWristUpError",
    badtext: "Wrists moving upwards",
    goodtext: "Elbow bent at 90°",
    badimage: "Low Row_WristUp",
    goodimage: "Low Row_Normal",
  },
  {
    error: "LRWristDownError",
    badtext: "Wrists moving downwards",
    goodtext: "Elbow bent at 90°",
    badimage: "Low Row_WristDown",
    goodimage: "Low Row_Normal",
  },
  // {
  //   error: "PronatedNeckUpError",
  //   badtext: "Neck lifted off the ground",
  //   goodtext: "Head touching the Ground",
  //   badimage: "",
  //   goodimage: "",
  // },
  // {
  //   error: "PronatedSRNeckUpError",
  //   badtext: "Neck lifted off the ground",
  //   goodtext: "Head touching the Ground",
  //   badimage: "",
  //   goodimage: "",
  // },
  {
    error: "riseUpError",
    badtext: "Hips Sinking Lower",
    goodtext: "Hold Hips high",
    badimage: "",
    goodimage: "",
  },
  {
    error: "Kneebend",
    badtext: "Bent Knees",
    goodtext: "Legs Straight",
    badimage: "",
    goodimage: "",
  },
  {
    error: "Backnotvertical",
    badtext: "Back not Straight",
    goodtext: "Back Straight",
    badimage: "",
    goodimage: "",
  },
  {
    error: "Handnotaligned",
    badtext: "Hand not Aligned",
    goodtext: "Hand Aligned",
    badimage: "",
    goodimage: "",
  },
  {
    error: "Backbend",
    badtext: "Back Bent",
    goodtext: "Back not Bent",
    badimage: "",
    goodimage: "",
  },
  {
    error: "Handsbent",
    badtext: "Hands Bent",
    goodtext: "Hands not Bent",
    badimage: "",
    goodimage: "",
  },
  {
    error: "Elbowsup",
    badtext: "Elbows moving up",
    goodtext: "Elbows at 90°",
    badimage: "",
    goodimage: "",
  },
  {
    error: "Elbowsdown",
    badtext: "Elbows moving up",
    goodtext: "Elbows at 90°",
    badimage: "",
    goodimage: "",
  },
];