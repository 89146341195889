// Index.js
import React, { useEffect, useState } from 'react';
import { useUserAuth } from '../context/UserAuthContext';
import { useNavigate } from 'react-router-dom';
import SplashScreen from './components/SplashScreen';
import moment from 'moment';
import NoInternetPopup from './components/NoInternetPopup';

export const Index = () => {
  const navigate = useNavigate();
  const { auth, user, programRenewalData, setEvent, setDocEvent, getAllPrograms, GetAllPhysioData, fetchPhysioData, WeeklyUpcomingAppointmentsData, fetchCompleteSlotData,VideoCallHistoryData, ADDingdata, fetchCompleteSlotDocData, tranferdata,getProgramCodes } = useUserAuth();
  const bookedColors = ['#FADB8C', '#FADB8C', '#FADB8C', '#FADB8C'];
 
  const handleSplashComplete = () => {
    console.log("this is user Data ",localStorage.getItem('userData'))
    console.log("this is user Data ",localStorage.getItem('ID'))
    if (localStorage.getItem('userData')) {
      if(localStorage.getItem('ID'))
        navigate('/home');
      else
        navigate('/phone')
    } else {
      navigate('/phone');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // console.log("this in function index");
      // await tranferdata();
      const codes = await getProgramCodes();
      localStorage.setItem('codes', JSON.stringify(codes));
      const data = await GetAllPhysioData();
      localStorage.setItem('Data', JSON.stringify(data));
      const data2 = await VideoCallHistoryData();
      localStorage.setItem('VideoCallHistoryData', JSON.stringify(data2));
      const data1 = await WeeklyUpcomingAppointmentsData();
      const filteredData = data1.filter(item => item.ConsultationCompleted === false);
      localStorage.setItem('WeeklyUpcomingAppointmentsData', JSON.stringify(filteredData));
      const output = await programRenewalData();
      // console.log("output is component index", output);
      localStorage.setItem('ProgramRenewalData', JSON.stringify(output));
      const completeSlotData = await fetchCompleteSlotData();
        if (completeSlotData) {
          // Process the data and convert it into events format
          const newEvents = [];
          for (const date in completeSlotData) {
            completeSlotData[date].forEach((slot, index) => {
              const isBooked = slot.ID.startsWith('booked');
              const color = isBooked ? bookedColors[index % bookedColors.length] : undefined;
              const eventTitle = slot.Name || 'Blocked';
              newEvents.push({
                id: newEvents.length + 1,
                ID: `${slot.ID}`,
                title: eventTitle,
                start: moment(`${date} ${slot.start}`, 'DD/MM/YYYY HH:mm').toDate(),
                end: moment(`${date} ${slot.end}`, 'DD/MM/YYYY HH:mm').toDate(),
                className: isBooked ? 'booked-event' : "not-yetbooked",
                color: color,
              });
            });
          }
        
          // console.log("this is actual calender event  ",newEvents);
          localStorage.setItem('CalenderEvents', JSON.stringify(newEvents));
          setEvent(newEvents);
        }

      // for Doctors Calender Data 
      const completeDocSlotData = await fetchCompleteSlotDocData();
      if (completeSlotData) {
        // Process the data and convert it into events format
        const newEvents = [];
        for (const date in completeSlotData) {
          completeSlotData[date].forEach((slot, index) => {
            let isBooked = false;

            try {
              isBooked = slot.ID && slot.ID.startsWith('booked');
            } catch {
              isBooked = false;
            }

            const color = isBooked ? bookedColors[index % bookedColors.length] : undefined;
            const eventTitle = slot.Name || 'Blocked';

            // Ensure properties exist before accessing them
            const start = slot.start ? new Date(date + ' ' + slot.start) : null;
            const end = slot.end ? new Date(date + ' ' + slot.end) : null;

            newEvents.push({
              id: newEvents.length + 1,
              ID: `${slot.ID}`,
              title: eventTitle,
              start: start,
              end: end,
              className: isBooked ? 'booked-event' : "not-yetbooked",
              color: color,
              appointmentType: slot.appointmentType || '',
              appointmentStartTime: slot.appointmentStartTime || '',
              day: slot.day || '',
              remainingMinutes: slot.remainingMinutes || '',
              slotDuration: slot.slotDuration || '',
              patientName: slot.patientname || '', // Add check for patientName
              patientNumber: slot.patientnumber || '' // Add check for patientNumber
            });
          });
        }
        
        console.log("this is actual calendar event  ", newEvents);
        localStorage.setItem('DocCalenderEvents', JSON.stringify(newEvents));
        // setEvents(newEvents);
        setDocEvent(newEvents);
      }
      const programsData = await getAllPrograms();
      localStorage.setItem('Program', JSON.stringify(programsData));
      
      
      const userData = await fetchPhysioData();
      if (userData) {
        localStorage.setItem('py_username', userData.Name);
        localStorage.setItem('py_About', userData.About);
        localStorage.setItem('py_Price', userData.ConsultancyPrice);
      }
      
      

    };
    const tranferingdata = async () => {
      await ADDingdata();
    }


    fetchData();
    // tranferingdata();
  }, [user]);

  return (
    <div>
        <SplashScreen onSplashComplete={handleSplashComplete} />

        <NoInternetPopup />
      
    </div>
    // <div>
    //   <SplashScreen onSplashComplete={handleSplashComplete} >
    //   </SplashScreen>
    // </div>
  );
};
