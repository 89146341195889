import { useUserAuth } from "context/UserAuthContext";
import React,{ useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({children}) =>{
    let { user,loggedIn } = useUserAuth();
    const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user !== null) {
      // User authentication is complete
      setLoading(false);
    }
  }, [user]);


//   console.log("the value of user in route ",user);

  useEffect(() =>{
    // console.log("inside real use effect ")
    // if(!user){
    //     return <Navigate to = "/"/>; 
    // }
  },[loading]);
  // console.log("the value of user in Protected Route 1", loggedIn);
//   if(!user){
//      return <Navigate to = "/"/>; 
//   }
  // console.log("the value of user in Protected Route 2", loggedIn);
  // console.log("childern",children);

  return children ; 

}

export default ProtectedRoute;