import React from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { Login } from "./pages/Login";
import { Col, Container } from 'react-bootstrap';
import { SignUp } from './pages/SignUp';
import { Home } from './pages/Home';
import { PhoneSignUp } from './pages/PhoneSignUp';    // this need to be changes to PhoneSignUp
import { PlanView } from './pages/PlanView';
import  ProtectedRoute  from './pages/ProtectedRoute';
import UserAuthContextProvider from './context/UserAuthContext'; // Import directly from the context file
import OTPpage from 'pages/OTPpage';
import { Profile } from 'pages/Profile';
import { PatientProfile } from 'pages/PatientProfile';
import { AddPatient } from 'pages/AddPatient';
import { Calender } from 'pages/Calender';
import { useUserAuth } from "./context/UserAuthContext";
import { ProgramRenewalPage } from 'pages/ProgramRenewalPage';
import { Index } from 'pages/Index';
import { VideoCallHistory } from 'pages/VideoCallHistory';
import { Report } from 'pages/Report';
import { AddAIProgram } from 'pages/AddAIProgram';
import './messaging_init_in_sw'
import NotificationPage from 'pages/NotificationPage';
import { DocCalender } from 'pages/DocCalender';
import { PhoneOTPSignUp } from 'pages/PhoneOTPSignUp';
import { QRcodePage } from 'pages/QRcodePage';
import OnboardingForm from 'pages/OnboardingForm';
import PhysioNewSignUpPhone from 'pages/PhysioNewSignUpPhone';
import PhysioNewOTPPage from 'pages/PhysioNewOTPPage';
import MyPrograms from 'pages/MyPrograms';
import JointSelectionPage from 'pages/JointSelectionPage';
import CustomModuleCreation from 'pages/CustomModuleCreation';
import GetStarted from 'pages/GetStarted';


function App() {
  return (
    <div className="App">
        <Col>
          <UserAuthContextProvider>
            <Router basename="">
              <Routes>
                <Route path="/" element={<ProtectedRoute><Index/></ProtectedRoute>} />
                <Route path="/login" element={<Login />} />
                <Route path="/login1" element={<PhysioNewSignUpPhone />} />
                {/* <Route path="/phone" element={<PhoneOTPSignUp />} />
                <Route path="/otp/:countryCode/:mobileNumber" element={<OTPpage />} /> */}
                <Route path="/phone" element={<PhysioNewSignUpPhone />} />
                <Route path="/otp/:countryCode/:mobileNumber" element={<PhysioNewOTPPage />} />
                <Route path="/signUp" element={<SignUp />} />
                <Route path='/getstarted' element = {<ProtectedRoute><GetStarted/></ProtectedRoute>} />
                <Route path='/home' element = {<ProtectedRoute><Home/></ProtectedRoute>} />
                <Route path="/plan/:planId/:planName/:code" element={<ProtectedRoute><PlanView /></ProtectedRoute>} />
                <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                <Route path="/patientprofile/:patientid/:patientname/:patientnumber" element={<ProtectedRoute><PatientProfile /></ProtectedRoute>} />
                <Route path="/addpatient" element={<ProtectedRoute><AddPatient /></ProtectedRoute>} />
                <Route path="/calendar" element={<ProtectedRoute><Calender /></ProtectedRoute>} />
                <Route path="/doccalendar" element={<ProtectedRoute><DocCalender /></ProtectedRoute>} />
                <Route path="/programrenewal" element={<ProtectedRoute><ProgramRenewalPage/></ProtectedRoute>} />
                <Route path="/videocallhistory" element={<ProtectedRoute><VideoCallHistory/></ProtectedRoute>} />
                <Route path="/report" element={<ProtectedRoute><Report/></ProtectedRoute>} />
                <Route path="/addaiprogram/:patientid" element={<ProtectedRoute><AddAIProgram/></ProtectedRoute>} />
                <Route path="/qrcode" element={<ProtectedRoute><QRcodePage/></ProtectedRoute>} />
                <Route path="/onboarding/:mobilenumber" element={<ProtectedRoute><OnboardingForm/></ProtectedRoute>} />
                <Route path="/myprograms" element={<ProtectedRoute><MyPrograms/></ProtectedRoute>} />
                <Route path="/joint" element={<ProtectedRoute><JointSelectionPage/></ProtectedRoute>} />
                <Route path="/custommodule/:joint" element={<ProtectedRoute><CustomModuleCreation/></ProtectedRoute>} />
                {/* <Route path="/" element={<ProtectedRoute><NotificationPage/></ProtectedRoute>} /> */}
              </Routes>
            </Router>
          </UserAuthContextProvider>
        </Col>
    </div>
  );
}

export default App;
