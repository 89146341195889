import React, { useState } from 'react';
import logo from '../images/logo.png';
import upload from '../images/Upload.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';

const OnboardingForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    expertise: '',
    address: '',
    dob: '',
    email: '',
    education: '',
    registrationNumber: '',
    profilePicture: null,
    gender: '',               
    experience: '',            
    approxPatients: '',    
  });

  const [fileName, setFileName] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { physioOnboarding } = useUserAuth();
  const { mobilenumber } = useParams();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: '' });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, profilePicture: file });
    setFileName(file ? file.name : '');
    setErrors({ ...errors, profilePicture: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]  && key !== 'profilePicture') {
        newErrors[key] = `${key.split(/(?=[A-Z])/).join(' ')} is required`;
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      try {
        const response = await physioOnboarding(formData, mobilenumber);
        if (response === 1) {
          // navigate('/getstarted');
          navigate('/home');
        } else {
          // Handle error or other response codes
          console.error('Form submission failed');
        }
      } catch (error) {
        console.error('Form submission error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className='onboarding-form'>
      {loading && (
        <div className="spinner-container">
          <div className="spinner-grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div className='header'>
        <img src={logo} alt="Logo" className='logo' />
        <h2>Onboarding Form</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='OBpage-text' style={{fontFamily:"Rajdhani"}}>
          Full Name
        </div>
        <input
          className='OBpage-input'
          type="text"
          id="fullName"
          value={formData.fullName}
          onChange={handleChange}
          autoComplete="off"
        />
        {errors.fullName && <div className="OBerror-message">{errors.fullName}</div>}

        <div className='OBpage-text'>
          Profile Picture
        </div>
        <input
          className='OBpage-input'
          type="file"
          id="profilePicture"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
         <button
          type="button"
          onClick={() => document.getElementById('profilePicture').click()}
          className='OBpage-input'
        >
          {fileName || <><span><img src ={upload} style={{height:"20px", paddingRight:"5px"}}/></span>Upload Image </>}
        </button>
        {errors.profilePicture && <div className="OBerror-message">{errors.profilePicture}</div>}

        <div className='OBpage-text'>
          Area of Expertise
        </div>
        <input
          className='OBpage-input'
          type="text"
          id="expertise"
          value={formData.expertise}
          onChange={handleChange}
          autoComplete="off"
        />
        {errors.expertise && <div className="OBerror-message">{errors.expertise}</div>}

        <div className='OBpage-text'>
          Address
        </div>
        <input
          className='OBpage-input'
          type="text"
          id="address"
          value={formData.address}
          onChange={handleChange}
          autoComplete="off"
        />
        {errors.address && <div className="OBerror-message">{errors.address}</div>}

        <div className='OBpage-text'>
          Date of Birth
        </div>
        <input
          className='OBpage-input'
          type="date"
          id="dob"
          value={formData.dob}
          onChange={handleChange}
        />
        {errors.dob && <div className="OBerror-message">{errors.dob}</div>}

        <div className='OBpage-text'>
          Email Address
        </div>
        <input
          className='OBpage-input'
          type="email"
          id="email"
          value={formData.email}
          onChange={handleChange}
          autoComplete="off"
        />
        {errors.email && <div className="OBerror-message">{errors.email}</div>}

        <div className='OBpage-text'>
          Education
        </div>
        <input
          className='OBpage-input'
          type="text"
          id="education"
          value={formData.education}
          onChange={handleChange}
          autoComplete="off"
        />
        {errors.education && <div className="OBerror-message">{errors.education}</div>}

        <div className='OBpage-text'>
          Clinicians registration number 
        </div>
        <input
          className='OBpage-input'
          type="text"
          id="registrationNumber"
          value={formData.registrationNumber}
          onChange={handleChange}
          autoComplete="off"
        />
        {errors.registrationNumber && <div className="OBerror-message">{errors.registrationNumber}</div>}

        <div className='OBpage-text'>
          Gender
        </div>
        <select
          className='OBpage-input'
          id="gender"
          value={formData.gender}
          onChange={handleChange}
        >
          <option value="">Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          {/* <option value="Other">Other</option> */}
        </select>
        {errors.gender && <div className="OBerror-message">{errors.gender}</div>}

        <div className='OBpage-text'>
          Years of Experience
        </div>
        <input
          className='OBpage-input'
          type="number"
          id="experience"
          value={formData.experience}
          onChange={handleChange}
          autoComplete="off"
        />
        {errors.experience && <div className="OBerror-message">{errors.experience}</div>}

        <div className='OBpage-text'>
          Approximate Number of Patients
        </div>
        <input
          className='OBpage-input'
          type="number"
          id="approxPatients"
          value={formData.approxPatients}
          onChange={handleChange}
          autoComplete="off"
        />
        {errors.approxPatients && <div className="OBerror-message">{errors.approxPatients}</div>}


        <button className='submit-button' type="submit">Submit</button>
      </form>
    </div>
  );
};

export default OnboardingForm;
