import React, { useState, useEffect } from 'react';
import { useUserAuth } from "../context/UserAuthContext";
import left from '../images/Left.png';
import { useNavigate, Link } from 'react-router-dom';
import hip from '../images/hip.png';
import shoulder from '../images/shoulder.png';
import knee from '../images/knee.png';
import back from '../images/back.png';
import ankle from '../images/ankle.png';
import neck from '../images/neck.png';

const JointSelectionPage = () => {
  const { fetchAvailableJoints } = useUserAuth();
  const [jointSelected, setJointSelected] = useState(null);
  const [availableJoints, setAvailableJoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const handleImageClick = () => {
    navigate('/myprograms');
  };

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchJoints = async () => {
      try {
        const data = await fetchAvailableJoints();
        console.log("Fetched data:", data);
        setAvailableJoints(data.joints || []);
      } catch (error) {
        console.error("Error fetching joints", error);
      } finally {
        setLoading(false); // Ensure loading is set to false once data fetching is done
      }
    };

    fetchJoints();
  }, [fetchAvailableJoints]);

  const jointImages = {
    Hip: hip,
    Shoulder: shoulder,
    Knee: knee,
    Back: back,
    Ankle: ankle,
    Neck: neck
  };

  const handleContinueClick = () => {
    if (!jointSelected) {
      setError('Please select a joint');
      return;
    }
    setError('');
    navigate(`/custommodule/${jointSelected}`);
  };

  return (
    <div className='profile-page' style={{ marginTop: "40px" }}>
      <div style={{ marginLeft: "5%" }}>
        <img
          className="AP-back-image"
          src={left}
          alt="back"
          onClick={handleImageClick}
        />
        &nbsp; &nbsp;
        <span className="calender-text" style={{ fontSize: "24px", fontWeight: "700" }}>Joints</span>
      </div>

      {error && <div className='Jointerror-message'>{error}</div>}

      {loading ? (
        <div className="spinner-container">
          <div className="spinner-grow" role="status">
          </div>
        </div>
      ) : (
        <div className='joint-selection-container'>
          {availableJoints.length === 0 ? (
            <div className='no-joints-message'>No joints available</div>
          ) : (
            availableJoints.map((joint) => (
              <div
                key={joint}
                className={`joint-item`}
                onClick={() => setJointSelected(joint)}
              >
                <img 
                  src={jointImages[joint]} 
                  alt={joint} 
                  className={`joint-image  ${joint === jointSelected ? 'selected' : ''}`}
                />
                <div className='joint-name'>{joint}</div>
              </div>
            ))
          )}
        </div>
      )}


      <button className='AP-button2' onClick={handleContinueClick}>
        <div className='inner-AP-button'>
          <div className='AP-text'>CONTINUE</div>
        </div>
      </button>
    </div>
  );
}

export default JointSelectionPage;
