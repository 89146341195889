import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { analytics } from "../../firebase/index";
import { useUserAuth } from "../../context/UserAuthContext";
import { logEvent } from "firebase/analytics";

export const AddPatientButton = () => {
  const { ID, verified, setVerified } = useUserAuth();

  const handleAddPatientClick = () => {
    logEvent(analytics, 'py_AddPatientButton_clicked', {
      Date: new Date().toLocaleString(),
      ID,
      "py_log": "Add Patient button clicked"
    });
    console.log("Logging data for Add Patient button click");
  };


  return (
    <div className={`AP-button ${!verified ? 'disabled' : ''}`}>
      <Link 
        to='/addpatient' 
        onClick={verified ? handleAddPatientClick : (e) => e.preventDefault()} 
        style={{ textDecoration: "none", pointerEvents: verified ? 'auto' : 'none' }}
      >
        <div className='inner-AP-button'>
          <div className='AP-text'>ADD PATIENT</div>
        </div>
      </Link>
    </div>
  );
};
